import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import { toast } from "react-toastify";
import axios from "../../axios"
import sign from "jwt-encode";

const UsersModal = (props) => {
  console.log("props",props)
  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [gender, setGender] = useState([]);
  const [units, setUnits] = useState([]);
  const [userData, setUserData] = useState({
    id:props?.user?.id,
    first_name:props?.user?.first_name
  })


  
  const getGenders = () => {
    axios.get('/gender/all').then(data => {
      setGender(data.data);

    }).catch(err => {
      console.log(err)
    })
  }
  const getUnits = () => {
    axios.get('/unit-measurements/all').then(data => {
      setUnits(data.data);

    }).catch(err => {
      console.log(err)
    })
  }


  const handleChange = (e) => {
    let secretKey = process.env.REACT_APP_SECRET
    if (e.target.name == "password") {
      let encriptedPassword = sign(e.target.value, secretKey)
      setUserData({
        ...userData,
        [e.target.name]: encriptedPassword,
      })
    }else{
      setUserData({
        ...userData,
        [e.target.name]: e.target.value,
      })
    }

  }

  console.log("userData",userData)

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })

  // useEffect(() => {
  //   if (props?.isOpen) {
  //     setEdit(false);
  //     getGenders();
  //     setDelete(false);
  //   }
  // }, [props?.isOpen]);

  useEffect(()=>{
    getGenders();
    getUnits();
  },[])
  
  console.log("first", units)
  const handleUser = (e) => {
    e.preventDefault();
    axios.post('/users', userData)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e user janë shtuar me sukses!');


      }
      ).catch(err => {
        toast.error('Te dhenat nuk jane shtuar!');
        console.log(err);
      });
  }


  const handleEditUser = (e) => {
    e.preventDefault();
    axios.put('/users', userData)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e user janë shtuar me sukses!');


      }
      ).catch(err => {
        toast.error('Te dhenat nuk jane shtuar!');
        console.log(err);
      });
  }

  const handleDeleteUser = () => {
    axios.delete(`/users/${props?.user?.id}`).then(data => {
      toast.success('Të dhënat  janë fshir me sukses!');

      props?.handleModalClose();
      props?.getData();


    }).catch(err => {
      console.log('userProfileGetErr', err);
      toast.error('Të dhënat nuk jan fshir me sukses!');

    })
  }

  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                        <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons>  */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>


              {isdelete === false && (
                <Form onSubmit={props?.type === "add" ? handleUser : props?.type === "edit" ? handleEditUser : ""}>
                  <ModalTitle title>User</ModalTitle>
                  <div className="form-group mb-2">
                    <label>First Name:</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      required
                      name="first_name"
                      className="form-control"
                      placeholder="First Name"
                      defaultValue={props?.type === "edit" ? props?.user?.first_name : null}

                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Last Name:</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      required
                      name="last_name"
                      className="form-control"
                      placeholder="Last Name"
                      defaultValue={props?.type === "edit" ? props?.user?.last_name : null}

                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Age:</label>
                    <input
                      type="number"
                      onChange={handleChange}
                      required
                      name="age"
                      className="form-control"
                      placeholder="Age"
                      defaultValue={props?.type === "edit" ? props?.user?.age : null}

                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Email:</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      required
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      defaultValue={props?.type === "edit" ? props?.user?.email : null}

                    />
                  </div>
                
                  <div className="form-group mb-2">
                    <label>Password:</label>
                    <input
                      type="password"
                      onChange={handleChange}
                      required
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      defaultValue={props?.type === "edit" ? "*********" : null}
                      disabled={props?.type === "edit" ? true : false}

                    />
                  </div>
                  <div className="form-group mb-2">
                    <label for="exampleFormControlSelect1">Gender</label>
                    <select className="form-select"
                      onChange={handleChange}
                      required
                      name="gender_id"
                      id="exampleFormControlSelect1"
                      // defaultValue={props?.type === "edit" ? props?.user?.gender_id : null}
                      
                    >
                      <option value=""  selected disabled>Select</option>
                      {gender?.map((el) => (
                        <>
                        <option key={el?.id} value={el?.id} selected={props?.user?.gender_id === el.id ? true : null}>
                          {el?.name}
                        </option>
                        </>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-2">
                    <div className="">

                    <label for="weightFormControlSelect" className="w-50">Weight</label>
                    <label for="weightUnitFormControlSelect" className="w-50">Units (KG/LB)</label>
                    </div>
                    <div className="input-group">
                      <input type="number" className="form-control"
                        onChange={handleChange}
                        required
                        name="weight"
                        placeholder="KG/LB" aria-label="KG/LB" aria-describedby="basic-addon2"
                        defaultValue={props?.type === "edit" ? props?.user?.weight : null}
                        />
                      <select className="form-select"
                      onChange={handleChange}
                      required
                      name="weight_type_id"
   
                      // defaultValue={props?.type === "edit" ? props?.user?.unit_id : null}
                      
                    >
                      <option value="" selected disabled>Select Unit for Weight</option>
                      {units?.filter((elem) => {
                          const name_al = elem.sets_setting?.name_al || "";
                          return name_al.toLowerCase() === "pesha".toLowerCase();
                        }).map((el) => (
                        <>
                        {/* {console.log("HEJ",el?.sets_setting?.name_al)} */}
                        <option key={el?.id} value={el?.id} selected={props?.user?.weight_type_id === el.id ? true : null}>
                          {el?.unit}
                        </option>
                        </>
                      ))}
                    </select>
             
                      
                    </div>
                  </div>
                  <div className="form-group mb-2">
                    <label for="heightFormControlSelect" className="w-50">Height</label>
                    <label for="heightUnitFormControlSelect" className="w-50">Units (CM/IN)</label>
                    <div className="input-group">
                      <input type="number" className="form-control"
                        onChange={handleChange}
                        name="height"
                        required
                        placeholder="CM/IN" aria-label="CM/IN" aria-describedby="basic-addon2"
                        defaultValue={props?.type === "edit" ? props?.user?.height : null}
                        />
                      <select className="form-select"
                      onChange={handleChange}
                      required
                      name="height_type_id"
   
                      // defaultValue={props?.type === "edit" ? props?.user?.unit_id : null}
                      
                    >
                      <option value=""  selected disabled>Select Unit for Height</option>
                      {units?.filter((elem) => {
                          const name_al = elem.sets_setting?.name_al || "";
                          return name_al.toLowerCase() === "gjatesia".toLowerCase();
                        }).map((el) => (
                        <>
                        <option key={el?.id} value={el?.id} selected={props?.user?.height_type_id === el.id ? true : null}>
                          {el?.unit}
                        </option>
                        </>
                      ))}
                    </select>
                    </div>
                  </div>
                  {props?.type === "add" && (
                    <SaveButtonModal type="submit">
                      Ruaj
                    </SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal type="submit" >
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>


              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={()=>handleDeleteUser()}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default UsersModal;
