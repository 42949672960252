import React, { useState, useEffect } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios";
import { toast } from "react-toastify";
import sign from "jwt-encode";

const PersonalTrainersModal = (props) => {

  console.log("trainer props", props)

  const personalTrainersData = props?.data;

  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [dataToSave, setDataToSave] = useState({
    id: personalTrainersData?.id,
    personal_trainers_id: personalTrainersData?.personal_trainers_id
  });

  const [selectedCountry, setSelectedCountries] = useState(personalTrainersData?.country_id || null);
  const [selectedCity, setSelectedCity] = useState(personalTrainersData?.city_id || null);
  const [initialImage, setInitialImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [emailErr, setEmailErr] = useState('')
  const [imagePreview2, setImagePreview2] = useState(null);



  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })



  const handleAddSubmmision = (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.keys(dataToSave).forEach((key) => {
      formData.append(key, dataToSave[key]);
    });

    e.preventDefault();
    axios.post('personal-trainers', formData)
      .then(data => {
        console.log("vjosa darta", data)
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat janë ruajtur me sukses!');
      }
      ).catch(err => {
        console.log("vjosa darta catch", err)
        // props?.handleModalClose();
        toast.error(err?.response?.data?.message);
        setEmailErr(err?.response?.data?.message)

        console.log(err);
      });
  }



  const handelEditSubmmision = (e) => {
    e.preventDefault();
    setEdit(false);

    const formData = new FormData();

    Object.keys(dataToSave).forEach((key) => {
      formData.append(key, dataToSave[key]);
    });

    axios.put(`personal-trainers`, formData)
      .then(data => {
        toast.success('Të dhënat janë përditësuar me sukses!');
        props?.handleModalClose();
        props?.getData();
      })
      .catch(err => {
        console.log(err);
        toast.error('Të dhënat nuk janë përditësuar!');
      });
  }



  const handleDeleteSubmmision = () => {

    axios.delete(`/personal-trainers/${personalTrainersData?.id}`).then(data => {
      toast.success('Të dhënat  janë fshir me sukses!');
      props?.handleModalClose();
      props?.getData();

    }).catch(err => {
      console.log(err);
      toast.error('Të dhënat nuk jan fshir me sukses!');

    })
  }

  const handleChange = (e) => {

    let secretKey = process.env.REACT_APP_SECRET;
    // if (e.target.name == "password") {
    // let encriptedPassword = sign(e.target.value, secretKey)
    // setDataToSave({
    //   ...dataToSave,
    //   [e.target.name]: encriptedPassword,
    // });
    // }
    // } else if(e.target.type === "file") {
    //   setDataToSave({
    //     [e.target.name]: e.target.files[0],
    //   });
    // }
    // else{
    if (e.target.name === "active") {
      setDataToSave({
        ...dataToSave,
        [e.target.name]: e.target.checked,
      })
    }
    else {
      setDataToSave({
        ...dataToSave,
        [e.target.name]: e.target.value,
      })
    }
    // }
    console.log("datatosave", dataToSave)
  }

  const isChecked = (e) => {
    setDataToSave({
      ...dataToSave,
      [e.target.name]: e.target.checked,
    });
  }



  useEffect(() => {
    if (props.type === 'edit' && dataToSave.image) {
      setInitialImage(dataToSave.image);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(dataToSave.image);
      reader.readAsDataURL(dataToSave.subscription_image);

    } else {
      setInitialImage(null);
      setImagePreview(null);
      setImagePreview2(null);

    }
  }, [props.type, dataToSave.image]);

console.log("personalTrainersData", personalTrainersData)
  const image = (e) => {
    const ud = Object.assign({}, dataToSave);
    ud.personal_trainer = e.target.files[0];
    setDataToSave(ud);

    if (ud.personal_trainer) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(ud.personal_trainer);
    } else {
      setImagePreview(null);
    }

    console.log("image data", ud);
  };

  const subscription_image = (e) => {
    const ud = Object.assign({}, dataToSave);
    ud.subscription_image = e.target.files[0];
    setDataToSave(ud);

    if (ud.subscription_image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview2(reader.result);
      };
      reader.readAsDataURL(ud.subscription_image);
    } else {
      setImagePreview2(null);
    }

    console.log("image data", ud);
  };
  // const image = (e) => {
  //   const ud = Object.assign({}, dataToSave);
  //   ud.personal_trainer = e.target.files[0];
  //   setDataToSave(ud);
  //   // setSelectedName(ud?.url?.name);
  //   console.log("image data", ud)
  // };



  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>


              {isdelete === false && (
                <Form onSubmit={props?.type === "add" ? handleAddSubmmision : props?.type === "edit" ? handelEditSubmmision : ""}>
                  <ModalTitle title> {props?.type === "add" ? "Add Personal Trainer" : "Edit Personal Trainer"}</ModalTitle>
                  <div className="form-group mb-3">
                    <label>First Name: <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                    <input
                      name="first_name"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.first_name : ""}
                      required
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label>Last Name:<span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                    <input
                      name="last_name"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.last_name : ""}
                      required
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label>E-mail:<span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                    <input
                      name="email"
                      onChange={handleChange}
                      type="email"
                      className="form-control"
                      placeholder="E-mail"
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.email : ""}
                      disabled={props?.type == 'edit' ? true : false}
                      required
                    />
                  </div>
                 
                  {<span style={{ color: "red" }}>{emailErr}</span>}

                  <div className="form-group mb-3">
                    <label>Password:<span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                    <input
                      name="password"
                      onChange={handleChange}
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.password : ""}
                      disabled={props?.type == 'edit' ? true : false}
                      required
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label>Country:</label>
                    <select
                      name="country_id"
                      onChange={handleChange}
                      disabled={!isEdit || props?.type === 'add' ? false : true}
                      className="form-control text-capitalize"
                      value={props?.type === "edit" ? selectedCountry : null}
                      defaultValue={personalTrainersData?.country_id}
                    >

                      <option selected disabled={props?.type === "edit" ? true : false}> Select country</option>
                      {props?.countries?.map((el) => (
                        <option key={el?.id} value={el?.id}>
                          {el?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group mb-3">
                    <label>City:</label>
                    <select
                      name="city_id"
                      onChange={handleChange}
                      disabled={!isEdit || props?.type === 'add' ? false : true}
                      className="form-control text-capitalize"
                      value={props?.type === "edit" ? selectedCity : null}
                      defaultValue={personalTrainersData?.city_id}
                    >
                      <option selected disabled={props?.type === "edit" ? true : false}> Select city</option>
                      {props?.cities?.map((el) => (
                        <option key={el?.id} value={el?.id}>
                          {el?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <hr />
                  <div className="form-group mb-3">
                    <label>One month IOS packet name:<span style={{ color: "red", fontWeight: "bold" }}></span></label>
                    <input
                      name="trainer_ios_one_month_packet"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder=""
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.personal_trainer?.trainer_ios_one_month_packet : ""}

                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Three month IOS packet name:<span style={{ color: "red", fontWeight: "bold" }}></span></label>
                    <input
                      name="trainer_ios_three_month_packet"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder=""
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.personal_trainer?.trainer_ios_three_month_packet : ""}

                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>One year IOS packet name:<span style={{ color: "red", fontWeight: "bold" }}></span></label>
                    <input
                      name="trainer_ios_one_year_packet"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder=""
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.personal_trainer?.trainer_ios_one_year_packet : ""}

                    />
                  </div>
                  {console.log("personalTrainersData", personalTrainersData)}



                  <hr />

                  <div className="form-group mb-3">
                    <label>One month Android packet name:<span style={{ color: "red", fontWeight: "bold" }}></span></label>
                    <input
                      name="trainer_android_one_month_packet"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder=""
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.personal_trainer?.trainer_android_one_month_packet : ""}

                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Three month Android packet name:<span style={{ color: "red", fontWeight: "bold" }}></span></label>
                    <input
                      name="trainer_android_three_month_packet"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder=""
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.personal_trainer?.trainer_android_three_month_packet : ""}

                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>One year Android packet name:<span style={{ color: "red", fontWeight: "bold" }}></span></label>
                    <input
                      name="trainer_android_one_year_packet"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder=""
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.personal_trainer?.trainer_android_one_year_packet : ""}

                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Android trainer package id:<span style={{ color: "red", fontWeight: "bold" }}></span></label>
                    <input
                      name="trainer_android_package_id"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      placeholder=""
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.personal_trainer?.trainer_android_package_id : ""}

                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Order number:</label>
                    <input
                      name="order_id"
                      onChange={handleChange}
                      type="number"
                      className="form-control"
                      placeholder="Order"
                      defaultValue={props?.type == 'edit' ? personalTrainersData?.personal_trainer?.order_id : ""}
                    
                    />
                  </div>


                  <div className="form-group mb-3">
                    <input
                      className="form-check-input"
                      onChange={handleChange}
                      type="checkbox"
                      id="active"
                      name="active"
                      defaultChecked={personalTrainersData?.active}
                    />
                    <label for="active" className="ms-1">Is Active</label>
                    <input
                      className="form-check-input ms-5"
                      onChange={isChecked}
                      type="checkbox"
                      id="ambasador"
                      name="ambasador"
                      defaultChecked={personalTrainersData?.personal_trainer?.ambasador}
                    />
                    <label for="ambasador" className="ms-1">Ambasador</label>
                    <input
                      className="form-check-input  ms-5"
                      onChange={isChecked}
                      type="checkbox"
                      id="coming_soon"
                      name="coming_soon"
                      defaultChecked={personalTrainersData?.personal_trainer?.coming_soon}
                    />
                    <label for="coming_soon" className="ms-1">Coming Soon</label>
                  </div>

                  <div className="form-group mb-3">
                    
                  </div>
                  
                  {/* <div className="form-group mb-3">
                        <label>Regular price:<span style={{color:"red" , fontWeight: "bold"}}>*</span></label>
                        <input
                          name="price"
                          onChange={handleChange}
                          type="number"
                          className="form-control"
                          placeholder="Regular Price"
                          defaultValue={personalTrainersData?.personal_trainer?.price}
                          required
                        />                       
                    </div> */}

                  <div className="form-group mb-3">
                  <label>Trainer image:</label>
                    {imagePreview && (
                      <div>
                        <img src={imagePreview} alt="Preview" style={{ maxWidth: 'auto', maxHeight: '100px' }} />
                      </div>
                    )}

                    {!imagePreview && props?.type === "edit" &&
                      <div>
                        <img src={`${process.env.REACT_APP_UPLOADS + personalTrainersData?.image}`} style={{ maxWidth: 'auto', maxHeight: '100px' }} />
                      </div>

                    }

                  </div>
                  <div className="form-group mb-3">
                    
                    <input
                      type="file"
                      name="personal_trainer"
                      onChange={image}
                    />
                  </div>
                  <div className="form-group mb-3">
                  <label>Subscription image:</label>

                    {imagePreview2 && (
                      <div>
                        <img src={imagePreview2} alt="Preview" style={{ maxWidth: 'auto', maxHeight: '100px' }} />
                      </div>
                    )}

                    {!imagePreview2 && props?.type === "edit" &&
                      <div>
                        <img src={`${process.env.REACT_APP_UPLOADS + personalTrainersData?.personal_trainer?.subscription_image}`} style={{ maxWidth: 'auto', maxHeight: '100px' }} />
                      </div>
                    }

                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="file"
                      name="subscription_image"
                      onChange={subscription_image}
                    />
                  </div>
                  

                  {props?.type === "add" && (
                    <SaveButtonModal type="submit" className="mb-5" >
                      Ruaj
                    </SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal type="submit" className="mb-5" >
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>
              )}

              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={handleDeleteSubmmision}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default PersonalTrainersModal;
