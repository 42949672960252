import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios"
import { toast } from "react-toastify";

const LocationsModal = (props) => {
  const [isAdd, setIsAdd] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [dataToSave, setDataToSave] = useState(props?.data);
  const [disableEdit, setDisableEdit] = useState(false);
  const LocationsData=props?.data;

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })

  const handleChange= (e) => {
    setDisableEdit(true);
    setDataToSave({
      ...dataToSave,
      [e?.target?.name]: e?.target?.value,
    })
  }

  const handleAddSubmmision = (e) => {
    e.preventDefault();
    axios.post('locations/', dataToSave).then(
        data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat janë ruajtur me sukses!');
        setDisableEdit(false);
        }
    ).catch(err => {
        toast.error('Të dhënat nuk janë ruajtur!');
        console.log(err);
    });
  }

  const handleEditSubmmision = (e) => {
    e.preventDefault();
    axios.put('locations/', dataToSave).then(
        data => {
            props?.getData();
            toast.success('Të dhënat janë përditësuar me sukses!');
            props?.handleModalClose();
            setDisableEdit(false);
        }
    ).catch(err => {
        toast.error('Të dhënat nuk janë përditësuar!');
        console.log(err);
    });
  }
  
  const handleDelete = (e) => {
    e.preventDefault();
    axios.delete(`locations/${LocationsData?.id}`)
        .then(data => {
            props?.getData();
            toast.success('Të dhënat janë fshirë me sukses!');
            props?.handleModalClose();
        })
        .catch(err => {
            toast.error('Të dhënat nuk janë fshirë!');
            console.log(err);
        })
  }
  
  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
          <div className="wholePage" onClick={() => props?.handleModalClose()}/>
            <Mymodal_wrapper>

              <ModalActionButtonWrapper>
                { props?.type !== "add" ?
                    <>
                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                     <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                        <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                    </>
                :   
                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                        <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                }
              </ModalActionButtonWrapper>
              {isdelete === false && (
               <Form  onSubmit={props?.type=="edit" ? handleEditSubmmision : handleAddSubmmision}>
                    <ModalTitle title>{props?.type === 'add' && !isAdd  ? 'Add ' : 'Edit ' }Locations</ModalTitle>
                    <div className="form-group">
                    <label>Emri shqip</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Emri"
                      name="name_al"
                      onChange={handleChange}
                      defaultValue={props?.type=='edit' ? LocationsData?.name_al : ""}
                      required
                     />
                    </div>
                    <div className="form-group mt-3">
                    <label>Emri anglisht</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Emri"
                      name="name_en"
                      onChange={handleChange}
                      defaultValue={props?.type=='edit' ? LocationsData?.name_en : ""}
                     />
                    </div>
                    {props?.type === "add" && (
                     <SaveButtonModal type="submit" disabled={!disableEdit}>Ruaj</SaveButtonModal>
                    )}
                    {props?.type === "edit" && (
                     <SaveButtonModal type="submit"  disabled={!disableEdit}>Ndrysho</SaveButtonModal>
                    )}
                 </Form>
              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini {LocationsData?.name_al}?</ModalTitle>
                    <DeleteButtonModal  onClick={handleDelete}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};
export default LocationsModal;
