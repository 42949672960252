import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loggMeIN, rmAuth, toggleMenu } from "../../redux/Functions/actions";
import {
  MenuButton,
  PageStyle,
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  EyeIcon,
  Pagination
} from "../../styles/MainLayout";

import FitnessModal from "./fitnessModal"
import Search from "../search/search";
import axios from "../../axios"
import ReactPaginate from "react-paginate";

const Fitnesses = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [dataToModal, setDataToModal] = useState();
  const [fitness, setFitness] = useState();
  const [fitnessCountry, setFitnessCountry] = useState();
  const [fitnessCity, setFitnessCity] = useState();
  const [fitnessID, setFitnessID] = useState();

  const [filtered, setFiltered] = useState([]);

  console.log("propsat", props);

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 8;
  const pagesVisited = pageNumber * itemsPerPage;
  const pageCount = Math.ceil(filtered.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }

  const getFitnesses = () => {
    axios.get('/fitness/all').then(data => {
      setFitness(data.data);
      setFiltered(data?.data)
    }).catch(err => {
      console.log('fitnessErr', err)
    })
  }

  console.log('fitness', fitness);

  const getFitnessCountry = () => {
    axios.get('/countries/all').then(data => {
      console.log('fitcountry', data)
      setFitnessCountry(data.data)
    }).catch(err => {
      console.log('fitcountryErr', err)
    })
  }

  const getFitnessCity = () => {
    axios.get('/cities/all').then(data => {
      console.log('fitcity', data)
      setFitnessCity(data.data)
    }).catch(err => {
      console.log('fitcityErr', err)
    })
  }

  useEffect(() => {
    getFitnesses();
    getFitnessCountry();
    getFitnessCity();
  }, []);

  const handleModalAdd = () => {
    setModalType("add");
    setIsOpen(true);
  };

  const handleModalEdit = (data) => {
    setDataToModal(data)
    setFitnessID(data)
    setModalType("edit");
    setIsOpen(true);
  };

  const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
  };
  

  return (
    <>
      {isOpen === true ?
        <FitnessModal
          dataToModal={dataToModal}
          fitness={fitnessID}
          isOpen={isOpen}
          type={modalType}
          getData={() => getFitnesses()}
          handleModalClose={()=>{setIsOpen(); setDataToModal(null)}}
        />
        : ""
      }

      <PageStyle isMenuOpen={props?.menu}>
        <PageTitleStyle>List of Fitnesses</PageTitleStyle>
        <Search data={fitness} filtered={setFiltered} setPageNumber={setPageNumber}/>
        <MenuButton onClick={handleMenuClick}>
          {props.menu ? (
            <i className="fa fa-chevron-left"></i>
          ) : (
            <i className="fa fa-bars"></i>
          )}
        </MenuButton>

        <PageContainer>
          <TableResponsive isMenuOpen={props?.menu}>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableHeadStyle tableFirstElement>ID</TableHeadStyle>
                  <TableHeadStyle>Name</TableHeadStyle>
                  <TableHeadStyle>City</TableHeadStyle>
                  <TableHeadStyle>Actions</TableHeadStyle>
                </TableRowHead>
              </TableHead>
              <TableBody>
              {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>}
                {filtered && filtered?.length > 0 && <>
                  {filtered?.slice(pagesVisited, pagesVisited + itemsPerPage)?.map((el, index) => {
                    return (
                      <>
                        <TableRowBody key={index}>
                          <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                          <TableBodyStyle >{el?.name}</TableBodyStyle>
                          <TableBodyStyle>{fitnessCity?.find(elem => elem?.id == el?.city_id)?.name || 'City not found'}</TableBodyStyle>
                          <TableBodyStyle><EyeIcon onClick={() => handleModalEdit(el)} /> </TableBodyStyle>
                        </TableRowBody>
                      </>
                    )
                  })}</>
                }
              </TableBody>
            </Table>
            <Pagination>
              <div className="text-right">
                {((fitness?.length >= itemsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
          </TableResponsive>
        </PageContainer>
      </PageStyle>

      <MenuButton
        add
        onClick={() => {
          handleModalAdd();
        }}
      >
        <i className="fa fa-plus" />
      </MenuButton>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Fitnesses);