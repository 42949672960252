import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import { 
  MenuButton, 
  PageStyle, 
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  Pagination,

  TableHeadStyle,
  TableBodyStyle,
  EyeIcon } from "../../styles/MainLayout";
import Search from "../search/search";
import axios from "../../axios"
import ReactPaginate from "react-paginate";
import OrdersStatusModal from "./orderStatusModal";

const OrderStatus = (props) => {
const [isOpen, setIsOpen] = useState(false);
const [modalType, setModalType] = useState(null);
const [statusNames, setStatusNames] = useState();
const [statusID, setStatusID] = useState();
const [filtered, setFiltered] = useState([]);

  //Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 8;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }
const getOrderStatus = () => {
  axios.get('/orders-status/all').then(data => {
    console.log('datadata diqka', data)
    setStatusNames(data.data);
    setFiltered(data?.data)

  }).catch(err => {
    console.log('userProfileGetErr', err);
  })
}


useEffect(() => {
  getOrderStatus();
}, []);
const handleModalAdd = () => {
  setModalType('add')
  setIsOpen(true);
};

const handleModalEdit = (data) => {
  setStatusID(data)
  setModalType('edit')
  setIsOpen(true);
};

const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
};    
console.log("data", statusID)
  
return (
      <>
         {isOpen === true ?
        <OrdersStatusModal  status={statusID} getData={() => getOrderStatus()}  isOpen={isOpen} type={modalType} handleModalClose={()=>{setIsOpen();setStatusID(null)}} />
        : ""
      }
        <PageStyle isMenuOpen={props?.menu}>

          <PageTitleStyle>Order Status</PageTitleStyle>
          <Search data={statusNames} filtered={setFiltered} setPageNumber={setPageNumber} />
          <MenuButton onClick={handleMenuClick}>{props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>

            <PageContainer>
              <TableResponsive isMenuOpen={props?.menu}>
                <Table>
                  <TableHead>
                    <TableRowHead>
                      <TableHeadStyle tableFirstElement>ID</TableHeadStyle>
                      <TableHeadStyle >Emri statusit</TableHeadStyle>
                      <TableHeadStyle >Name of Status</TableHeadStyle>
                      
                      <TableHeadStyle>Actions</TableHeadStyle>
                    </TableRowHead>
                  </TableHead>
                  <TableBody>
                  {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>
              }
                {filtered && filtered?.length > 0 && <>
                  {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                    return (
                      <>
                    <TableRowBody>
                      <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                      <TableBodyStyle >{el?.name_al}</TableBodyStyle>
                      <TableBodyStyle >{el?.name_en}</TableBodyStyle>
                     
                      <TableBodyStyle><EyeIcon onClick={() => handleModalEdit(el) }/> </TableBodyStyle>                
                    </TableRowBody>
                    </>
                             )
                           })}</>
                         }
                  </TableBody>
                </Table>
                <Pagination>
              <div className="text-right">
                {((statusNames?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
            </TableResponsive>
          </PageContainer>

        </PageStyle>

        <MenuButton add onClick={() => {handleModalAdd();}}>
          <i className="fa fa-plus" />
        </MenuButton>
      </>
)
}

const mapStateToProps = (state) => {
    return {
      lang: state.data.lang,
      langData: state.data.langData,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu()),
      // loadingOff: () => dispatch(loadingOff()),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(OrderStatus);