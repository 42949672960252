import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loggMeIN, rmAuth, toggleMenu } from "../../redux/Functions/actions";
import "./fitnessPlansWeek.scss"
import {
  MenuButton,
  PageStyle,
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  FitnesPlanWeeksTable,
  BackButton,
} from "../../styles/MainLayout";
import FitnessPlansWeeksModal from "./fitnessPlansWeeksModal";
import Search from "../search/search";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../axios";
import { useParams } from 'react-router-dom';
const FitnessPlansWeeks = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [fitnessWeeks, setFitnessWeeks] = useState([]);
  const [planWeeksData, setPlanWeeksData] = useState();
  const [fitnessInfo, setFitnessInfo] = useState();
  let { id } = useParams();

  const getFitnessesWeeks = () => {
    axios.get(`/fitness-plans-weeks/all/${id}`).then(data => {
      setFitnessWeeks(data.data);
    }).catch(err => {
      console.log('fitnessErr', err)
    })
  }

  const getFitnessesInfo = () => {
    axios.get(`/fitness-plans/${id}`).then(data => {
      setFitnessInfo(data.data);
    }).catch(err => {
      console.log('fitnessErr', err)
    })
  }

  useEffect(() => {
    getFitnessesWeeks();
    getFitnessesInfo();
  }, [])

  const handleModalAdd = (week, day) => {
    setModalType("add");
    setIsOpen(true);
    setPlanWeeksData({ week, day });
  };

  const handleModalEdit = () => {
    setModalType("edit");
    setIsOpen(true);
  };

  const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
  };
  const navigate = useNavigate();


  return (
    <>
      {isOpen === true ?
        <FitnessPlansWeeksModal
          isOpen={isOpen}
          type={modalType}
          weeksCount={fitnessWeeks}
          planWeekdata={planWeeksData}
          fitnessInfo={fitnessInfo}
          handleModalClose={() => { setIsOpen(false); setPlanWeeksData(null) }}
          getData={() => getFitnessesWeeks()}
        />
        : ""
      }
      <PageStyle isMenuOpen={props?.menu}>
        <PageTitleStyle>Fitness Plans Weeks</PageTitleStyle>
        <Search />
        <MenuButton onClick={handleMenuClick}>
          {props.menu ? (
            <i className="fa fa-chevron-left"></i>
          ) : (
            <i className="fa fa-bars"></i>
          )}
        </MenuButton>

        <PageContainer>
          <FitnesPlanWeeksTable isMenuOpen={props?.menu}>
            <BackButton onClick={() => navigate(-1)}>
              <i className="fa fa-chevron-left backIcon "></i><h6>Back</h6>
            </BackButton>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableHeadStyle tableFirstElement></TableHeadStyle>
                  <TableHeadStyle></TableHeadStyle>
                  <TableHeadStyle></TableHeadStyle>
                  <TableHeadStyle>Weeks</TableHeadStyle>
                  <TableHeadStyle></TableHeadStyle>
                  <TableHeadStyle></TableHeadStyle>
                  <TableHeadStyle></TableHeadStyle>

                </TableRowHead>
              </TableHead>
              <TableBody>
                {fitnessWeeks?.map((el) => {
                  console.log("el", el)
                  return (
                    <TableRowBody key={el?.id}>
                      <TableBodyStyle tableFirstElement>
                        <h5>Week {el?.week_id}</h5></TableBodyStyle>
                      {el?.days?.map((elementDays) => {
                        return (
                          <>
                            {elementDays?.is_active
                              ?
                              <TableBodyStyle tableFirstElement>
                                <div className="divActive">

                                  <button className="buttonSelectedDay" add
                                    onClick={() => {
                                      handleModalAdd(el, elementDays);
                                    }}> Day{elementDays?.day_id + 1}</button>
                                </div>
                              </TableBodyStyle>
                              :
                              <TableBodyStyle tableFirstElement>
                                <div className="divLine">

                                  <button disabled className="buttonDay text-decoration-line-through" add
                                  > Day{elementDays?.day_id + 1}</button>
                                </div>
                              </TableBodyStyle>
                            }
                          </>

                        )
                      })}

                    </TableRowBody>
                  )
                })}


              </TableBody>
            </Table>
          </FitnesPlanWeeksTable>
        </PageContainer>
      </PageStyle>

      {/* <MenuButton
        add
        onClick={() => {
          handleModalAdd();
        }}
      >
        <i className="fa fa-plus" />
      </MenuButton> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FitnessPlansWeeks);
