export const LANG_AL = 'al';
export const LANG_EN = 'en';
export const SET_LANG = 'SET_LANG';
export const LOADING_ON = true;
export const LOADING_OFF = false;
export const LOGGEDIN = 'LOGGEDIN';
export const SET_AUTH = 'SET_AUTH';
export const RM_AUTH = 'RM_AUTH';
export const USER_ID = 'USER_ID';
export const CUSTOMER_ID = 'CUSTOMER_ID';
export const ROLE = 'ROLE';
export const MENU = 'MENU';
export const EXTERNAL_DB = 'EXTERNAL_DB';
export const ACTIVEMENU = 'ACTIVEMENU';
export const SETLANGDATA = 'SETLANGDATA';