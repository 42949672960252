import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import sign from "jwt-encode";

const LabelsModal = (props) => {
    console.log("propsss",props)
  const [isdelete, setDelete] = useState(false);
  const [language, setLanguage] = useState([]);  
  const [values, setValues] = useState([]);  
  const [keyValue, setKeyValue] = useState();
  
  const getCategoryData = () => {
    axios.get('languages/all').then(data => {
      setLanguage(data.data);
    }).catch(err => {
      console.log('err', err)
    });
  };

  const handleAddSubmmision = (e) => {
    e.preventDefault();
    let dataToSend = {
        key_name:keyValue,
        translation_values:values
    }
        axios.post('/label-translations', dataToSend)
          .then(data => {
            props?.handleModalClose();
            props?.getData();
            toast.success('Të dhënat janë ruajtur me sukses!');
          }
          ).catch(err => {
            toast.error(`Të dhënat nuk janë ruajtur! ${err.response.data.message}`);
            console.log(err);
          });
  };

  const handleEditSubmmision = (e) => {
    e.preventDefault();
    let dataToSend = {
        translation_values:values
    }
        axios.put('/label-translations', dataToSend)
          .then(data => {
            props?.handleModalClose();
            props?.getData();
            toast.success('Të dhënat janë ruajtur me sukses!');
          }
          ).catch(err => {
            toast.error('Të dhënat nuk janë ruajtur!');
            console.log(err);
          });
  }

  const handleDelete = () => {
    axios.delete(`/label-translations/${props?.dataToModal?.keyname}`).then(data => {
      toast.success('Të dhënat janë fshirë me sukses!');
      props?.handleModalClose();
      props?.getData();
    }).catch(err => {
      console.log(err);
      toast.success('Të dhënat  nuk janë fshirë me sukses!');
    })
  };

  const handleChange = (e) => {

        setKeyValue(e.target.value)
      
  }
  const handleValues = (e, data,idData) => {
    console.log("idData",idData)
    setValues((prevValues) => {
      const langIndex = prevValues.findIndex((item) => item.lang_id === data.id);
  
      if (langIndex !== -1) {
        const updatedValues = [...prevValues];
        updatedValues[langIndex] = {
          value: e.target.value,
          lang_id: data.id,
          code:data.code,
          id:idData
        };
        return updatedValues;
      } else {
        return [
          ...prevValues,
          {
            value: e.target.value,
            lang_id: data.id,
            code:data.code,
            id:idData
          },
        ];
      }
    });
    

  };
  

  console.log("handleValues",values)


  
  useEffect(() => {
    getCategoryData();
  }, []);

useEffect(() => {
  if (language && props?.type === "add") {
    setValues(
      language.map((data) => ({
        value: "",
        lang_id: data.id,
        code: data.code,
      }))
    );
  }
}, [language]);

  useEffect(() => {
    if (props?.isOpen) {
      setDelete(false);
    }
  }, [props?.isOpen]);


  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()}/>
            <Mymodal_wrapper>
              <ModalActionButtonWrapper>
                { props?.type !== "add" ?
                  <>
                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>
                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :   
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>
              {isdelete === false && (
                <Form onSubmit={props?.type === "add" ? handleAddSubmmision : props?.type === "edit" ? handleEditSubmmision : ""}>
                  <ModalTitle title>Labels </ModalTitle>
                  <div className="form-group">
                    <label>Shto Key:</label>
                    <input
                      required
                      id="name"
                      name="key_name"
                      type="text"
                      className="form-control"
                      placeholder="KeyName"
                      onChange={handleChange}
                      disabled={props?.type === "edit" ? true : false}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.keyname : ""}
                    />
                  </div>
                  {language?.map((el,index)=>{
                    return(
                        <>
                  <div className="form-group my-2">
                    <label>Shto Value {el.code}:</label>
                    <input
                      id="name"
                      name="value"
                      type="text"
                      required={index === 0 ? true : false}
                      placeholder="Value"
                      className="form-control"
                      onChange={(e)=>handleValues(e,el,props?.dataToModal?.allValues[index]?.id)}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.allValues[index]?.value : ""}
                    />
                  </div>
                        </>
                    )
                  })}
                  
                  {props?.type === "add" && (
                    <SaveButtonModal type="submit">
                      Ruaj
                    </SaveButtonModal>
                  )}
                  {props?.type === "edit" && (
                    <SaveButtonModal type="submit">
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>
              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={handleDelete}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};
export default LabelsModal;
