import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import {
    ModalStyle
} from "../../styles/MainLayout"

import { toast } from "react-toastify";

const DeleteItemsModal = (props) => {
    console.log("propss",props)
    const handleSubmit = (e) => {
        e.preventDefault();
        if(props?.type === "parent"){
            axios.delete(`/ecommerce/deleteVariation/${props?.data?.id}`)
            .then(data => {
                props?.getVariationData();
                props?.handleModalClose();
            }).catch(err => {
                console.log(err)
            })
        }else{
            axios.delete(`/ecommerce/deleteSingleVariation/${props?.data?.id}`)
            .then(data => {
                props?.getVariationData();
                props?.handleModalClose();
            }).catch(err => {
                console.log(err)
            })
        }
    };


    if (props?.isOpen) {
        return (
            <>
                <div className="wholePage" onClick={() => {props?.handleModalClose(); }} />
                <div className="delete-item p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4>Delete Item <span className='text-danger'> {props?.data.name_al} </span> </h4>
                    <i className='fa fa-times fa-2x pointer-icon' onClick={() => {props?.handleModalClose(); }}/>
                  </div>
                  <div className="text-center">

                  <i class="fas fa-exclamation-triangle text-danger fa-3x"></i>
                  </div>
                  <h4 className='text-center my-4'>Are you sure u want to delete this item. All variations that contain this item 
                  <span className='text-danger'> {props?.data.name_al} </span> will be deleted</h4>
                    <div className="row justify-content-between mt-5">
                        <div className="col-md-6">

                        <button className='btn btn-danger w-100' onClick={() => {props?.handleModalClose(); }}>Cancel</button>
                        </div>
                        <div className="col-md-6">
                        <button className='btn btn-success w-100' onClick={(e) => handleSubmit(e)}>Accept</button>
                        </div>
                    </div>
           

                    </div>
               
            </>
        )
    }
}

export default DeleteItemsModal;