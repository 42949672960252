import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
} from "../../redux/Functions/actions";
import {
    MenuButton,
    PageStyle,
    PageTitleStyle,
    TableResponsive,
    TableBody,
    TableHead,
    TableRowHead,
    TableRowBody,
    PageContainer,
    Table,
    TableHeadStyle,
    TableBodyStyle,
    EyeIcon,
    CostumTableHeadStyle,
    BackButton,
    Pagination,
} from "../../styles/MainLayout";
// import GlobalModal from "./globalModal";
import Search from "../search/search";
import NewSetModal from './addNewSetModal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from '../../axios';
import ReactPaginate from "react-paginate";


const ListOfWorkoutSets = (props) => {
    let params = useParams()
    const [isOpen, setIsOpen] = useState(false);
    const [dataToModal, setDataToModal] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [workoutSets, setWorkoutSets] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [weekCount, setWeekCount] = useState();



  //Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 10;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }


    const handleMenuClick = (e) => {
        e.prevenTableHeadStyleefault();
        props.toggleMenu();
    };

    const handleGetWorkoutSets = () => {
        axios.get(`workouts-sets/plan-workouts/${params?.id}`)
            .then(res => { 
                setWorkoutSets(res.data); 
                setFiltered(res?.data);
                console.log('res', res) })
            .catch(err => console.log('err', err))
    };
    const handleGetWorkoutWeeks = () => {
        axios.get(`workouts-sets/getWeekCount/${params?.id}`)
            .then(res => { 
            setWeekCount(res.data); 
            })
            .catch(err => console.log('err', err))
    };

    useEffect(() => {
        handleGetWorkoutSets();
        handleGetWorkoutWeeks();
    }, [])

    const handleModalAdd = () => {
        setModalType('add')
        setIsOpen(true);
    };
    const handleModalEdit = (el) => {
        setDataToModal(el)
        setModalType('edit')
        setIsOpen(true);
    };

    const handleModalExcSets = () => {
        setModalType('excSets')
        setIsOpen(true);
    };
    const handleModalClose = () => {
        setIsOpen(false)
        setDataToModal(null);
    };
    const navigate = useNavigate();

    return (
        <>
            {isOpen === true && 
            <NewSetModal isOpen={isOpen} 
            type={modalType} 
            dataToModal={dataToModal} 
            weekTotal={weekCount?.fitness_plan?.plan_weeks}
            handleModalClose={() => handleModalClose()} 
            handleGetWorkoutSets={() => handleGetWorkoutSets()} />}

            <PageStyle isMenuOpen={props?.menu}>

                <PageTitleStyle>List of Workout Sets</PageTitleStyle>
                {/* <Search data={workoutSets} filtered={setFiltered} setPageNumber={setPageNumber} /> */}
                <Search />

                <MenuButton onClick={handleMenuClick}>{props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>

                <PageContainer>
                    <TableResponsive isMenuOpen={props?.menu}>
                        <BackButton onClick={() => navigate(-1)}>
                        <i className="fa fa-chevron-left backIcon "></i><h6>Back</h6>

                        </BackButton>
                            <h3 className='text-center'>Sets for Workout: <span className='text-danger'>{weekCount?.name_al}</span></h3>
                        <Table>
                            <TableHead>
                                <TableRowHead>
                                    <TableHeadStyle tableFirstElement>id</TableHeadStyle>
                                    <TableHeadStyle>Reps</TableHeadStyle>
                                    <TableHeadStyle>Set UoM</TableHeadStyle>
                                    <TableHeadStyle>Set Value</TableHeadStyle>
                                    <TableHeadStyle>Set Week</TableHeadStyle>
                                    <TableHeadStyle>Set Day</TableHeadStyle>
                                    <CostumTableHeadStyle>Actions</CostumTableHeadStyle>
                                </TableRowHead>
                            </TableHead>
                            <TableBody>
                            {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>
              }
                {filtered && filtered?.length > 0 && <>
                  {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                        return(
                          <>
                                        <TableRowBody>
                                            <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                                            <TableBodyStyle>{el?.name}</TableBodyStyle>
                                            <TableBodyStyle>{el?.unit?.name_al}</TableBodyStyle>
                                            <TableBodyStyle>{el?.value}</TableBodyStyle>
                                            <TableBodyStyle>{el?.week_id}</TableBodyStyle>
                                            <TableBodyStyle>{el?.day_id !== null ? el?.day_id+1 : <span className='text-danger'>Not Set</span>}</TableBodyStyle>
                                            <TableBodyStyle className='text-center'>
                                                <EyeIcon className="" onClick={() => handleModalEdit(el)} />
                                                <span className=""></span>
                                            </TableBodyStyle>
                                        </TableRowBody>
                                        </>
                       )
                      })}</>
                    }

                            </TableBody>
                        </Table>
                        <Pagination>
              <div className="text-right">
                {((workoutSets?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
                    </TableResponsive>
                </PageContainer>

            </PageStyle>

            <MenuButton add onClick={() => { handleModalAdd(); }}>
                <i className="fa fa-plus" />
            </MenuButton>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        lang: state.data.lang,
        langData: state.data.langData,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        // loadingOff: () => dispatch(loadingOff()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListOfWorkoutSets);