import { LANG_AL, LANG_EN, LOADING_OFF, LOADING_ON, LOGGEDIN, LOGGMEOUT, SET_AUTH, RM_AUTH, USER_ID, ROLE, MENU, ACTIVEMENU, CUSTOMER_ID, EXTERNAL_DB,SETLANGDATA,SET_LANG } from './types';



export const loadingOn = () => {
    return { type: LOADING_ON }
};
export const loadingOff = () => {
    return { type: LOADING_OFF }
};
export const loggMeIN = () => {
    return { type: LOGGEDIN }
}


export const setAuth = (auth) => {
    return { type: SET_AUTH, auth: auth }
}
export const rmAuth = () => {
    return { type: RM_AUTH, auth: null }
}
export const setUserId = (id) => {
    return { type: USER_ID, user_id: id }
}
export const setCustomerId = (id) => {
    return { type: CUSTOMER_ID, customer_id: id }
}
export const setRole = (role) => {
    return { type: ROLE, role: role }
}
export const toggleMenu = () => {
    return { type: MENU }
}
export const setActiveMenu = (activemenu) => {
    return { type: ACTIVEMENU, activemenu: activemenu }
}
export const setLangData = (langdata) => {
    return { type: SETLANGDATA, langdata: langdata }
}
export const setLang = (id) => {
    return { type: SET_LANG, lang: id }
}