import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import { 
  MenuButton, 
  PageStyle, 
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  Pagination,
  EyeIcon } from "../../styles/MainLayout";
import EComPaymentTypesModal from "./eComPaymentTypesModal";
import Search from "../search/search";
import axios from "../../axios"
import ReactPaginate from "react-paginate";



const EComPaymentTypes = (props) => {

const [isOpen, setIsOpen] = useState(false);
const [modalType, setModalType] = useState(null);
const [dataToModal, setDataToModal] = useState();
const [paymentType, setPaymentType] = useState();
const [filtered, setFiltered] = useState([]);



  //Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 10;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }


const getAllPaymentTypes = () => {
    axios.get('/eCom-payment-type/all').then(data => {
      setPaymentType(data.data);
      setFiltered(data?.data);
    }).catch(err => {
      console.log('getAllPaymentTypes', err);
    })
  }
console.log("paymentType", paymentType)

const handleModalAdd = () => {
  setModalType('add')
  setIsOpen(true);
};

const handleModalEdit = (data) => {
  setModalType('edit')
  setDataToModal(data)
  setIsOpen(true);
};

const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
};    
useEffect(() => {
  getAllPaymentTypes();
}, []);
return (
      <>
      {isOpen === true ?
  
        <EComPaymentTypesModal dataToModal={dataToModal} isOpen={isOpen} type={modalType} getData={()=> getAllPaymentTypes()} handleModalClose={()=>{setIsOpen(); setDataToModal(null)}} />
        : ""
        }
        <PageStyle isMenuOpen={props?.menu}>

          <PageTitleStyle>EComPaymentTypes</PageTitleStyle>
            <Search data={paymentType} filtered={setFiltered} setPageNumber={setPageNumber} />
          <MenuButton onClick={handleMenuClick}>{props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>
            <PageContainer>
              <TableResponsive isMenuOpen={props?.menu}>
                <Table>
                  <TableHead>
                    <TableRowHead>
                    <TableHeadStyle tableFirstElement>ID</TableHeadStyle>
                      <TableHeadStyle>Menyra e pageses</TableHeadStyle>
                      <TableHeadStyle>Payment Types</TableHeadStyle>
                      <TableHeadStyle>Actions</TableHeadStyle>
                      <TableHeadStyle></TableHeadStyle>
                    </TableRowHead>
                  </TableHead>
                  <TableBody>
                  {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>
              }
                {filtered && filtered?.length > 0 && <>
                  {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                        return(
                          <>
                    <TableRowBody>
                    <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                      <TableBodyStyle >{el?.name_al}</TableBodyStyle>
                      <TableBodyStyle >{el?.name_en}</TableBodyStyle>
                      <TableBodyStyle><EyeIcon onClick={() => handleModalEdit(el) }/> </TableBodyStyle>           
                      <TableBodyStyle></TableBodyStyle>
                    </TableRowBody>
                          </>
                       )
                      })}</>
                    }
                  </TableBody>
                </Table>
                <Pagination>
              <div className="text-right">
                {((paymentType?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
            </TableResponsive>
          </PageContainer>

        </PageStyle>

        <MenuButton add onClick={() => {handleModalAdd();}}>
          <i className="fa fa-plus" />
        </MenuButton>
      </>
)
}

const mapStateToProps = (state) => {
    return {
      lang: state.data.lang,
      langData: state.data.langData,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu()),
      // loadingOff: () => dispatch(loadingOff()),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(EComPaymentTypes);