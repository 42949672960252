import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ECommerceOrdersModal = (props) => {
  console.log('order props ', props)
  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(props.dataToModal?.order_status_id || null);
  const [ecomOrderStatus, setEComOrderStatus] = useState([]);
  const [addEComOrder, setAddEComOrder] = useState({
    id: props?.dataToModal?.id || "",
    order_status_id: selectedStatus,
  });

  const getStatus = () => {
    axios
      .get("orders-status/all")
      .then((data) => {
        setEComOrderStatus(data.data);
      })
      .catch((err) => {
        console.log("ecomorderCateErr", err);
      });
  };

  const editECommerceOrder = (e) => {
    e.preventDefault();
    // console.log("Before update addEComOrder:", addEComOrder);

    let payload = {
      id: props?.dataToModal?.id,
      order_status_id: selectedStatus,
    };
    // console.log("Data to be sent for edit:", payload);

    axios.put(`/orders`, payload).then((data) => {
      // console.log("Server responsi:", data);
      toast.success("Të dhënat janë përditësuar me sukses!");
      props?.getData();
      props?.handleModalClose();
    })
      .catch((err) => {
        console.log(err);
        toast.error("Të dhënat nuk janë përditësuar!");
      });
  };

  const deleteECommerceOrder = () => {
    axios
      .delete(`/orders/${props?.dataToModal?.id}`)
      .then((data) => {
        toast.success("Të dhënat janë fshirë me sukses!");
        props?.handleModalClose();
        props?.getData();
      })
      .catch((err) => {
        console.log(err);
        toast.success("Të dhënat nuk janë fshirë me sukses!");
      });
  };

  const handleChange = (e) => {
    if (e?.target?.name === "status") {
      setSelectedStatus(e.target.value);
    } else {
      setAddEComOrder({
        ...addEComOrder,
        [e?.target?.name]: e?.target?.value,
      });
    }
  };
  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      props?.handleModalClose();
    }
  });

  useEffect(() => {
    getStatus();
    if (props.type === "edit" && props.dataToModal) {
      setAddEComOrder((prevData) => ({
        ...prevData,
        order_status_id: props.dataToModal.order_status_id || "",
      }));
      setSelectedStatus(props.dataToModal.order_status_id || "");
    } else {
      setAddEComOrder((prevData) => ({
        ...prevData,
        order_status_id: "",
      }));
      setSelectedStatus("");
    }
  }, [props.type, props.dataToModal]);

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    if (props?.isOpen) {
      setEdit(false);
      setDelete(false);
    }
  }, [props?.isOpen]);


  const inputDate = new Date(props?.dataToModal?.date);

  const options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  };

  const formattedDate = inputDate.toLocaleString('en-US', options);

  const totalPriceForEachProduct = props?.dataToModal?.order_products?.map((el) =>
    el?.is_variation === false
      ? el?.qty_ordered * el?.prod_sale_price
      : el?.qty_ordered * el?.ordered_variation?.sale_price_variation
  );

  const overallTotalPrice = totalPriceForEachProduct?.reduce((acc, totalPrice) => acc + totalPrice, 0);

  // console.log("Total Price for Each Product", totalPriceForEachProduct);
  // console.log("Overall Total Price", overallTotalPrice);

  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>
              <ModalActionButtonWrapper>
                {props?.type !== "add" ? (
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                                            <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                                        </ModalButtons> */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons
                      close
                      onClick={() => props?.handleModalClose()}
                    >
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                ) : (
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                )}
              </ModalActionButtonWrapper>

              {isdelete === false && (
                <Form onSubmit={editECommerceOrder}>
                  <ModalTitle title>Order #SHF-{props?.dataToModal?.id} details</ModalTitle>

                  <div className="form-group">
                    <div className="d-flex">
                      <p className="me-1 mb-0">Date:</p>
                      <p className="mb-0">{formattedDate}</p>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-flex">
                      <p className="me-1 mb-0">Transaction ID:</p>
                      <p className="mb-0">{String(props?.dataToModal?.transaction_id)}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="d-flex">
                      <p className="me-1 mb-0">Name and Surname:</p>
                      <p className="mb-0">{props?.dataToModal?.name_surname}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="d-flex">
                      <p className="me-1 mb-0">Phone number:</p>
                      <p className="mb-0">{props?.dataToModal?.phone}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="d-flex">
                      <p className="me-1 mb-0">Address:</p>
                      <p className="mb-0">{props?.dataToModal?.address}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="d-flex">
                      <p className="me-1 mb-0">Email:</p>
                      <p className="mb-0">{props?.dataToModal?.email}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="d-flex">
                      <p className="me-1 mb-0">City:</p>
                      <p className="mb-0">{props?.dataToModal?.city?.name}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="d-flex">
                      <p className="me-1 mb-0">Country:</p>
                      <p className="mb-0">{props?.dataToModal?.city?.country?.name}</p>
                    </div>
                  </div>

                  <hr />

                  <h6>Cart items:</h6>

                  {/* <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <p className="my-0">Fitness Gloves #1414-sku</p>
                      </div>
                      <div className="form-group">
                        <p className="my-0">Gain Weith Protein #16116-sku</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <p className="my-0">10.50 €</p>
                      </div>
                      <div className="form-group">
                        <p className="my-0">15.30 €</p>
                      </div>
                    </div>
                  </div> */}
                  {console.log("{props?.dataToModal?.city?.country?.name}", props?.dataToModal?.order_products)}
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Image</th>
                        <th scope="col">Product name</th>
                        <th scope="col">Order variation's</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Price</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props?.dataToModal?.order_products?.map((el, index) =>
                        <tr>
                          {console.log("firstel", el)}
                          <th scope="row">{
                            el?.e_commerce?.e_commerce_images?.length > 0 ?
                              <img className="rounded w-75 img-thumbnail" src={process.env.REACT_APP_UPLOADS + el?.e_commerce?.e_commerce_images[index]?.image} alt={index} style={{ width: "150px;" }} />
                              : 'No Product Image'
                          }

                          </th>
                          <th scope="row">{el?.e_commerce?.title_al}</th>
                          <td >{el?.is_variation == true ? `${el?.ordered_variation?.FirstVariation[0]?.name_al}   ${el?.ordered_variation?.SecondVariation[0]?.name_al ? ` / ${el?.ordered_variation?.SecondVariation[0]?.name_al}` : ''}` : 'No Variation'}</td>
                          <td >{el?.qty_ordered}</td>
                          <td >{el?.is_variation == false ? el?.prod_sale_price : el?.ordered_variation?.sale_price_variation}</td>
                          <td >{el?.is_variation == false ? el?.qty_ordered * el?.prod_sale_price : el?.qty_ordered * el?.ordered_variation?.sale_price_variation}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <hr />

                  <div className="form-group">
                    <div className="d-flex">
                      <p className="me-1 mb-0">Total :</p>
                      <p className="mb-0">{overallTotalPrice} €</p>
                    </div>
                  </div>

                  {/* <div className="form-group">
                    <div className="d-flex">
                      <p className="me-1 mb-0">VAT (18%):</p>
                      <p className="mb-0">4.64 €</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="d-flex">
                      <p className="me-1 mb-0">Total (inc. VAT):</p>
                      <p className="mb-0">25.80 €</p>
                    </div>
                  </div> */}

                  <hr />

                  <div className="form-group">
                    <div className="d-flex">
                      <p className="me-1 mb-0">Payment type:</p>
                      <p className="mb-0"> Cash</p>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <div className="d-flex">
                      <p className="col-3 me-1 mb-0">Edit order status:</p>
                      <select
                        id="name"
                        name="status"
                        className="form-control"
                        onChange={handleChange}
                        value={selectedStatus}
                      >
                        <option hidden disabled value="">Select order status</option>
                        {ecomOrderStatus?.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.name_en}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {props?.type === "add" && (
                    <SaveButtonModal type="submit">Ruaj</SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal onClick={editECommerceOrder} type="submit">
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>
              )}
              {isdelete && (
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>
                      Deshironi te fshini te dhenat?
                    </ModalTitle>
                    <DeleteButtonModal onClick={deleteECommerceOrder}>
                      Fshij
                    </DeleteButtonModal>
                  </DeleteMessage>
                </>
              )}
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal >
      </>
    );
  } else {
    return null;
  }
};

export default ECommerceOrdersModal;
