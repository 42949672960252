import React, { useEffect, useState } from "react";

const ECommerceTermVariations = (props) => {
  console.log("props", props?.number)
  const [items, setItems] = useState(props?.initialItems);
  const [text, setText] = useState('');


  useEffect(() => {
    setItems(props?.initialItems);
  }, [props?.initialItems]);

  const addItem = () => {
    if (text) {
      const newItem = { name: text, group_id: props?.number };
      setItems([...items, newItem]);
      setText('');
      props.sendItemsToParent([...items, newItem]);
    };
    props?.handleModalClose()
  }


  console.log("items", items)
  return (
    <div>
      <div className="card p-4 my-3">
        <div className="d-flex align-items-center justify-content-between">
          <h4>Add {props?.name}</h4>
          <button type="button" class="btn-close" aria-label="Close" onClick={() => props?.handleModalClose()}></button>
        </div>
        <label htmlFor="">Name:</label>
        <input
          //   required
          id="name"
          type="text"
          placeholder="Name"
          className="form-control"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <button className="btn btn-success mt-3" onClick={addItem}>Add</button>
      </div>
    </div>
  )
}

export default ECommerceTermVariations