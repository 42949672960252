import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import { 
  MenuButton, 
  PageStyle, 
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  EyeIcon,
  Pagination } from "../../styles/MainLayout";
import PersonalTrainersModal from "./personalTrainersModal";
import Search from "../search/search";
import axios from "../../axios";
import ReactPaginate from "react-paginate";


const PersonalTrainers = (props) => {

const [isOpen, setIsOpen] = useState(false);
const [modalType, setModalType] = useState(null);
const [personalTrainers, setPersonalTrainers] = useState();
const [city, setCity] = useState();
const [country, setCountry] = useState();
const [dataToModal, setDataToModal] = useState(null);
const [filtered, setFiltered] = useState([]);

  //Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 8;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }

const getPersonalTrainers = () => {
  axios.get('/personal-trainers/all').then(data => {
    setPersonalTrainers(data.data);
    setFiltered(data?.data);
  }).catch(err => {
    console.log(err);
  })
}

const getCities = () => {
  axios.get('/cities/all').then(data => {
    setCity(data.data);
  }).catch(err => {
    console.log(err);
  })

  axios.get('/countries/all').then(data => {
    setCountry(data.data);
  }).catch(err => {
    console.log(err);
  })
}

// console.log("personal trainers", personalTrainers)

useEffect(() => {
  getPersonalTrainers()
  getCities()
},[])

const handleModalAdd = () => {
  setModalType('add')
  setIsOpen(true);
};

const handleModalEdit = (data) => {
  setModalType('edit')
  setIsOpen(true);
  setDataToModal(data);
};

const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
};    
  
return (
      <>
     {isOpen === true ?
        <PersonalTrainersModal getData={() => getPersonalTrainers()} isOpen={isOpen} type={modalType} handleModalClose={()=>{setIsOpen() ; setDataToModal(null)}} data={dataToModal} cities={city} countries={country} />
        : ""
      }

        <PageStyle isMenuOpen={props?.menu}>

          <PageTitleStyle>Personal Trainers</PageTitleStyle>
          <Search data={personalTrainers} filtered={setFiltered} setPageNumber={setPageNumber} />
          <MenuButton onClick={handleMenuClick}>{props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>

            <PageContainer>
              <TableResponsive isMenuOpen={props?.menu}>
                <Table>
                  <TableHead>
                    <TableRowHead>
                      <TableHeadStyle tableFirstElement>ID</TableHeadStyle>
                      <TableHeadStyle >First Name </TableHeadStyle>
                      <TableHeadStyle >Last Name </TableHeadStyle>
                      <TableHeadStyle>E-mail</TableHeadStyle>
                      <TableHeadStyle>Active</TableHeadStyle>
                      <TableHeadStyle>City</TableHeadStyle>
                      <TableHeadStyle>Ambasador</TableHeadStyle>
                      <TableHeadStyle>Order ID</TableHeadStyle>
                      <TableHeadStyle>Action</TableHeadStyle>
                    </TableRowHead>
                  </TableHead>
                  <TableBody>
                  {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>}
                    {filtered && filtered?.length > 0 && <>
                      {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                        console.log("elementetn", el)
                        return (
                          <>
                              <TableRowBody key={el.id}>
                                <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                                <TableBodyStyle >{el?.first_name}</TableBodyStyle>
                                <TableBodyStyle >{el?.last_name}</TableBodyStyle>
                                <TableBodyStyle >{el?.email}</TableBodyStyle>
                                <TableBodyStyle >{el?.active ? <i className="fa fa-check"/> : <i className="fa fa-times"/>}</TableBodyStyle>
                                <TableBodyStyle >{city?.find(elem => elem?.id == el?.city_id)?.name || 'Country not found'}</TableBodyStyle>
                                <TableBodyStyle >{el?.personal_trainer?.ambasador ?  <i className="fa fa-check"/> : <i className="fa fa-times"/>}</TableBodyStyle>
                                <TableBodyStyle >{el?.personal_trainer?.order_id}</TableBodyStyle>
                                <TableBodyStyle><EyeIcon onClick={() => handleModalEdit(el)} /> </TableBodyStyle>
                              </TableRowBody>
                              </>
                        )
                      })}</>
                    }
                  </TableBody>  
                </Table>

            <Pagination>
              <div className="text-right">
                {((personalTrainers?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
            </TableResponsive>
          </PageContainer>

        </PageStyle>

        <MenuButton add onClick={() => {handleModalAdd();}}>
          <i className="fa fa-plus" />
        </MenuButton>
      </>
)
}

const mapStateToProps = (state) => {
    return {
      lang: state.data.lang,
      langData: state.data.langData,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu()),
      // loadingOff: () => dispatch(loadingOff()),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(PersonalTrainers);