const AdminMenuData = [
    {
        name_al: "Dashboard",
        url: "/",
        icon: "fa-solid fa-list",
        id: 1
    },
    {
        name_al: "Users",
        url: "/settings/users",
        icon: "fa-solid fa-user-group",
        id: 2
    },
    {
        name_al: "Fitness's",
        url: "/settings/fitness",
        icon: "fa-solid fa-dumbbell",
        id: 3
    },
    // {
    //     name_al: "Sets Settings",
    //     url: "/admin/sets-settings",
    //     icon: "fa-solid fa-map-location-dot",
    //     id: 2
    // },
    {
        name_al: "Training Lessons",
        url: "/trainingLessons",
        icon: "fa-solid fa-book",
        id: 4
    },
    {
        name_al: "News",
        url: "/news",
        icon: "fa-solid fa-newspaper",
        id: 5
    },
    {
        name_al: "E-Commerce",
        url: "/eCommerce",
        icon: "fa-solid fa-shop",
        id: 6
    },
    {
        name_al: "e-Com Product Categories",
        url: "/settings/e-com-product-categories",
        icon: "fa-solid fa-bag-shopping", 
        idS: 611
    },
    {
        name_al: "Settings",
        icon: "fa fa-home",
        id: 7,
        more: [
            {
                name_al: "Workout Categorisation",
                url: "/settings/workout-categorisation",
                icon: "fa-solid fa-folder-tree",
                idS: 601
            },
            {
                name_al: "Sets Settings",
                url: "/settings/sets-settings",
                icon: "fa-solid fa-sliders",
                idS: 602
            },
            {
                name_al: "Excercise Levels",
                url: "/settings/excercise-levels",
                icon: "fa-solid fa-stopwatch-20", 
                idS: 603
            },
            {
                name_al: "Locations",
                url: "/settings/locations",
                icon: "fa-solid fa-map-marker",
                idS: 604
            },
          
            {
                name_al: "Activity Levels",
                url: "/settings/activity-levels",
                icon: "fa-solid fa-signal",
                idS: 605
            },
            {
                name_al: "Goals",
                url: "/settings/goals",
                icon: "fa-solid fa-bullseye",
                idS: 606
            },
            {
                name_al: "Diet Types",
                url: "/settings/diet-types",
                icon: "fa-solid fa-plate-wheat", 
                idS: 607
            },
            {
                name_al: "Calories Range",
                url: "/settings/calories-range",
                icon: "fa-solid fa-weight-scale",
                idS: 608
            },
            {
                name_al: "Equipments",
                url: "/settings/equipments",
                icon: "fa-solid fa-dumbbell",
                idS: 609
            },
            {
                name_al: "Training Lessons Categories",
                url: "/settings/training-lessons-categories",
                icon: "fa-solid fa-person-chalkboard",
                idS: 610
            },
            {
                name_al: "e-Com Payment Types",
                url: "/settings/e-com-payment-types",
                icon: "fa-solid fa-list-alt",
                idS: 612
            },
            {
                name_al: "Countries",
                url: "/settings/countries",
                icon: "fa-solid fa-earth-europe",
                idS: 613
            },
            {
                name_al: "Cities",
                url: "/settings/cities",
                icon: "fa-solid fa-city",
                idS: 614
            },
            {
                name_al: "Packages",
                url: "/settings/packages",
                icon: "fa-solid fa-box",
                idS: 615
            },
            {
                name_al: "News Categories",
                url: "/settings/news-categories",
                icon: "fa-solid fa-newspaper",
                idS: 616
            },
            {
                name_al: "Order Status",
                url: "/settings/order-status",
                icon: "fa-solid fa-list-alt",
                idS: 699
            }
                 
]},
    {
        name_al: "Personal Trainers",
        url: "/personal-trainers",
        icon: "fa-solid fa-person-chalkboard",
        id: 8
    },
        
    {
        name_al: "Fitness Plans",
        url: "/fitness-plans",
        icon: "fa-solid fa-ranking-star",
        id: 9
    },
    {
        name_al: "Languages",
        url: "/languages",
        icon: "fa fa-language",
        id: 10
    },
    {
        name_al: "Labels",
        url: "/labels",
        icon: "fa fa-tags",
        id: 11
    },
    {
        name_al: "Manage Files",
        url: "/file-manager",
        icon: "fa fa-tags",
        id: 12
    },

]

export default AdminMenuData;










