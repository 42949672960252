import React, { useEffect, useState, useRef } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import FitnessMap from "./FitnessMap";


import axios from "../../axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import sign from "jwt-encode";

const FitnessModal = (props) => {

  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);

  const [fitnessCountries, setFitnessCountries] = useState();
  const [selectedCountry, setSelectedCountry] = useState(props?.fitness?.country_id || null);
  const [fitnessCities, setFitnessCities] = useState();
  const [selectedCity, setSelectedCity] = useState(props?.fitness?.city_id || null);

  const [initialLogo, setInitialLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  const [latitude, setLatitude] = useState(props?.dataToModal?.latitude || "");
  const [longitude, setLongitude] = useState(props?.dataToModal?.longitude || "");

  const [password, setPassword] = useState("");


  const [addFitness, setAddFitness] = useState({
    id: props?.dataToModal?.id,
  });


  // console.log("addFitness", addFitness)


  const getCountryData = () => {
    axios.get('/countries/all').then(data => {
      setFitnessCountries(data.data);
    }).catch(err => {
      console.log('fitCountryErr', err)
    })
  }

  const getCityData = () => {
    axios.get('/cities/all').then(data => {
      setFitnessCities(data.data);
    }).catch(err => {
      console.log('fitCityErr', err)
    })
  }


  const [galleryData, setGalleryData] = useState([])
  const [existingImages, setExistingImages] = useState([]);

  const handleGalleryImageUpload = (e) => {
    const files = e.target.files;
    const updatedGallery = [...galleryData]

    for (let i = 0; i < files.length; i++) {
      updatedGallery.push(files[i])
    }
    setGalleryData([...updatedGallery]);
  }

  // console.log("galleryData", galleryData)

  useEffect(() => {
    if (props.type === 'edit' && props.dataToModal?.fitness_images) {
      setExistingImages(props.dataToModal?.fitness_images);
    } else {
      setExistingImages([]);
    }
  }, [props.type, props.dataToModal?.fitness_images]);


  useEffect(() => {
    if (props.type === 'edit' && props.dataToModal?.fitness_images) {
      setExistingImages(props.dataToModal?.fitness_images);
    } else {
      setExistingImages([]);
    }
  }, [props.type, props.dataToModal?.fitness_images]);



  const addTheFitness = (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.keys(addFitness).forEach((key) => {
      formData.append(key, addFitness[key])
    })

    for (const existingImage of existingImages) {
      formData.append('fitness_images', existingImage.image);
    }

    for (const galleryImg of galleryData) {
      formData.append('fitness_gallery', galleryImg)
    }

    e.preventDefault();

    axios.post(`/fitness`, formData)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e fitnesit janë shtuar me sukses!')
      }).catch(err => {
        toast.error('Të dhënat e fitnesit nuk janë shtuar!')
      })
  }

  const editFitness = (e) => {
    e.preventDefault()

    const formData = new FormData();

    Object.keys(addFitness).forEach((key) => {
      formData.append(key, addFitness[key])
    })

    if (existingImages.length > 0) {
      existingImages.forEach((existingImage) => {
        formData.append('deletedImages', existingImage.id);
      });
    }
    
    for (const galleryImg of galleryData) {
      formData.append('fitness_gallery', galleryImg)
    }

    // if (password !== "") {
    //   formData.append("password", password);
    // }

    axios.put(`/fitness`, formData)
      .then(data => {
        toast.success('Të dhënat e fitnesit janë përditësuar me sukses!');
        props?.getData();
        props?.handleModalClose();
      }).catch(err => {
        console.log(err);
        toast.error('Të dhënat e fitnesit nuk janë përditësuar!');
      })
  }

  const handleRemoveExistingImage = async (imageId) => {
    try {
      await axios.delete(`/fitness/images/${imageId}`);

      const updatedExistingImages = existingImages.filter(image => image.id !== imageId);
      setExistingImages(updatedExistingImages);

      // toast.success('Image removed successfully!');
    } catch (error) {
      console.error('Error removing image:', error);
      toast.error('Failed to remove image.');
    }
  };


  const deleteFitness = () => {
    axios.delete(`/fitness/${props?.dataToModal?.id}`).then(data => {
      toast.success('Të dhënat e fitnesit janë fshirë me sukses!');
      props?.handleModalClose();
      props?.getData();
    }).catch(err => {
      console.log(err);
      toast.success('Të dhënat e fitnesit nuk janë fshirë me sukses!');
    })
  }

  const handleChange = (e) => {
    let secretKey = process.env.REACT_APP_SECRET;

    if (e.target.name === "password") {
      let encryptedPassword = sign(e.target.value, secretKey);
      setPassword(e.target.value); // Update the password state
      setAddFitness({
        ...addFitness,
        [e.target.name]: encryptedPassword,
      });
    } else {
      setAddFitness({
        ...addFitness,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    setAddFitness({
      ...addFitness,
      latitude: latitude,
      longitude: longitude,
    });
  }, [latitude, longitude]);

  const handleLocationChange = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
  };



  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })

  useEffect(() => {
    getCountryData();
    getCityData();
  }, [])

  useEffect(() => {
    if (props?.isOpen) {
      setEdit(false);
      setDelete(false);
    }
  }, [props?.isOpen]);

  useEffect(() => {
    if (props.type === 'edit' && addFitness.logo) {
      setInitialLogo(addFitness.logo);

      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(addFitness.logo);

    } else {
      setInitialLogo(null);
      setLogoPreview(null);
    }
  }, [props.type, addFitness.logo]);




  const logo = (e) => {
    const ud = Object.assign({}, addFitness);
    ud.fitness = e.target.files[0];
    setAddFitness(ud);

    if (ud.fitness) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(ud.fitness);
    } else {
      setLogoPreview(null);
    }

    console.log("logo data", ud);
  };



  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>


              {isdelete === false && (
                <Form onSubmit={props?.type === "add" ? addTheFitness : props?.type === "edit" ? editFitness : ""} >

                  <ModalTitle title> {props?.type === "add" ? "Add Fitness" : "Edit Fitness"}</ModalTitle>

                  <div className="form-group mb-2">
                    <label>Fitness name: <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                    <input
                      required
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.name : ""}
                      placeholder="ex. Lets Go Gym"
                    />
                  </div>

                  <div className="form-group mb-2">
                    <label>Country: <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                    <select
                      className="form-control"
                      required
                      id="name"
                      name="country_id"
                      onChange={handleChange}
                      value={props?.type === "edit" ? selectedCountry : null}
                    >
                      <option>Select Country</option>
                      {fitnessCountries?.map((el) => (
                        <option key={el?.id} value={el?.id}>
                          {el?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group mb-2">
                    <label>City: <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                    <select
                      className="form-control"
                      required
                      id="name"
                      name="city_id"
                      onChange={handleChange}
                      value={props?.type === "edit" ? selectedCity : null}
                    >
                      <option>Select City</option>
                      {fitnessCities?.map((el) => (
                        <option key={el?.id} value={el?.id}>
                          {el?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group mb-3">
                    {logoPreview && (
                      <div>
                        <img src={logoPreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '120px' }} />
                      </div>
                    )}

                    {!logoPreview && props?.type === "edit" &&
                      <div>
                        <img src={`${process.env.REACT_APP_UPLOADS + props?.dataToModal?.logo}`} alt="Logo Preview" style={{ maxWidth: '100%', maxHeight: '120px' }} />
                      </div>
                    }
                  </div>

                  <div className="form-group mb-3">
                    <label className="me-2">
                      {props.type === "add" ? "Upload Logo:" : "Change Logo:"}
                    </label>
                    <input
                      type="file"
                      name="fitness"
                      onChange={logo}
                    />
                  </div>



                  <div className="form-group mb-2">
                    <label className="me-2">Upload Images:</label>
                    <input
                      onChange={handleGalleryImageUpload}
                      type="file"
                      multiple
                      name="fitness_gallery"
                      accept="image/png, image/jpeg"
                    />

                    
                        {existingImages.length > 0 && (
                          <div className="existing-images mb-4">
                            {/* <h4>Uploaded Images:</h4> */}
                            <div className="image-preview row">
                              {existingImages.map((existingImage, index) => (
                                <div key={index} className="image-preview-item col-3">
                                  <img
                                    src={`${process.env.REACT_APP_UPLOADS + existingImage.image}`}
                                    alt={`Existing Image ${index}`}
                                    style={{ width: '100px', height: '100px', objectFit: "cover", margin: '5px' }}
                                  />
                                 <button type="button"  className="btn btn-dark mx-2 my-2" onClick={() => handleRemoveExistingImage(existingImage.id)}>
                                    Remove
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                    {galleryData.length > 0 && (
                      <div className="image-preview">
                        {galleryData.map((image, index) => (
                          <img
                            key={index}
                            src={URL.createObjectURL(image)}
                            alt={`Newly Added Image ${index}`}
                            style={{ maxWidth: '100px', maxHeight: '100px', margin: '5px' }}
                          />
                        ))}
                      </div>
                    )}

                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group mb-2">
                        <label>Latitude: </label>
                        <input
                          type="number"
                          className="form-control"
                          name="latitude"
                          onChange={(e) => setLatitude(e.target.value)}
                          value={latitude}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group mb-2">
                        <label>Longitude: </label>
                        <input
                          type="number"
                          className="form-control"
                          name="longitude"
                          onChange={(e) => setLongitude(e.target.value)}
                          value={longitude}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-2">
                    <FitnessMap
                      onLocationChange={handleLocationChange}
                      selectedLocation={{ lat: latitude, lng: longitude }}
                    />
                  </div>

                  <div className="form-group mb-2">
                    <label>Pershkrimi:</label>
                    <textarea
                      id="name"
                      name="description_al"
                      type="text"
                      className="form-control"
                      placeholder="p.sh Lorem Ipsum"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.description_al : ""}
                      rows="4"
                      cols="11"
                    />
                  </div>

                  <div className="form-group mb-2">
                    <label>Description:</label>
                    <textarea
                      id="name"
                      name="description_en"
                      type="text"
                      className="form-control"
                      placeholder="ex. Lorem Ipsum"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.description_en : ""}
                      rows="4"
                      cols="11"
                    />
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group mb-2">
                        <label>Username/email:</label>
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Username/email"
                          defaultValue={props?.type === "edit" ? props?.fitness?.user?.email : ""}
                          disabled={props?.type == 'edit' ? true : false}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group mb-2">
                        <label>Password:</label>
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Password"
                          defaultValue={props?.type === "edit" ? props?.fitness?.user?.password : ""}
                          // disabled={props?.type == 'edit' ? true : false}
                        />
                      </div>
                    </div>
                  </div>

                  {props?.type === "add" && (
                    <SaveButtonModal type="submit" onClick={addTheFitness}>
                      Ruaj
                    </SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal type="submit" onClick={editFitness}>
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>


              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={deleteFitness}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default FitnessModal; 
