import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios"
import { toast } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';





const CitiesModal = (props) => {
  console.log("sssspropsat", props)
  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [countries, setCountries] = useState();
  const [selectedCountry, setSelectedCountries] = useState(props?.city?.country_id || null);
  // const [userData, setUserData] = useState();
  const [addCity, setAddcity] = useState({
    id: props?.city?.id,
    // name: '',
    // country_id: ''
  })
  console.log("addCity", addCity)

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })
  const handleChange = (e) => {
    if (e?.target?.name === "country_id") {
      setSelectedCountries(e.target.value);
    }
    setAddcity({
      ...addCity,
      [e?.target?.name]: e?.target?.value,
    })

  }


  const getUserData = () => {
    axios.get('/countries/all').then(data => {
      setCountries(data.data);
    }).catch(err => {
      console.log('userProfileGetErr', err);
    })
  }
  const deletCity = () => {
    axios.delete(`/cities/${props?.city?.id}`).then(data => {
      toast.success('Të dhënat  janë fshir me sukses!');

      props?.handleModalClose();
      props?.getData();


    }).catch(err => {
      console.log('userProfileGetErr', err);
      toast.error('Të dhënat nuk jan fshir me sukses!');

    })
  }


  const HandleAddCity = (e) => {
    e.preventDefault();
    axios.post('/cities', addCity)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e qytetit janë shtuar me sukses!');


      }
      ).catch(err => {
        toast.error('Te dhenat nuk jane shtuar!');
        console.log(err);
      });
  }

  const handelEditSubmmision = (e) => {
    // console.log("editttt")
    e.preventDefault();
    setEdit(false);

    axios.put(`/cities`, addCity)
      .then(data => {
        toast.success('Të dhënat e qytetit janë përditësuar me sukses!');
        props?.getData();
        props?.handleModalClose();

      })
      .catch(err => {
        console.log(err);
        toast.error('Të dhënat e qytetit nuk janë përditësuar!');


      });
  }
 


  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (props?.isOpen) {
      setEdit(false);
      setDelete(false);
    }
  }, [props?.isOpen]);


  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                        <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons>  */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>


              {isdelete === false && (
                <Form >
                  <ModalTitle title>Modal Title</ModalTitle>
                  <div className="form-group">
                    <label>Name:</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.city?.name : ''}
                      className="form-control"
                      name="name"
                      placeholder="emri"
                      disabled={!isEdit || props?.type === "add" ? false : true}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Country:</label>
                    <select
                      name="country_id"
                      onChange={handleChange}
                      disabled={!isEdit || props?.type === 'add' ? false : true}
                      className="form-control text-capitalize"
                      value={props?.type === "edit" ? selectedCountry : null}
                      defaultValue={props?.type === "edit" ? selectedCountry : null}
                      // defaultValue={props?.type === "edit" ? props?.user?.gender_id : null}

                    >
                      
                      <option value={null} hidden selected disabled>Select</option>
                      {countries?.map((el) => (
                        <option key={el?.id} value={el?.id}>
                          {el?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div className="form-group">
                        <label>Country:</label>
                        <input
                        type="text"
                        onChange={handleChange}
                        defaultValue={props?.city?.country_id}
                        className="form-control"
                        name="country_id"
                        placeholder="Country"
                        disabled={!isEdit || props?.type === "add" ? false : true}
                        />
                    </div> */}
                  {/* <div className="form-group">
                        <label>Mbiemer:</label>
                        <select
                        disabled={isEdit || props?.type === "add" ? false : true}
                        className="form-control text-capitalize"
                        >
                        <option>popo </option>
                        <option>joopo </option>
                        <option>popaa </option>
                        <option>poopo </option>
                        </select>
                    </div> */}


                  {props?.type === "add" && (
                    <SaveButtonModal type="submit" onClick={HandleAddCity}>
                      Ruaj
                    </SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal onClick={handelEditSubmmision} type="submit" disabled={!isEdit || props?.type === "add" ? false : true}>
                      Ndrysho

                    </SaveButtonModal>
                  )}
                </Form>


              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={deletCity}>Fshij</DeleteButtonModal>

                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>

          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default CitiesModal;
