import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import { 
  MenuButton, 
  PageStyle, 
  PageTitleStyle,
  TableResponsive,
  PageContainer,
 } from "../../styles/MainLayout";
import Search from "../search/search";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from "../../axios";


const FileManager = (props) => {

    const [personalTrainers, setPersonalTrainers] = useState();

    const getPersonalTrainers = () => {
        axios.get('/personal-trainers/all').then(data => {
          setPersonalTrainers(data.data);
        }).catch(err => {
          console.log(err);
        })
      }

useEffect(()=>{
    getPersonalTrainers();
},[])

return (
      <>

        
        <PageStyle isMenuOpen={props?.menu}>

          <PageTitleStyle>File Manager</PageTitleStyle>
          <Search />
          <MenuButton>{props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>

          <PageContainer>
        
          <TableResponsive isMenuOpen={props?.menu}>
                <h1 className="text-center">Manage files of trainers</h1>
          <div class="row m-4">
            {personalTrainers?.map((el)=>{
                return(
                    <>
            <div class="col-xl-4 col-lg-6 mb-3">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">Trainer</h5>
                        <span class="h3 font-weight-bold mb-0">{el?.first_name} {el?.last_name}</span>
                      </div>
                      <div class="col-auto m-auto">
                        <Link to={`/files/${el?.id}`}>
                        <div class="icon icon-shape bg-dark text-white rounded-circle shadow">
                          <i class="fas fa-users"></i>
                        </div>
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
                    </>
                )
            })}
        
              </div>
         
          </TableResponsive>
          </PageContainer>

        </PageStyle>

        {/* <MenuButton add onClick={() => {handleModalAdd();}}>
          <i className="fa fa-plus" />
        </MenuButton> */}
      </>
)
}

const mapStateToProps = (state) => {
    return {
      lang: state.data.lang,
      langData: state.data.langData,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu()),
      // loadingOff: () => dispatch(loadingOff()),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(FileManager);