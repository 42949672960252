import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios"
import { toast } from "react-toastify";


const TrainingLessonsCategoriesModal = (props) => {
  // const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })


  //AXIOS

  const addTrainingLessonCategory = (e) => {
    e.preventDefault()
    let body = {
      name_al: e?.target?.name_al?.value,
      name_en: e?.target?.name_en?.value,
    }
    axios.post("/training-lessons-cat", body).then(
      response => {
        toast.success("Training lesson category added successfully")
        props?.handleModalClose()
        props?.getTrainingLessonsCat()
        console.log("add Response :", response)
      }
    ).catch(err => {
      console.log("error :", err)
    toast.error("Something went wrong")

    })
    console.log("Add Training Lesson :", body)
  }


  const editTrainingLessonCategory = (e) => {
    e.preventDefault()
    let body = {
      id: props?.data?.id,
      name_al: e?.target?.name_al?.value,
      name_en: e?.target?.name_en?.value,
    }
    axios.put("/training-lessons-cat/", body).then(
      response => {
        console.log("Edit response: ", response)
        toast.success("Training lesson category edited successfully")
        props?.handleModalClose()
        props?.getTrainingLessonsCat()
      }
    ).catch(err => {
      console.log("edit error: ", err)
    toast.error("Something went wrong")

    })
    console.log("edit Training Lesson :", body)
  }


  const deleteTrainingLessonCategory = (id) => {
    axios.delete(`/training-lessons-cat/${id}`).then(
      response => {
        console.log("delete res:", response)
        toast.success("Training lesson category deleted successfully")
        props?.getTrainingLessonsCat()
        props?.handleModalClose()
      }
    ).catch(err =>{
    console.log("err",err)
    toast.error("Something went wrong")
    })
  }

  useEffect(() => {
    if (props?.isOpen) {
      // setEdit(false);
      setDelete(false);
    }
  }, [props?.isOpen]);

  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle onClick={() => props?.handleModalClose()}>
            <Mymodal_wrapper onClick={(e) => e.stopPropagation()}>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                        <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons>  */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>


              {isdelete === false && (
                <Form onSubmit={props?.type === "add" ? addTrainingLessonCategory : props?.type === "edit" ? editTrainingLessonCategory : ""} >
                  <ModalTitle title>Add Training Lesson Category</ModalTitle>
                  <div className="form-group">
                    <label>Emri i kategorise se trajnimit:<span className="text-danger">*</span></label>
                    <input
                      required
                      id="name"
                      name="name_al"
                      type="text"
                      className="form-control"
                      placeholder="e.x Trajnim Fuqi"
                      defaultValue={props?.type === "add" ? "":props?.data?.name_al}
                    />
                    <label>Training lesson category name:</label>
                    <input
                      id="name"
                      name="name_en"
                      type="text"
                      className="form-control"
                      placeholder="e.x Strength Training"
                      defaultValue={props?.type === "add" ? "":props?.data?.name_en}
                    />
                  </div>

                  {props?.type === "add" && (
                    <SaveButtonModal type="submit">
                      Ruaj
                    </SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal type="submit" >
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>


              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={() => deleteTrainingLessonCategory(props?.data?.id)}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default TrainingLessonsCategoriesModal;
