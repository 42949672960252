import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";


const TranslationComponent = (props) => {
    console.log("props",props?.langData)
    // Find the translation for the selected language and key
    const translation = props?.langData?.map((el)=>{
        console.log("el",el)
       return el?.allValues?.find(
            (t) => t.key_name === props?.keyName && t.lang_id === props?.lang
          );
    })?.filter(Boolean);

    const displayText = translation?.length > 0 ? translation[0]?.value : 'Translation not available';

    return <span>{displayText}</span>;
  };

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TranslationComponent);