import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
  ReplicateButtonModal,
} from "../../styles/MainLayout";
import axios from "../../axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const FitnessPlansWeeksModal = (props) => {
  console.log("first", props)
  console.log("CountWeeks", props.weeksCount)
  const plan_id = useParams();
  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [orderShow, setOrderShow] = useState(false);
  const [planWorkouts, setPlanWorkouts] = useState([]);
  const [existingPlanWorkouts, setExistingPlanWorkouts] = useState([]);
  const [allWeeksDays, setAllWeeksDays] = useState([]);
  const [checkedWorkouts, setCheckedWorkouts] = useState([]);
  const [uncheckedWorkouts, setUncheckedWorkouts] = useState([]);
  const [changesTrigger, setChangesTrigger] = useState(true);
  const [trainerId, setTrainerId] = useState();
  const [dataToSave, setDataToSave] = useState({
    id: props?.planWeekdata.day.id
  })

  const [updatedAnswerOrder , setUpdateAnswerOrder]= useState([]);
  const [dayImages , setDayImages]= useState();
  const fitnessPlanId = useParams();

  


  const handleCheckData = (event, id,index ) => {
    const isChecked = event?.target?.checked;
    const orderId = event?.target?.value;
 
    if (isChecked) {
      // setOrderShow(index);
      if (!existingPlanWorkouts.some((workout) => workout.id === id)) {
        setCheckedWorkouts((prevCheckedWorkouts) => [
          ...prevCheckedWorkouts,
          {
            week_index: Number(props?.planWeekdata?.week?.week_id),
            day_index: Number(props?.planWeekdata?.day?.day_id),
            fitness_plan_id: Number(fitnessPlanId?.id),
            workout_id: Number(id),
            // order_id: Number(orderId),
          },
        ]);
      }

      setUncheckedWorkouts((prevUncheckedWorkouts) =>
        prevUncheckedWorkouts.filter((workout) => workout.workout_id !== id)
      );
    } else {
      // setOrderShow(index+Math.random());
      setCheckedWorkouts((prevCheckedWorkouts) =>
        prevCheckedWorkouts.filter((workout) => workout.workout_id !== id)
      );
      setUpdateAnswerOrder((prevTest) =>
      prevTest.filter((el) => el.workout_id !== id)
    );
      if (existingPlanWorkouts.some((workout) => workout.id === id)) {
        setUncheckedWorkouts((prevUncheckedWorkouts) => [
          ...prevUncheckedWorkouts,
          {
            week_index: Number(props?.planWeekdata?.week?.week_id),
            day_index: Number(props?.planWeekdata?.day?.day_id),
            fitness_plan_id: Number(fitnessPlanId?.id),
            workout_id: Number(id),
            // order_id: Number(orderId),
            id: Number(id),
          },
        ]);
      }
    }
  };


  const handleOrderChange = (event, workoutId) => {
    const newOrderValue = Number(event.target.value);
    setCheckedWorkouts((prevCheckedWorkouts) => {
      console.log("newOrderValue",newOrderValue)
  
      setUpdateAnswerOrder((prevTest) => {
        const existingElement = prevTest.find((el) => (
          el?.day_index === Number(props?.planWeekdata?.day?.day_id) &&
          el?.week_index === Number(props?.planWeekdata?.week?.week_id) &&
          el?.fitness_plan_id === Number(fitnessPlanId?.id) &&
          el?.workout_id === workoutId
        ));
      
        if (existingElement) {
          // Update existing element
          return prevTest.map((el) => (
            el === existingElement
              ? {
                  ...el,
                  order_id: newOrderValue,
                }
              : el
          ));
        } else {
          // Add new element
          return [
            ...prevTest,
            {
              workout_id: workoutId,
              week_index: Number(props?.planWeekdata?.week?.week_id),
              day_index: Number(props?.planWeekdata?.day?.day_id),
              fitness_plan_id: Number(fitnessPlanId?.id),
              order_id: newOrderValue,
            },
          ];
        }
      });

      const index = prevCheckedWorkouts?.findIndex((workout) => workout.workout_id === workoutId);
      if (index !== -1) {
        const updatedWorkouts = [...prevCheckedWorkouts];
        updatedWorkouts[index] = {
          ...updatedWorkouts[index],
          order_id: newOrderValue,
        };
       
        
        return updatedWorkouts;
      }

     

      return [
        ...prevCheckedWorkouts,
        {
          workout_id: workoutId,
          week_index: Number(props?.planWeekdata?.week?.week_id),
          day_index: Number(props?.planWeekdata?.day?.day_id),
          fitness_plan_id: Number(fitnessPlanId?.id),
          order_id: newOrderValue,
        },
      ];
 
    });
  };

console.log("setUpdateAnswerOrder",updatedAnswerOrder)

  const submitData = (e) => {
    e.preventDefault();
    //Old Create Method 
    // if (existingPlanWorkouts.length === 0) {
    //   axios.post('/exercise-per-day', { exercises: checkedWorkouts }).then(
    //     response => {
    //       console.log("exercise per day data: ", response.data);
    //       toast.success("Exercise saved successfully");
    //       props?.handleModalClose()
    //     }
    //   ).catch(
    //     err => {
    //       console.log("error:", err);
    //     }
    //   );
    // } else {

      //This One creates or updates workout
      axios.put('/exercise-per-day', { answers: checkedWorkouts, deleteAnswers: uncheckedWorkouts, updatedAnswer: updatedAnswerOrder }).then(
        response => {
          console.log("exercise per day update: ", response.data);
          toast.success("Exercise updated successfully");
          props?.handleModalClose()
        }
      ).catch(
        err => {
          console.log("error:", err);
        }
      );
    // }
  };

  const image = (e) => {
    const ud = Object.assign({}, dataToSave);
    ud.day_plan_image = e.target.files[0];
    setDataToSave(ud);
  };

  const handleChange = (e) => {
    setDataToSave({
      ...dataToSave,
      [e.target.name]: e.target.value,
    })
  }


  const handleEditDay = (e) => {

    e.preventDefault();
    const formData = new FormData();

    Object.keys(dataToSave).forEach((key) => {
      formData.append(key, dataToSave[key])
    })

    axios.put('/fitness-plans-weeks/day', formData)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e ditës janë shtuar me sukses!');
      }
      ).catch(err => {
        toast.error('Te dhënat nuk jane shtuar!');
        console.log(err);
      });
  }
  useEffect(() => {
    const fetchData = async () => {
      if (props?.isOpen) {
        try {
          const existingResponse = await axios.get(`/exercise-per-day/${Number(props?.planWeekdata?.week?.week_id)}/${Number(props?.planWeekdata?.day?.day_id)}/${plan_id?.id}`);
          setExistingPlanWorkouts(existingResponse.data);

          const response = await axios.get(`/plan_workouts/all/${plan_id?.id}`);
          setPlanWorkouts(response.data);

          const allDaysPerWeek = await axios.get(`/exercise-per-day/weeksDay/${Number(props?.planWeekdata?.day?.day_id)}/${plan_id?.id}`);
          setAllWeeksDays(allDaysPerWeek.data);

          setEdit(false);
          setDelete(false);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [props?.isOpen]);

  const getTrainerData = () => {
    axios.get(`exercise-per-day/trainerData/${props?.planWeekdata?.day?.fitness_plan_id}/${props?.planWeekdata?.week?.week_id}/${props?.planWeekdata?.day?.day_id}`)
      .then((res) => {
        setTrainerId(res?.data);
        setDataToSave({
          ...dataToSave,
          "trainerInfo": res?.data?.fitness_plan?.trainer_id,
          "planInfo": res?.data?.fitness_plan_id,
          "weekInfo": res?.data?.week_id,
          "dayInfo": res?.data?.day_id,
        })
      })
      .catch((err) => { console.log('plan_workouts', err) })
  };

  const handleDeleteVideo = (data ,e) => {
    e.preventDefault();
  let dataToSend = {
      fileName: data
  }
  axios.post(`/remove-files`, dataToSend)
    .then(data => {
      toast.success('Të dhënat  janë fshir me sukses!');
      // props?.getData();
      getDayImages();
      // props?.handleModalClose();
    }).catch(err => {
      console.log(err);
      toast.error('Të dhënat nuk janë fshir! / Video apo Foto është fshir apo e ke fshir / Refresh Page');
    })
  
};  

const getDayImages = () => {
  axios.get(`/remove-files/allTrainerDayPhotos/${props?.fitnessInfo?.trainer_id}`)
    .then((res) => {
      setDayImages(res?.data?.dayPhotos)
    })
    .catch((err) => { console.log('plan_workouts', err) })
};

  useEffect(()=>{
    getTrainerData();
    getDayImages();
  },[])

  const handleDays = (e) => {
    e.preventDefault();

    let dataToWeeks = {
      answersDaily: props?.planWeekdata?.day,
      answersWeekAnswer: props?.planWeekdata?.week.week_id,
      workoutDaily: existingPlanWorkouts,
      weeksCount: props.weeksCount,
      alldaysWeek: allWeeksDays
    }
    // console.log("hejj",dataToWeeks)
    axios.put('/fitness-plans-weeks/multipleDay', dataToWeeks)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e ditës janë shtuar me sukses!');
      }
      ).catch(err => {
        toast.error('Te dhënat nuk jane shtuar!');
        console.log(err);
      });
  }

  const photoExists = dayImages?.some(photoObj => photoObj.dayPhoto === props?.planWeekdata?.day?.day_image_url);
  const isChecked = (id) => checkedWorkouts.some((workout) => workout.workout_id === id);
const getOrder = (id) => {
  const checkedWorkout = checkedWorkouts.find((workout) => workout.workout_id === id);
  return checkedWorkout ? checkedWorkout.order_id : null;
};

  const weekIdReplicate = props?.planWeekdata?.week?.week_id;

  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper onClick={(e) => e.stopPropagation()}>
              <ModalActionButtonWrapper>
                {props?.type !== "add" ? (
                  <>
                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                ) : (
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                )}
              </ModalActionButtonWrapper>


              <ModalTitle title className="container">
                Select exercises for Week <strong>{props?.planWeekdata?.week?.week_id}</strong>(<strong>Day {props?.planWeekdata?.day?.day_id + 1}</strong>)
              </ModalTitle>
              <div className="">
                <Form onSubmit={handleEditDay}>

                  <div className="row">
                    <div className="col-md-6">

                      <div className="form-group labelInput mb-2">
                        <label>Day Name:</label>
                        <input
                          type="text"
                          onChange={handleChange}
                          required
                          name="day_name"
                          className="form-control"
                          placeholder="Day Name"
                          defaultValue={props?.planWeekdata?.day?.day_name}

                        />

                      </div>
                    </div>
                    <div className="col-md-6">

                      <div className="form-group labelInput mb-2">
                        <label>Recommended Time:</label>
                        <input
                          type="number"
                          onChange={handleChange}
                          required
                          name="recommended_time"
                          className="form-control"
                          placeholder="Recommended Time"
                          defaultValue={props?.planWeekdata?.day?.recommended_time}

                        />

                      </div>
                    </div>

                  </div>

                  <div className="form-group my-3 d-flex justify-content-between align-items-center">
                    {/* <label>Image:</label> */}
                    <div className="inputFile row align-items-center">
                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12">
                        <input
                          type="file"
                          name="day_plan_image"
                          onChange={image}
                        />
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12">
                        {props?.planWeekdata?.day?.day_image_url != null ?
                        //  photoExists && (
                           <img src={`${process.env.REACT_APP_UPLOADS + props?.planWeekdata?.day?.day_image_url}`} alt="photo Preview"
                           className="img-fluid" style={{ maxWidth: '100%', maxHeight: '100px', borderRadius: '10px' }} />
                          // )
                          :
                          null}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h5 className='text-warning text-start'>Some files are being used by the system. Please be cautious!</h5>
                  <div className="col-md-3">
                  {/* <p>Delete Photo:</p> */}
                          <button className='btn btn-danger w-100' onClick={(e) => handleDeleteVideo(props?.planWeekdata?.day?.day_image_url,e)}>Delete Day Photo</button>
                  </div>
                  <hr />
                  <div className="saveButon">

                    <SaveButtonModal type="submit" >

                      Save
                    </SaveButtonModal>
                  </div>
                </Form>

              </div>

              <div className="mt-4 container">
                <div className="listLayout mx-auto">
                  <h5 >List of Exercises</h5>
                  <hr />
              <form className="w-100" onSubmit={submitData}>
                  <div className="mt-4 row">
                    {existingPlanWorkouts && planWorkouts?.map((el,index) => {
                      return (
                        <>
                        <div className="col-md-6 form-check formCheck" key={el?.id}>
                           
                          <div className="inputCheck">
                            <input
                              className="form-check-input ms-2"
                              type="checkbox"
                              value={existingPlanWorkouts.some((item) => item.workout_id == el.id) ? existingPlanWorkouts.find((item) => item.workout_id == el.id).id : el.id}
                              id={"Excercises" + (existingPlanWorkouts.some((item) => item.workout_id == el.id) ? existingPlanWorkouts.find((item) => item.workout_id == el.id).id : el.id)}
                              onChange={(event) => handleCheckData(event, existingPlanWorkouts.some((item) => item.workout_id == el.id) ? existingPlanWorkouts.find((item) => item.workout_id == el.id).id : el.id,index)}
                              defaultChecked={existingPlanWorkouts.some((item) => item.workout_id == el.id)}
                            />
                          </div>
                        
                          &nbsp;
                          <div>

                            <label className="form-check-label mt-1" htmlFor={"Excercises" + el.id}>
                              <h6 className="fw-normal">{el.name_al}</h6>
                            </label>
                          </div>
                       
                        </div>
                        <div className="col-md-6 row">
                        {(isChecked(el.id) && (
                          <>
                            <div className="col-md-4">
                              <span className="fw-normal">Order</span>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="number"
                                required
                                className="form-control"
                                defaultValue={getOrder(el.id)}
                                onChange={(e) => handleOrderChange(e, el.id)}
                              />
                            </div>
                          </>
                        )) || (!isChecked(el.id) && existingPlanWorkouts.some((item) => item.workout_id == el.id) && (
                          <>
                            <div className="col-md-4">
                              <span className="fw-normal">Order</span>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="number"
                                required
                                className="form-control"
                                defaultValue={existingPlanWorkouts.find((item) => item.workout_id == el.id).order_id}
                                onChange={(e) => handleOrderChange(e, el.id)}
                              />
                            </div>
                          </>
                        ))}
                          </div>
                        </>
                        

                );
                })}
                  </div>

                  {props?.type === "add" && (
                    <div className="saveButon">
                      <SaveButtonModal
                        type="submit"
                        // onClick={(e) => submitData(e)}
                      >
                        Save
                      </SaveButtonModal>
                    </div>
                  )}

                  {/* {props?.type === "edit" && (
                    <SaveButtonModal
                      type="submit"
                      disabled={isEdit || props?.type === "add" ? false : true}
                    >
                      Ndrysho
                    </SaveButtonModal>
                  )} */}
                </form>
                </div>

                <hr />
                {weekIdReplicate && [1, 2, 3, 4].includes(weekIdReplicate)
                  ? <div class="alert alert-danger d-flex align-items-center justify-content-evenly mt-4 replicatefunction" role="alert">
                    {/* <svg class="" role="img" aria-label="Danger:"></svg> */}
                    <div>
                      <i className="fa fa-info pr-2"></i> &nbsp;
                      Through this function you will override the program's for the  same day for all the weeks.
                    </div>
                    <div className="replicateButton">
                      <ReplicateButtonModal
                        type="submit"
                        onClick={handleDays}
                      >
                        Replicate
                        <i class="fa-regular fa-clone"></i>
                      </ReplicateButtonModal>
                    </div>
                  </div>
                  :
                  ""
                }
              </div>
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default FitnessPlansWeeksModal;
