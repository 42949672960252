import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import { toast } from "react-toastify";
import './fileManager.scss'
import ModalPortal from '../../portal/ModalPortal';
import { ModalStyle } from '../../styles/MainLayout';
const FileManagerModal = (props) => {
    const [videos, setVideos] = useState(null);
    const [images, setImages] = useState(null);
    const [dayImages, setDayImages] = useState(null);
    const [allDayImages, setAllDayImages] = useState(null);
   

    const getVideoImage = () => {
        axios.get(`/remove-files/allVideos/${props?.trainerId}`)
          .then((res) => {
            setVideos(res?.data?.videos)
          })
          .catch((err) => { console.log('plan_workouts', err) })
      };

    const getImages = () => {
        axios.get(`/remove-files/allPhotos/${props?.trainerId}`)
          .then((res) => {
            setImages(res?.data?.photos)
          })
          .catch((err) => { console.log('plan_workouts', err) })
      };

    const getDayImages = () => {
        axios.get(`/remove-files/allTrainerDayPhotos/${props?.trainerId}`)
          .then((res) => {
            setDayImages(res?.data?.dayPhotos)
          })
          .catch((err) => { console.log('plan_workouts', err) })
      };

    const getAllDayImages = () => {
        axios.get(`/remove-files/allDayPhotos/`)
          .then((res) => {
            setAllDayImages(res?.data?.dayPhotos)
          })
          .catch((err) => { console.log('plan_workouts', err) })
      };

      useEffect(() => {
        if(props?.type==="video"){
            getVideoImage();
        }
        if(props?.type==="dayPhoto"){
            getDayImages();
        }
        if(props?.type === "photo"){
            getImages();
        }else{
            getAllDayImages();
        }
      }, []);


      const handleDeleteVideo = (data ,e) => {
          e.preventDefault();
        let dataToSend = {
            fileName: data
        }
        axios.post(`/remove-files`, dataToSend)
          .then(data => {
            toast.success('Të dhënat  janë përditësuar me sukses!');
            // props?.handleModalClose();
            getVideoImage();
            getImages();
            getDayImages();
            getAllDayImages();
          }).catch(err => {
            console.log(err);
            toast.error('Të dhënat nuk janë përditësuar!');
          })
        
      };   

    if (props?.isOpen) {
        return (
            <>
            <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
                <div className="wholePage" onClick={() => {props?.handleModalClose(); }} />
                <div className="active-item p-3">
                    <div>
                        <h4 className='text-warning text-center'>Some files are being used by the system. Please be cautious!</h4>
                    {props?.type === "video"
                    ?
                    <>
                        <h1 className='text-center my-4'>List of Workout Videos</h1>
                        {videos && videos.length > 0 ? (
                            videos.map((el, index) => (
                                <div className='row' key={index}>
                                <div className="col-md-10">
                                    <h4 className="text-start btn btn-outline-success w-100">
                                    <span title={el?.video}>{el?.video?.split('/')[3]?.split('___')[0]}</span>
                                    </h4>
                                </div>
                                <div className="col-md-2">
                                    <button className='btn btn-danger w-100' onClick={(e) => handleDeleteVideo(el?.video,e)}>Delete</button>
                                </div>
                                </div>
                            ))
                            ) : (
                            <p>No videos available</p>
                            )}
                    </>
                    : (
                        props?.type === "photo" ? 
                    <>
                        <h1 className='text-center my-4'>List of Workout Photos</h1>
                        {images && images.length > 0 ? (
                            images.map((el, index) => (
                                <div className='row' key={index}>
                                <div className="col-md-10">
                                    <h4 className="text-start btn btn-outline-success w-100">
                                    <span title={el?.photo}>{el?.photo?.split('/')[3]?.split('___')[0]}</span>
                                    </h4>
                                </div>
                                <div className="col-md-2">
                                    <button className='btn btn-danger w-100' onClick={(e) => handleDeleteVideo(el?.photo,e)}>Delete</button>
                                </div>
                                </div>
                            ))
                            ) : (
                            <p>No photos available</p>
                            )}
                    </>
                : props?.type === "dayPhoto" ? 
                    <>
                    <h1 className='text-center my-4'>List of Day Photo</h1>
                    {dayImages && dayImages.length > 0 ? (
                        dayImages.map((el, index) => (
                            <div className='row' key={index}>
                            <div className="col-md-10">
                                <h4 className="text-start btn btn-outline-success w-100">
                                <span title={el?.dayPhoto}>{el?.dayPhoto?.split('/')[4]?.split('___')[0]}</span>
                                </h4>
                            </div>
                            <div className="col-md-2">
                                <button className='btn btn-danger w-100' onClick={(e) => handleDeleteVideo(el?.dayPhoto,e)}>Delete</button>
                            </div>
                            </div>
                        ))
                        ) : (
                        <p>No photos available</p>
                        )}
                     </>
                    
                    : 
                    <>
                    <h1 className='text-center my-4'>List of All Photos</h1>
                    {allDayImages && allDayImages.length > 0 ? (
                        allDayImages.map((el, index) => (
                            <div className='row' key={index}>
                            <div className="col-md-10">
                                <h4 className="text-start btn btn-outline-success w-100">
                                <span title={el?.dayPhoto}>{el?.dayPhoto?.split('/')[3]?.split('___')[0]}</span>
                                </h4>
                            </div>
                            <div className="col-md-2">
                                <button className='btn btn-danger w-100' onClick={(e) => handleDeleteVideo(el?.dayPhoto,e)}>Delete</button>
                            </div>
                            </div>
                        ))
                        ) : (
                        <p>No photos available</p>
                        )}
                     </>
                    )
                    }
                    </div>
           

                    </div>
                    </ModalStyle>
        </ModalPortal>
            </>
        )
    }
}

export default FileManagerModal;