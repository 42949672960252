import React from 'react'
import { SearchBar, Refresh, ButtonRefresh, IconRefresh, Input } from "../../styles/MainLayout";

const Search = (props) => {
  let { data, filtered, setPageNumber, onData, from, onSearch } = props;
  console.log("texi", props)
  const searcher = async (text) => {
    if (!!data && !!filtered && !!setPageNumber) {
      let searchText = await text?.target.value.toLowerCase();
      let filteredUser = data?.filter((dataCallBack) => {
        console.log("texi", searchText, dataCallBack?.name_al)
        // if (dataCallBack?.category?.name_al) {
        //   return dataCallBack?.category?.name_al.toLowerCase().includes(searchText);
        // } 
        if (dataCallBack?.name_al || dataCallBack?.name_en || dataCallBack?.range || dataCallBack?.name  || dataCallBack?.first_name || dataCallBack?.title_al || dataCallBack?.title_en|| dataCallBack?.name_surname || dataCallBack?.keyname) {
          return dataCallBack?.name_al?.toLowerCase().includes(searchText)
            || dataCallBack?.name_en?.toLowerCase().includes(searchText)
            || dataCallBack?.range?.toLowerCase().includes(searchText)
            || dataCallBack?.name?.toLowerCase().includes(searchText)
            || dataCallBack?.first_name?.toLowerCase().includes(searchText)
            || dataCallBack?.title_al?.toLowerCase().includes(searchText)
            || dataCallBack?.title_en?.toLowerCase().includes(searchText)
            || dataCallBack?.name_surname?.toLowerCase().includes(searchText)
            || dataCallBack?.keyname?.toLowerCase().includes(searchText)

        }
      });
      filtered(filteredUser);
      setPageNumber(0);
  }
  };
  return (
    <SearchBar>
      <Refresh className="refresh">
        <ButtonRefresh>
          <IconRefresh />
        </ButtonRefresh>
      </Refresh>
      <Input type="search" onChange={searcher} id="site-search" name="q" placeholder='Kerko' />
    </SearchBar>
  )
}

export default Search
