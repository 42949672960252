import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const EComPaymentTypesModal = (props) => {
  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [oneTime, setOneTime] = useState(false);

  // const [payment, setPayment] = useState();


const [addPayment, setAddPayment] = useState({
  id: props?.dataToModal?.id,
  // name_en: '',
  // name_al: ''

})

const handleChange = (e) => {
  setAddPayment({
    ...addPayment,
    [e.target.name]: e.target.value,
  })

}

console.log("addPayment",props)
const addPaymentTypes = (e) => {
  e.preventDefault();
  setOneTime(true);
  axios.post('/eCom-payment-type', addPayment)
    .then(data => {
      props?.handleModalClose();
      props?.getData();
      toast.success('Payment types added successfully!');
      setOneTime(false);
    }
    ).catch(err => {
      toast.error('Something went wrong!');
      console.log(err);
    });
}

const editPaymentTypes = (e) => {
  e.preventDefault();
  setEdit(false);
  axios.put(`/eCom-payment-type`, addPayment)
    .then(data => {
      toast.success('Payment types edited successfully !');
      props?.getData();
      props?.handleModalClose();
    })
    .catch(err => {
      console.log(err);
      toast.error('Something went wrong!');


    });
}


const deletePaymentTypes = () => {
  axios.delete(`/eCom-payment-type/${props?.dataToModal?.id}`).then(data => {
    toast.success('Payment types deleted successfully!');
    props?.handleModalClose();
    props?.getData();
  }).catch(err => {
    console.log('userProfileGetErr', err);
    toast.error('Something went wrong!');
  })
}



  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })

  useEffect(() => {
    if (props?.isOpen) {
      setEdit(false);
      setDelete(false);
    }
  }, [props?.isOpen]);


  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
          <div className="wholePage" onClick={() => props?.handleModalClose()}/>
            <Mymodal_wrapper>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                      <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons> */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>


              {isdelete === false && (
                <Form >
                  <ModalTitle title>Modal title</ModalTitle>
                  <div className="form-group">
                    <label>Menyra e pageses:</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.name_al : ""}
                      className="form-control"
                      name="name_al"
                      placeholder="Menyra e pageses"
                    />
                  </div>
                  <div className="form-group">
                    <label>Payment Types:</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.name_en : ""}
                      className="form-control"
                      name="name_en"
                      placeholder="Payment Types"
                    />
                  </div>
                
              

                  {props?.type === "add" && (
                    <SaveButtonModal type="submit" onClick={addPaymentTypes} disabled={oneTime === true ? true : false}>
                      Ruaj
                    </SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal onClick={editPaymentTypes} type="submit" disabled={!isEdit || props?.type === "add" ? false : true}>
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>


              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={deletePaymentTypes}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default EComPaymentTypesModal;
