import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import { 
  MenuButton, 
  PageStyle, 
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  Pagination,
  EyeIcon } from "../../styles/MainLayout";
import Search from "../search/search";
import TrainingLessonsModal from "./trainingLessonModal";
import { Link } from "react-router-dom";
import axios from "../../axios";
import ReactPaginate from "react-paginate";

const TrainingLessons = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [dataToModal, setDataToModal] = useState();
  const [trainingLessons, setTrainingLessons] = useState([]);
  const [trainingLessonCat, setTrainingLessonCat] = useState([]);
  const [trainingLessonID, setTrainingLessonID] = useState(null);

  const [filtered, setFiltered] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 8;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }

  const getTrainingLessons = () => {
    axios.get('/training-lessons/all').then(data => {
      setTrainingLessons(data.data);
      setFiltered(data?.data)
    }).catch(err => {
      console.log('TrainingLessonsErr', err);
    });
  };

  console.log("trainingLessons", trainingLessons);

  const getTrainingLessonCat = () => {
    axios.get('training-lessons-cat/all').then(data => {
      console.log('catdatadata diqka', data);
      setTrainingLessonCat(data.data);
    }).catch(err => {
      console.log('TrainingLessonsCatErr', err);
    });
  };

  console.log("TrainingLessonsCat", trainingLessonCat);

  useEffect(() => {
    getTrainingLessons();
    getTrainingLessonCat();
  }, []);

  const handleModalAdd = () => {
    setModalType('add');
    setIsOpen(true);
  };

  const handleModalEdit = (data) => {
    setDataToModal(data);
    setTrainingLessonID(data);
    setModalType('edit');
    setIsOpen(true);
  };

  const handleMenuClick = (e) => {
    e.preventDefault();
    props.toggleMenu();
  };    

  return (
    <>
      {isOpen === true ?
        <TrainingLessonsModal dataToModal={dataToModal} training={trainingLessonID} isOpen={isOpen} type={modalType} getData={() => getTrainingLessons()} handleModalClose={() => setIsOpen(false)} />
        : ""
      }
      <PageStyle isMenuOpen={props?.menu}>
        <PageTitleStyle>Training Lessons</PageTitleStyle>
        <Search data={trainingLessons} filtered={setFiltered} setPageNumber={setPageNumber}/>
        <MenuButton onClick={handleMenuClick}>
          {props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}
        </MenuButton>
        <PageContainer>
          <TableResponsive isMenuOpen={props?.menu}>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableHeadStyle tableFirstElement>ID</TableHeadStyle>
                  <TableHeadStyle>Titulli</TableHeadStyle>
                  <TableHeadStyle>Title</TableHeadStyle>
                  <TableHeadStyle>Kategoria Trajnimit</TableHeadStyle>
                  <TableHeadStyle>Training Category</TableHeadStyle>
                  <TableHeadStyle>Actions</TableHeadStyle>
                </TableRowHead>
              </TableHead>
              <TableBody>
              {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>
              }
                {filtered && filtered?.length > 0 && <>
                  {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                    return (
                      <>
                      <TableRowBody key={el?.id}>
                      <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                      <TableBodyStyle>{el?.title_al}</TableBodyStyle>
                      <TableBodyStyle>{el?.title_en}</TableBodyStyle>
                      <TableBodyStyle>{trainingLessonCat?.find(elem => elem?.id == el?.training_lessons_cat)?.name_al || 'Kategoria nuk u gjet'} </TableBodyStyle>
                      <TableBodyStyle>{trainingLessonCat?.find(elem => elem?.id == el?.training_lessons_cat)?.name_en || 'Category not found'} </TableBodyStyle>
                      <TableBodyStyle>
                        <EyeIcon onClick={() => handleModalEdit(el)} />
                      </TableBodyStyle>
                    </TableRowBody>
                             </>
                             )
                           })}</>
                         }
              </TableBody>
            </Table>
            <Pagination>
              <div className="text-right">
                {((trainingLessons?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
          </TableResponsive>
        </PageContainer>
      </PageStyle>
      <MenuButton add onClick={handleModalAdd}>
        <i className="fa fa-plus" />
      </MenuButton>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingLessons);
