import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import { 
  MenuButton, 
  PageStyle, 
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  Pagination,
  EyeIcon } from "../../styles/MainLayout";
import Search from "../search/search";
import axios from "../../axios";
import ReactPaginate from "react-paginate";
import LanguagesModal from "./languagesModal";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ActiveLanguageModal from "./activeLanguageModal";
import './activeLanguage.scss'
import TranslationComponent from "../Translations/TranslationComponent ";

const Languages = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenActive, setIsOpenActive] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalTypeActive, setModalTypeActive] = useState();
  const [dataToModal, setDataToModal] = useState();
  const [dataToActiveModal, setDataToActiveModal] = useState();
  const [languages, setLanguages] = useState([]);
  const [languageID, setLanguageID] = useState(null);

  const [filtered, setFiltered] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 8;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }

  const getLanguages = () => {
    axios.get('/languages/allLanguages').then(data => {
      setLanguages(data.data);
      setFiltered(data?.data)
    }).catch(err => {
      console.log('err', err);
    });
  };
  useEffect(() => {
    getLanguages();
  }, []);

  const handleModalAdd = () => {
    setModalType('add');
    setIsOpen(true);
  };

  const handleModalEdit = (data) => {
    setDataToModal(data);
    setLanguageID(data?.data);
    setModalType('edit');
    setIsOpen(true);
  };

  const handleActiveLanguage = (data,type) => {
    setDataToActiveModal(data);
    setIsOpenActive(true);
    setModalTypeActive(type);
  };

  const handleMenuClick = (e) => {
    e.preventDefault();
    props.toggleMenu();
  };    




  return (
    <>
      {isOpen === true ?
        <LanguagesModal dataToModal={dataToModal} languageID={languageID} isOpen={isOpen} type={modalType} getData={() => getLanguages()} handleModalClose={() => setIsOpen(false)} />
        : ""
      }
      {isOpenActive === true ?
        <ActiveLanguageModal dataToModal={dataToActiveModal} type={modalTypeActive} isOpen={isOpenActive}  getData={() => getLanguages()} handleModalClose={() => setIsOpenActive(false)} />
        : ""
      }
      <PageStyle isMenuOpen={props?.menu}>
        <PageTitleStyle>Languages</PageTitleStyle>
        <Search data={languages} filtered={setFiltered} setPageNumber={setPageNumber}/>
        <MenuButton onClick={handleMenuClick}>
          {props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}
        </MenuButton>
        <PageContainer>
          <TableResponsive isMenuOpen={props?.menu}>
           <Table>
              <TableHead>
                <TableRowHead>
                  <TableHeadStyle tableFirstElement>ID</TableHeadStyle>
                  <TableHeadStyle>
                     {/* <TranslationComponent keyName="home"/>  */}
                     Emri
                     </TableHeadStyle>
                  <TableHeadStyle>
                    Kodi
                    {/* <TranslationComponent keyName="code"/> */}
                    </TableHeadStyle>
                  <TableHeadStyle>Active</TableHeadStyle>
                  {/* <TableHeadStyle>Default</TableHeadStyle> */}
                  <TableHeadStyle className="text-center">Edit</TableHeadStyle>
                  <TableHeadStyle className="text-center">Active/Deactive</TableHeadStyle>
                </TableRowHead>
              </TableHead>
            <TableBody>
              {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>
              }
                {filtered && filtered?.length > 0 && <>
                  {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                    return (
                      <>
                      <TableRowBody key={el?.id}>
                      <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                      <TableBodyStyle >{el?.name}</TableBodyStyle>
                      <TableBodyStyle>{el?.code}</TableBodyStyle>
                      <TableBodyStyle>{el?.is_active ? <i className="fa fa-check text-success"/>: <i className="fa fa-times text-danger"/>}</TableBodyStyle>
                      {/* <TableBodyStyle>{el?.is_default ? <i className="fa fa-check text-success"/>: <i className="fa fa-times text-danger"/>}</TableBodyStyle> */}
                      <TableBodyStyle className="text-center">
                        <EyeIcon onClick={() => handleModalEdit(el)} />
                       
                      </TableBodyStyle>
                      <TableBodyStyle className="text-center">
                      {!el?.is_active 
                        ?
                        <i className="fa fa-toggle-off activeIcon" onClick={(e) => handleActiveLanguage(el,"add")} />
                       : 
                       <i className="fa fa-toggle-on activeIcon" onClick={(e) => handleActiveLanguage(el,"edit")} />
                       }
                      </TableBodyStyle>
                      </TableRowBody>
                      </> )})}</>}
              </TableBody>
            </Table>
            <Pagination>
              <div className="text-right">
                {((languages?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
          </TableResponsive>
        </PageContainer>
      </PageStyle>
      <MenuButton add onClick={handleModalAdd}> <i className="fa fa-plus" /> </MenuButton>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Languages);