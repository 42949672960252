import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios"
import { toast } from "react-toastify";

const NewsCategoriesModal = (props) => {
  const [isdelete, setDelete] = useState(false);

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })

  //AXIOS
  const addNewsCategory = (e) => {
    e.preventDefault()
    let body = {
      name_al: e?.target?.name_al?.value,
      name_en: e?.target?.name_en?.value,
    }
    axios.post("/news-category", body).then(
      response => {
        toast.success("News category added successfully")
        props?.handleModalClose()
        props?.getNewsCategory()
        console.log("add Response :", response)
      }
    ).catch(err => {
      console.log("error :", err)
      toast.error("Something went wrong")
    })
  }

  const editNewsCategory = (e) => {
    e.preventDefault()
    let body = {
      id: props?.data?.id,
      name_al: e?.target?.name_al?.value,
      name_en: e?.target?.name_en?.value,
    }
    axios.put("/news-category/", body).then(
      response => {
        console.log("Edit response: ", response)
        toast.success("News category edited successfully")
        props?.handleModalClose()
        props?.getNewsCategory()
      }
    ).catch(err => {
      console.log("edit error: ", err)
      toast.error("Something went wrong")

    })
  }

  const deleteNewsCategory = (id) => {
    axios.delete(`/news-category/${id}`).then(
      response => {
        toast.success("News category deleted successfully")
        props?.getNewsCategory()
        props?.handleModalClose()
      }
    ).catch(err =>{
       console.log("err",err)
       toast.error("Something went wrong")
    })
  }

  useEffect(() => {
    if (props?.isOpen) {
      setDelete(false);
    }
  }, [props?.isOpen]);

  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle onClick={() => props?.handleModalClose()}>
            <Mymodal_wrapper onClick={(e) => e.stopPropagation()}>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>
              {isdelete === false && (
                <Form onSubmit={props?.type === "add" ? addNewsCategory : props?.type === "edit" ? editNewsCategory : ""} >
                  <ModalTitle title>Add News Category</ModalTitle>
                  <div className="form-group">
                    <label>Emri i kategorise se lajmeve:<span className="text-danger">*</span></label>
                    <input
                      required
                      id="name"
                      name="name_al"
                      type="text"
                      className="form-control"
                      placeholder="e.x Kategoria e Lajmeve"
                      defaultValue={props?.type === "add" ? "" : props?.data?.name_al}
                    />
                    <label>News category name:</label>
                    <input
                      id="name"
                      name="name_en"
                      type="text"
                      className="form-control"
                      placeholder="e.x News Category"
                      defaultValue={props?.type === "add" ? "":props?.data?.name_en}
                    />
                  </div>
                  {props?.type === "add" && (
                    <SaveButtonModal type="submit">
                      Ruaj
                    </SaveButtonModal>
                  )}
                  {props?.type === "edit" && (
                    <SaveButtonModal type="submit" >
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>
              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={() => deleteNewsCategory(props?.data?.id)}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default NewsCategoriesModal;
