import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const EComProductCategoriesModal = (props) => {
  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [oneTime, setOneTime] = useState(false);

  // const [category, setCategory] = useState();

  const [addCategory, setAddCategory] = useState({
    id: props?.dataToModal?.id,
    // name_en:'',
    // name_al: ''
  
  })
  
  const handleChange = (e) => {
    setAddCategory({
      ...addCategory,
      [e.target.name]: e.target.value,
    })
  
  }

  
  const addProductCategory = (e) => {
    e.preventDefault();
  setOneTime(true);

    axios.post('/eCom-product-cat', addCategory)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Product Category added successfully!');
      setOneTime(false);
      }
      ).catch(err => {
        toast.error('Something went wrong!');
        console.log(err);
      });
  }
  
  const editProductCategory = (e) => {
    e.preventDefault();
    setEdit(false);
    axios.put(`/eCom-product-cat`, addCategory)
      .then(data => {
        toast.success('Product Category edited successfully!');
        props?.getData();
        props?.handleModalClose();
      })
      .catch(err => {
        console.log(err);
        toast.error('Something went wrong!');
  
  
      });
  }
  
  
  const deleteProductCategory = () => {
    axios.delete(`/eCom-product-cat/${props?.dataToModal?.id}`).then(data => {
      toast.success('Product Category deleted successfully!');
      props?.handleModalClose();
      props?.getData();
    }).catch(err => {
      console.log('userProfileGetErr', err);
      toast.error('Something went wrong!');
    })
  }
   


  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })

  useEffect(() => {
    if (props?.isOpen) {
      setEdit(false);
      setDelete(false);
    }
  }, [props?.isOpen]);


  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
          <div className="wholePage" onClick={() => props?.handleModalClose()}/>
            <Mymodal_wrapper>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                      <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons> */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>


              {isdelete === false && (
                <Form >
                  <ModalTitle title>Modal Title</ModalTitle>
                  <div className="form-group">
                    <label>Kategoria e Produktit:</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.name_al : ""}
                      name="name_al"
                      className="form-control"
                      placeholder="Kategoria e Produktit"
                    />
                  </div>
                  <div className="form-group">
                    <label>Product Category:</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.name_en : ""}
                      name="name_en"
                      className="form-control"
                      placeholder="Product Category"
                    />
                  </div>
                
                


                  {props?.type === "add" && (
                    <SaveButtonModal type="submit" onClick={addProductCategory} disabled={oneTime === true ? true : false}>
                      Ruaj
                    </SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal type="submit" onClick={editProductCategory} disabled={!isEdit || props?.type === "add" ? false : true}>
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>


              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={deleteProductCategory}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default EComProductCategoriesModal;
