import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios"
import { toast } from "react-toastify";


const EquipmentsModal = (props) => {
  // const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })




  //AXIOS
  const addEquipment = (e) => {
    e.preventDefault()
    let body = {
      name_al: e?.target?.name_al?.value,
      name_en: e?.target?.name_en?.value
    }
    axios.post('/equipments/', body).then(
      response => {
        console.log("Add res: ", response)
        toast.success("Equipment added sucessfully")
        props?.handleModalClose()
        props?.getEquipments()
      }
    ).catch(
      err => {
        console.log("err: ", err)
        toast.error("Something went wrong!")
      }
    )
  }


  const editEquipment = (e) => {
    e.preventDefault()
    let body = {
      id: props?.data?.id,
      name_al: e?.target?.name_al?.value,
      name_en: e?.target?.name_en?.value
    }
    axios.put('/equipments/',body).then(
      response => {
        console.log("edit res: " , response)
        props?.handleModalClose()
        props?.getEquipments()
        toast.success("Equipment edited sucessfully")
      }
    ).catch(
      err => {
        console.log("err : " , err)
        toast.error("Something went wrong")
      }
    )
  }


  const deleteEquipment = (id) => {
    axios.delete(`/equipments/${id}`).then(
      response => {
        console.log("delete res: " , response)
        props?.handleModalClose()
        props?.getEquipments()
        toast.success("Equipment deleted sucessfully")
      }
    ).catch(err =>{
      console.log("err : " , err)
        toast.error("Something went wrong")
    })
  }

  useEffect(() => {
    if (props?.isOpen) {
      // setEdit(false);
      setDelete(false);
    }
  }, [props?.isOpen]);

  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle onClick={() => props?.handleModalClose()}>
            <Mymodal_wrapper onClick={(e) => e.stopPropagation()}>
              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                      <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons> */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>


              {isdelete === false && (
                <Form onSubmit={props?.type === "add" ? addEquipment : props?.type === "edit" ? editEquipment : ""}>
                  <ModalTitle title>Equipments</ModalTitle>
                  <div className="form-group">
                    <label>Emri i paisjes:<span className="text-danger">*</span></label>
                    <input
                      required
                      name="name"
                      id="name_al"
                      type="text"
                      className="form-control"
                      placeholder="e.x Dumbells & Barbells"
                      defaultValue={props?.type === "add" ?"":props?.data?.name_al}
                    />
                    <label>Equipment name:</label>
                    <input
                      name="name"
                      id="name_en"
                      type="text"
                      className="form-control"
                      placeholder="e.x Dumbells & Barbells"
                      defaultValue={props?.type === "add" ?"":props?.data?.name_en}
                    />
                  </div>

                  {props?.type === "add" && (
                    <SaveButtonModal type="submit">
                      Ruaj
                    </SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal type="submit">
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>


              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={() => deleteEquipment(props?.data?.id)}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default EquipmentsModal;
