import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import { 
  MenuButton, 
  PageStyle, 
  PageTitleStyle,
  TableResponsive,
  PageContainer,
  BackButton,
 } from "../../styles/MainLayout";
import Search from "../search/search";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from "../../axios";
import FileManagerModal from "./fileManagerModal";


const FileTrainerManage = (props) => {

    let params = useParams()
    console.log('params', params)
    const navigate = useNavigate();
    const [personalTrainers, setPersonalTrainers] = useState();
    const [videos, setVideos] = useState(null);
    const [images, setImages] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();

    const getVideoImage = () => {
        axios.get(`/plan_workouts/allVideos/${params?.id}`)
          .then((res) => {
            setVideos(res?.data?.videos)
            setImages(res?.data?.images)
    
          })
          .catch((err) => { console.log('plan_workouts', err) })
      };

      useEffect(() => {
        getVideoImage();
      }, []);



return (
      <>
      {isOpen === true ?
     <FileManagerModal
              type={modalType}
              trainerId={params?.id}
              isOpen={isOpen}
              handleModalClose={() => { setIsOpen(false) }} />
              : ""
        }
        
        <PageStyle isMenuOpen={props?.menu}>
       
          <PageTitleStyle>File Manager</PageTitleStyle>
          <Search />
          <MenuButton>{props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>

          <PageContainer>
        
          <TableResponsive isMenuOpen={props?.menu}>
          <BackButton onClick={() => navigate(-1)}>
              <i className="fa fa-chevron-left backIcon "></i><h6>Back</h6>
            </BackButton>
                <h1 className="text-center">Manage files of trainer {params?.id}</h1>
          <div class="row m-4">
      
            <div class="col-xl-4 col-lg-6 mb-3">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">Trainer</h5>
                        <span class="h3 font-weight-bold mb-0">Workout Videos</span>
                      </div>
                      <div class="col-auto m-auto">

                        <div class="icon icon-shape bg-dark text-white rounded-circle shadow" onClick={(e) => {setIsOpen(true);setModalType("video")}}>
                          <i class="fas fa-users"></i>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            <div class="col-xl-4 col-lg-6 mb-3">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">Trainer</h5>
                        <span class="h3 font-weight-bold mb-0">Workout Photos</span>
                      </div>
                      <div class="col-auto m-auto">
                      <div class="icon icon-shape bg-dark text-white rounded-circle shadow" onClick={(e) => {setIsOpen(true);setModalType("photo")}}>
                          <i class="fas fa-users"></i>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            <div class="col-xl-4 col-lg-6 mb-3">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">Trainer</h5>
                        <span class="h3 font-weight-bold mb-0">Day Photos</span>
                      </div>
                      <div class="col-auto m-auto">
                      <div class="icon icon-shape bg-dark text-white rounded-circle shadow" onClick={(e) => {setIsOpen(true);setModalType("dayPhoto")}}>
                          <i class="fas fa-users"></i>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
        <hr />
            <div class="col-xl-4 col-lg-6 mb-3">
                <div class="card card-stats mb-4 mb-xl-0 bg-danger text-white">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase mb-0 text-white">All Trainer Limited Use</h5>
                        <span class="h3 font-weight-bold mb-0">All Day Photos</span>
                      </div>
                      <div class="col-auto m-auto">
                      <div class="icon icon-shape bg-dark text-white rounded-circle shadow" onClick={(e) => {setIsOpen(true);setModalType("AlldayPhoto")}}>
                          <i class="fas fa-users"></i>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
      
        
              </div>
         
          </TableResponsive>
          </PageContainer>

        </PageStyle>

        {/* <MenuButton add onClick={() => {handleModalAdd();}}>
          <i className="fa fa-plus" />
        </MenuButton> */}
      </>
)
}

const mapStateToProps = (state) => {
    return {
      lang: state.data.lang,
      langData: state.data.langData,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu()),
      // loadingOff: () => dispatch(loadingOff()),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(FileTrainerManage);