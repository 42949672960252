import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from '../../axios'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const OrdersStatusModal = (props) => {
  console.log("propsCon", props?.country?.id)
  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [statuses, setStatuses] = useState();
  const [addStatuses, setaddStatuses] = useState({
    id: props?.status?.id,
  })
  console.log("addStatus", addStatuses)

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })
  const getStatusData = () => {
    axios.get('/orders-status/all').then(data => {
      setStatuses(data.data);
    }).catch(err => {
      console.log('userProfileGetErr', err);
    })
  }
  const HandleAddStatuses = (e) => {
    e.preventDefault();
    axios.post('/orders-status', addStatuses)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e statusit janë shtuar me sukses!');
      }
      ).catch(err => {
        toast.error('Te dhenat nuk jane shtuar!');
        console.log(err);
      });
  }
  const handelEditSubmmision = (e) => {
    e.preventDefault();
    setEdit(false);
    axios.put(`/orders-status`, addStatuses)
      .then(data => {
        toast.success('Të dhënat e statusit janë përditësuar me sukses!');
        props?.getData();
        props?.handleModalClose();

      })
      .catch(err => {
        console.log(err);
        toast.error('Të dhënat e statusit nuk janë përditësuar!');


      });
  }
  const deleteStatuses = () => {
    axios.delete(`/orders-status/${props?.status?.id}`).then(data => {
      toast.success('Të dhënat  janë fshir me sukses!');

      props?.handleModalClose();
      props?.getData();


    }).catch(err => {
      console.log('orderStatusErr', err);
      toast.error('Të dhënat nuk jan fshir me sukses!');

    })
  }

  const handleChange = (e) => {
    setaddStatuses({
      ...addStatuses,
      [e.target.name]: e.target.value,
    })

  }
 
  useEffect(() => {
    getStatusData();
  }, []);
  useEffect(() => {
    if (props?.isOpen) {
      setEdit(false);
      setDelete(false);
    }
  }, [props?.isOpen]);


  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                        <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons>  */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>


              {isdelete === false && (
                <Form >
                  <ModalTitle title>Modal Title</ModalTitle>
                  <div className="form-group">
                    <label>Emri Statusit:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      defaultValue={props?.type === "edit" ? props?.status?.name_al : ''}

                      name="name_al"
                      onChange={handleChange}
                      disabled={!isEdit || props?.type === "add" ? false : true}
                    />
                  </div>
                  <div className="form-group">
                    <label>Name of Status:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name_en"
                      defaultValue={props?.type === "edit" ? props?.status?.name_en : ''}

                      name="name_en"
                      onChange={handleChange}
                      disabled={!isEdit || props?.type === "add" ? false : true}
                    />
                  </div>
              
                  {props?.type === "add" && (
                    <SaveButtonModal onClick={HandleAddStatuses} type="submit">
                      Ruaj
                    </SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal onClick={handelEditSubmmision} type="submit" disabled={!isEdit || props?.type === "add" ? false : true}>
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>


              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={deleteStatuses}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default OrdersStatusModal;
