import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import { toast } from "react-toastify";
import './activeLanguage.scss'
import ModalPortal from '../../portal/ModalPortal';
import { ModalStyle } from '../../styles/MainLayout';
const ActiveLanguageModal = (props) => {
    console.log("propss",props)

    const handleActiveLanguage = (e) => {
        e.preventDefault();
        let dataSend = {
            languageId: props?.dataToModal?.id,
            code:props?.dataToModal?.code
        }
        axios.put(`/languages/activeLanguage`, dataSend)
          .then(data => {
            toast.success('Të dhënat  janë përditësuar me sukses!');
            props?.getData();
            props?.handleModalClose();
          }).catch(err => {
            console.log(err);
            toast.error('Të dhënat nuk janë përditësuar!');
          })
        
      };    

    const handleDisableLanguage = (e) => {
        e.preventDefault();
        let dataSend = {
            languageId: props?.dataToModal?.id,
        }
        axios.put(`/languages/disableLanguage`, dataSend)
          .then(data => {
            toast.success('Të dhënat  janë përditësuar me sukses!');
            props?.getData();
            props?.handleModalClose();
          }).catch(err => {
            console.log(err);
            toast.error('Të dhënat nuk janë përditësuar!');
          })
        
      };    


    if (props?.isOpen) {
        return (
            <>
            <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
                <div className="wholePage" onClick={() => {props?.handleModalClose(); }} />
                <div className="active-item p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4>{props?.type === "add" ? "Active" : "Deactive"} language {props?.dataToModal?.name}</h4>
                    <i className='fa fa-times fa-2x pointer-icon' onClick={() => {props?.handleModalClose(); }}/>
                  </div>
                  <div className="text-center">

                  <i class="fas fa-exclamation-triangle text-danger fa-3x"></i>
                  </div>
                  <h4 className='text-center my-4'>Are you sure u want to {props?.type === "add" ? "active" : "deactive"} this language</h4>
                    <div className="row justify-content-between mt-5">
                        <div className="col-md-6">

                        <button className='btn btn-danger w-100' onClick={() => {props?.handleModalClose(); }}>Cancel</button>
                        </div>
                        <div className="col-md-6">
                        <button className='btn btn-success w-100' 
                        onClick={(e)=> props?.type === "add" ? handleActiveLanguage(e) : props?.type === "edit" ? handleDisableLanguage(e) : ""}>Accept</button>
                        </div>
                    </div>
           

                    </div>
                    </ModalStyle>
        </ModalPortal>
            </>
        )
    }
}

export default ActiveLanguageModal;