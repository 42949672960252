import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
} from "../../redux/Functions/actions";
import {
    MenuButton,
    PageStyle,
    PageTitleStyle,
    TableResponsive,
    TableBody,
    TableHead,
    TableRowHead,
    TableRowBody,
    PageContainer,
    Table,
    TableHeadStyle,
    TableBodyStyle,
    EyeIcon,
    BackButton,
    CostumTableHeadStyle,
    Pagination,
} from "../../styles/MainLayout";
// import GlobalModal from "./globalModal";
import Search from "../search/search";
import WorkoutsPlanModal from './addWorkOutPlanModal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from '../../axios';
import ReactPaginate from "react-paginate";


const ListOfPlanQWorkouts = (props) => {
    const plan_id = useParams()


    const [isOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);
    const [planWorkouts, setPlanWorkouts] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [trainerId, setTrainerId] = useState();
    

    
  //Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 8;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }
    const handleMenuClick = (e) => {
        e.prevenTableHeadStyleefault();
        props.toggleMenu();
    };
    const handleGetFitnesPlans = () => {
        axios.get(`/plan_workouts/all/${plan_id?.id}`)
            .then((res) => {
                setPlanWorkouts(res.data);
                setFiltered(res?.data);
                // setTrainerId(res?.data[0]?.fitness_plan?.user.id)

            })
            .catch((err) => { console.log('plan_workouts', err) })
    };

 
    const handleModalAdd = () => {
        setModalType('add')
        setIsOpen(true);
        
    };
    const handleModalEdit = (el) => {
        setModalType('edit')
        setDataToModal(el)
        setIsOpen(true);
    };

    const handleModalClose = () => {
        setDataToModal(null)
        setIsOpen(false);
    };
    const navigate = useNavigate();

    useEffect(() => {
        handleGetFitnesPlans();
    }, [])
    return (
        <>
            {isOpen &&
                <WorkoutsPlanModal isOpen={isOpen}
                    type={modalType}
                    trainerId={plan_id?.trainerId}
                    dataToModal={dataToModal}
                    handleModalClose={() => handleModalClose()}
                    handleGetFitnesPlans={() => handleGetFitnesPlans()}
                />
            }
            <PageStyle isMenuOpen={props?.menu}>

                <PageTitleStyle>List of Plan Workouts</PageTitleStyle>
                <Search data={planWorkouts} filtered={setFiltered} setPageNumber={setPageNumber} />

                <MenuButton onClick={handleMenuClick}>{props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>

                <PageContainer>

                    <TableResponsive isMenuOpen={props?.menu}>
                        <BackButton onClick={() => navigate(-1)}>
                        <i className="fa fa-chevron-left backIcon "></i><h6>Back</h6>
                        </BackButton>
                        <Table>
                            <TableHead>
                                <TableRowHead>
                                    <TableHeadStyle tableFirstElement>id</TableHeadStyle>
                                    <TableHeadStyle>Exercise Name AL</TableHeadStyle>
                                    <TableHeadStyle>Exercise Name EN</TableHeadStyle>
                                    {/* <TableHeadStyle>Exercise Video</TableHeadStyle> */}
                                    <TableHeadStyle>Workout Category</TableHeadStyle>
                                    <CostumTableHeadStyle>Actions</CostumTableHeadStyle>
                                </TableRowHead>
                            </TableHead>
                            <TableBody>
                            {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>
              }
                {filtered && filtered?.length > 0 && <>
                  {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                        return(
                          <>
                                        <TableRowBody>
                                            <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                                            <TableBodyStyle>{el?.name_al}</TableBodyStyle>
                                            <TableBodyStyle>{el?.name_en}</TableBodyStyle>
                                            {/* <TableBodyStyle>{el?.video}</TableBodyStyle> */}
                                            <TableBodyStyle>{el?.category?.name_al} / {el?.category?.name_en}</TableBodyStyle>
                                            <TableBodyStyle className='text-center'>
                                                <EyeIcon className="me-3" onClick={() => handleModalEdit(el)} />
                                                <span className="mx-2">Exc. Sets</span>
                                                <Link to={`/workout-sets/${el?.id}`}>
                                                    <i className="fa fa-gear" title="User Info"></i>
                                                </Link>
                                            </TableBodyStyle>

                                            {/* <TableBodyStyle><EyeIcon onClick={() => handleModalEdit()} /> </TableBodyStyle> */}
                                        </TableRowBody>
                                        </>
                       )
                      })}</>
                    }
                            </TableBody>
                        </Table>
                        <Pagination>
              <div className="text-right">
                {((planWorkouts?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
                    </TableResponsive>
                </PageContainer>

            </PageStyle>

            <MenuButton add onClick={() => { handleModalAdd(); }}>
                <i className="fa fa-plus" />
            </MenuButton>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        lang: state.data.lang,
        langData: state.data.langData,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        // loadingOff: () => dispatch(loadingOff()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListOfPlanQWorkouts);