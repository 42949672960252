import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
const EditNewVariation = (props) => {

    const [items, setItems] = useState(props?.initialItems);
    const [text, setText] = useState('');
  
    console.log("props",props)
    // useEffect(() => {
    //     setItems(props?.initialItems); 
    //   }, [props?.initialItems]);

    const addItem = (e) => {
        e.preventDefault();
        let data = {
            product_id : props?.product_id,
            name_al:text
        }
        axios.post(`/ecommerce-variations/new-variation`, data)
        .then(data => {
          props?.handleModalClose();
          props?.getData();
          toast.success('Të dhënat e produktit janë shtuar me sukses!');
  
        })
        .catch(err => {
          toast.error('Të dhënat e produktit nuk janë shtuar!');
          console.log(err);
        });
        // setText('');
        // props?.handleModalClose()
      }
 

    console.log("text",text)
  return (
    <div>



                <div className="card p-4 my-3">
                      <div className="d-flex align-items-center justify-content-between">

                      <h4>Add {props?.name}</h4>
                      <button type="button" class="btn-close" aria-label="Close" onClick={()=>props?.handleModalClose()}></button>
                      </div>
                      <label htmlFor="">Name:</label>
                      <input
                              required
                              id="name"
                              type="text"
                              placeholder="Name"
                              className="form-control"
                              value={text}
                              onChange={(e) => setText(e.target.value)}
                            />
                            <button className="btn btn-success mt-3" onClick={addItem}>Add</button>
                      </div> 
    </div>
  )
}

export default EditNewVariation