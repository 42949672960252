import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from '../../axios'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const CountriesModal = (props) => {
  console.log("propsCon", props?.country?.id)
  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [countries, setCountries] = useState();
  const [addCountries, setaddCountries] = useState({
    id: props?.country?.id,
    // name: '',
    // prefix: ''
  })
  console.log("addCountries", addCountries)

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })
  const getUserData = () => {
    axios.get('/countries/all').then(data => {
      setCountries(data.data);
    }).catch(err => {
      console.log('userProfileGetErr', err);
    })
  }
  const HandleAddCountries = (e) => {
    e.preventDefault();
    axios.post('/countries', addCountries)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e qytetit janë shtuar me sukses!');
  
  
      }
      ).catch(err => {
        toast.error('Te dhenat nuk jane shtuar!');
        console.log(err);
      });
  }
  const handelEditSubmmision = (e) => {
    // console.log("editttt")
    e.preventDefault();
    setEdit(false);
    axios.put(`/countries`, addCountries)
      .then(data => {
        toast.success('Të dhënat e qytetit janë përditësuar me sukses!');
        props?.getData();
        props?.handleModalClose();

      })
      .catch(err => {
        console.log(err);
        toast.error('Të dhënat e qytetit nuk janë përditësuar!');


      });
  }
  const deleteCountries = () => {
    axios.delete(`/countries/${props?.country?.id}`).then(data => {
      toast.success('Të dhënat  janë fshir me sukses!');

      props?.handleModalClose();
      props?.getData();


    }).catch(err => {
      console.log('userProfileGetErr', err);
      toast.error('Të dhënat nuk jan fshir me sukses!');

    })
  }

  const handleChange = (e) => {
    // setSelectedCountries(e.target.value)
    setaddCountries({
      ...addCountries,
      [e.target.name]: e.target.value,
    })

  }
  // useEffect((e)=>{

  //   if (props?.type=="edit") {
  //     setaddCountries({
  //       ...addCountries,
  //       [e?.target?.name]: e?.target?.value,
  //     })
    
  //   } else {
  //     setaddCountries(null)
  //   }

  // },[props?.type=="edit"]);
  useEffect(() => {
    getUserData();
    // getUserCity();
  }, []);
  useEffect(() => {
    if (props?.isOpen) {
      setEdit(false);
      setDelete(false);
    }
  }, [props?.isOpen]);


  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                        <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons>  */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>


              {isdelete === false && (
                <Form >
                  <ModalTitle title>Modal Title</ModalTitle>
                  <div className="form-group">
                    <label>Emri:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      defaultValue={props?.type === "edit" ? props?.country?.name : ''}

                      name="name"
                      onChange={handleChange}
                      disabled={!isEdit || props?.type === "add" ? false : true}
                    />
                  </div>
                  <div className="form-group">
                    <label>Prefix:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="prefix"
                      defaultValue={props?.type === "edit" ? props?.country?.prefix : ''}

                      name="prefix"
                      onChange={handleChange}
                      disabled={!isEdit || props?.type === "add" ? false : true}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label>Mbiemer:</label>
                    <select
                      onChange={handleChange}
                      disabled={!isEdit || props?.type === "add" ? false : true}
                      className="form-control text-capitalize"
                      name="prefix"
                      defaultValue={props?.type === "edit" ? props?.country?.prefix : ''}
                      

                    >
                      {
                        countries?.map(el => {
                          // console.log('el edit diqka', el, selectedCountry)
                          return (
                            <>
                              <option value={el?.prefix}>{el?.prefix}</option>
                            </>
                          )
                        })
                      }
                    </select>
                  </div> */}


                  {props?.type === "add" && (
                    <SaveButtonModal onClick={HandleAddCountries} type="submit">
                      Ruaj
                    </SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal onClick={handelEditSubmmision} type="submit" disabled={!isEdit || props?.type === "add" ? false : true}>
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>


              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={deleteCountries}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default CountriesModal;
