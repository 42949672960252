import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import {
  MenuButton,
  PageStyle,
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  EyeIcon,
  Pagination
} from "../../styles/MainLayout";
import NewsCategoriesModal from "./newsCategoriesModal";
import Search from "../search/search";
import axios from "../../axios"
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

const NewsCategories = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [dataToModal , setDataToModal] = useState()
  const [newsCategories, setNewsCategories] = useState([])
  const [filtered, setFiltered] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 10;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
      setPageNumber(selected);
  }
  const getNewsCategory = () => {
    axios.get('news-category/all').then(
      response => {
        setNewsCategories(response?.data)
        setFiltered(response?.data)
      }
    ).catch(err => {
      console.log(err)
      toast.error("Something went wrong")
    })
  }

  const handleModalAdd = () => {
    setModalType('add')
    setIsOpen(true);
  };

  const handleModalEdit = (data) => {
    setModalType('edit')
    setIsOpen(true);
    setDataToModal(data)
  };

  const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
  };
  
  useEffect(() => {
    getNewsCategory()
  }, [])
  return (
    <>      
    {isOpen === true ? <NewsCategoriesModal getNewsCategory={getNewsCategory} data={dataToModal} isOpen={isOpen} type={modalType} handleModalClose={() => setIsOpen()} />
      : ""
    }
      <PageStyle isMenuOpen={props?.menu}>
        <PageTitleStyle>News Categories</PageTitleStyle>
        <Search data={newsCategories} filtered={setFiltered} setPageNumber={setPageNumber}/>
        <MenuButton onClick={handleMenuClick}>{props?.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>
        <PageContainer>
          <TableResponsive isMenuOpen={props?.menu}>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableHeadStyle tableFirstElement>Emri i kategorise se Lajmeve</TableHeadStyle>
                  <TableHeadStyle tableFirstElement>News category name</TableHeadStyle>
                  <TableHeadStyle>Actions</TableHeadStyle>
                </TableRowHead>
              </TableHead>
              <TableBody>
                {filtered?.map(el => {
                  return (
                    <>
                      <TableRowBody>
                        <TableBodyStyle tableFirstElement>{el?.name_al}</TableBodyStyle>
                        <TableBodyStyle tableFirstElement>{el?.name_en}</TableBodyStyle>
                        <TableBodyStyle><EyeIcon onClick={() => handleModalEdit(el)} /> </TableBodyStyle>
                      </TableRowBody>
                    </>
                  )
                })}
              </TableBody>
            </Table>
            <Pagination>
              <div className="text-right">
                {((newsCategories?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
          </TableResponsive>
        </PageContainer>
      </PageStyle>
      <MenuButton add onClick={() => { handleModalAdd(); }}>
        <i className="fa fa-plus" />
      </MenuButton>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsCategories);