import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loggMeIN, rmAuth, toggleMenu } from "../../redux/Functions/actions";
import {
  MenuButton,
  PageStyle,
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  EyeIcon,
  Pagination,
} from "../../styles/MainLayout";
import FitnessPlansModal from "./fitnessPlansModal";
import Search from "../search/search";
import { Link } from "react-router-dom";
import axios from "../../axios";
import ReactPaginate from "react-paginate";



const FitnessPlans = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [dataToModal, setDataToModal] = useState(null);



  const [ fitnessPlan, setFitnessPlan] = useState([]);
  const [filtered, setFiltered] = useState([]);


  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 8;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }


  const getFitnessPlan = () => {
    axios.get('/fitness-plans/all').then(data => {
      setFitnessPlan(data.data);
      setFiltered(data?.data);
    }).catch(err => {
      console.log(err);
    })
  }
  
  useEffect(()=>{
    getFitnessPlan()
  },[])
  console.log("Fitness Plan data", dataToModal)

  const handleModalAdd = () => {
    setModalType("add");
    setIsOpen(true);
  };

  const handleModalEdit = (data) => {
    setModalType("edit");
    setIsOpen(true);
    setDataToModal(data);
  };

  const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
  };

  return (
    <>
    {isOpen === true ?
      <FitnessPlansModal
        isOpen={isOpen}
        type={modalType}
        handleModalClose={()=>{setIsOpen() ; setDataToModal(null)}}
        data={dataToModal}
        getData={() => getFitnessPlan()}
      />
      : ""
      }
      <PageStyle isMenuOpen={props?.menu}>
        <PageTitleStyle>Fitness Plans</PageTitleStyle>
        <Search data={fitnessPlan} filtered={setFiltered} setPageNumber={setPageNumber} />

        <MenuButton onClick={handleMenuClick}>
          {props.menu ? (
            <i className="fa fa-chevron-left"></i>
          ) : (
            <i className="fa fa-bars"></i>
          )}
        </MenuButton>

        <PageContainer>
          <TableResponsive isMenuOpen={props?.menu}>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableHeadStyle tableFirstElement>ID</TableHeadStyle>
                  <TableHeadStyle>Personal Trainer</TableHeadStyle>
                  <TableHeadStyle>Plan Name</TableHeadStyle>
                  <TableHeadStyle>Plan Category</TableHeadStyle>
                  <TableHeadStyle>Location</TableHeadStyle>
                  <TableHeadStyle className="text-center">Actions</TableHeadStyle>
                </TableRowHead>
              </TableHead>
              <TableBody>
               
              {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>
              }
                {filtered && filtered?.length > 0 && <>
                  {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                    console.log("el",el)
                    return(
                      <TableRowBody>
                      <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                      <TableBodyStyle>{el?.user?.first_name + " " +el?.user?.last_name}</TableBodyStyle>
                      <TableBodyStyle>{el?.name_al}</TableBodyStyle>
                      <TableBodyStyle>{el?.goal?.name_al}</TableBodyStyle>
                      <TableBodyStyle>{el?.location?.name_al}</TableBodyStyle>
                      <TableBodyStyle className="text-center">
                        <EyeIcon className="me-3" onClick={() => handleModalEdit(el)}/>
                            <span className="mx-2">Plan Config</span>
                        <Link to={`/fitness-plans-weeks/${el?.id}`}>
                          <i className="fa fa-gear" title="User Info"></i>
                        </Link>
                        <span className="mx-2">Plan Wrk.</span>
                        <Link to={`/plan-workouts/${el?.id}/${el?.user?.id}`}>
                          <i className="fa fa-gear" title="User Info"></i>
                        </Link>
                      </TableBodyStyle>
                    </TableRowBody>
                   )
                  })}</>
                }
                {/* <TableRowBody>
                  <TableBodyStyle tableFirstElement>1258</TableBodyStyle>
                  <TableBodyStyle>Ibrahim Tolaj</TableBodyStyle>
                  <TableBodyStyle>The Powerlifting Plan</TableBodyStyle>
                  <TableBodyStyle>Gain Muscle</TableBodyStyle>
                  <TableBodyStyle>Gym</TableBodyStyle>
                  <TableBodyStyle className="text-center">
                    <EyeIcon className="me-3" onClick={() => handleModalEdit()}/>
                        <span className="mx-2">Plan Config</span>
                    <Link to={"/fitness-plans-weeks/1"}>
                      <i className="fa fa-gear" title="User Info"></i>
                    </Link>
                    <span className="mx-2">Plan Wrk.</span>
                    <Link to={"/plan-workouts/1"}>
                      <i className="fa fa-gear" title="User Info"></i>
                    </Link>
                  </TableBodyStyle>
                </TableRowBody> */}
              </TableBody>
            </Table>
            <Pagination>
              <div className="text-right">
                {((fitnessPlan?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
          </TableResponsive>
        </PageContainer>
      </PageStyle>

      <MenuButton
        add
        onClick={() => {
          handleModalAdd();
        }}
      >
        <i className="fa fa-plus" />
      </MenuButton>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FitnessPlans);
