import React, { useState, useEffect } from "react";

const FitnessMap = ({ onLocationChange, selectedLocation }) => {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (selectedLocation && map) {
      map.setCenter(selectedLocation);
      if (marker) {
        marker.setPosition(selectedLocation);
      }
    }
  }, [selectedLocation, map]);

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    if (marker) {
      marker.setPosition({ lat, lng });
    }

    onLocationChange(lat, lng);
  };

  useEffect(() => {

    const mapOptions = {
      center: { lat: 42.6629, lng: 21.1655 }, 
      zoom: 13,
    };
    const mapElement = document.getElementById("fitnessMap");
    const newMap = new window.google.maps.Map(mapElement, mapOptions);
    setMap(newMap);

    setMarker(new window.google.maps.Marker({ position: mapOptions.center, map: newMap }));


    newMap.addListener("click", handleMapClick);

    return () => {
      if (marker) {
        marker.setMap(null); 
      }
    };
  }, []);

  return (
    <div id="fitnessMap" style={{ height: "400px", width: "100%" }}></div>
  );
};

export default FitnessMap;
