import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios"
import { toast } from "react-toastify";
import Switch from "react-switch";

import 'react-toastify/dist/ReactToastify.css';
const PackagesModal = (props) => {

  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [isActive, setIsActive] = useState(props?.packages?.is_active);


  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      props?.handleModalClose();
    }
  });
  const HandlePackagesPost = (e) => {
    e.preventDefault();
    let body = {
      month: e?.target?.month?.value,
      name_al: e?.target?.name_al?.value,
      name_en: e?.target?.name_en?.value,
      price: e?.target?.price?.value,
      sale_price: e?.target?.sale_price?.value,
      is_active: isActive,
      // active:active
    }
    console.log("bodypost", body)
    axios.post('/packages', body)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Package added sucessfully!');


      }
      ).catch(err => {
        toast.error('Something went wrong');
        console.log(err);
      });
  }
  const handelEditSubmmision = (e) => {
    e.preventDefault();
    let body = {
      id: props?.packages?.id,
      month: e?.target?.month?.value,
      name_al: e?.target.name_al?.value,
      name_en: e?.target?.name_en?.value,
      price: e?.target?.price?.value,
      sale_price: e?.target?.sale_price?.value,
      is_active: String(isActive),
      // active:active
    }
    console.log("ereeree", body)

    setEdit(false);
    axios.put(`/packages`, body)
      .then(data => {
        toast.success('Package edited sucessfully!');
        props?.getData();
        props?.handleModalClose();

      })
      .catch(err => {
        console.log(err);
        toast.error('Something went wrong');


      });
  }
  const deletCity = () => {
    axios.delete(`/packages/${props?.packages?.id}`).then(data => {
      toast.success('Package deleted sucessfully!');
      props?.handleModalClose();
      props?.getData();

    }).catch(err => {
      console.log('userProfileGetErr', err);
      toast.error('Something went wrong');

    })
  }
  const handleChecked = () => {
    setIsActive(!isActive);
  };

  // useEffect((e)=>{

  //   if (props?.type=="edit") {
  //     setAddPackages({
  //       ...addPackages,
  //       [e?.target?.name]: e?.target?.value,
  //     })

  //   } else {
  //     setAddPackages(null)
  //   }

  // },[props?.type=="edit"]);
  useEffect(() => {
    if (props?.isOpen) {
      setEdit(false);
      setDelete(false);
    }
  }, [props?.isOpen]);
  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>
              <ModalActionButtonWrapper>
                {props?.type !== "add" ? (
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                      <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons> */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons
                      close
                      onClick={() => props?.handleModalClose()}
                    >
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                ) : (
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                )}
              </ModalActionButtonWrapper>

              {isdelete === false && (
                <Form onSubmit={props.type == "edit" ? handelEditSubmmision : HandlePackagesPost}>
                  <ModalTitle title>Modal Title</ModalTitle>

                  <div className="form-group">
                    <label>MemberShip Name al: <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      name="name_al"
                      id="name_al"
                      defaultValue={props?.packages?.name_al}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>MemberShip Name en:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name_en"
                      id="name_en"
                      defaultValue={props?.packages?.name_en} />
                  </div>
                  <div className="form-group">
                    <label>Duration:<span className="text-danger">*</span></label>
                    <select
                      className="form-control text-capitalize"
                      name="month"
                      id="month"
                      // disabled={isEdit || props?.type === "add" ? false : true}
                      // defaultValue={props?.packages?.month}
                      defaultValue={props?.type === "edit" ? props?.packages?.month : ''}
                      disabled={!isEdit || props?.type === "add" ? false : true}

                    >
                      {props.type == "edit" ?
                        <option hidden selected={props?.type === "edit" ? true : false} value={props?.packages?.month}>{props?.packages?.month}</option>
                        :
                        <option value={null} selected={props?.type === "edit" ? false : true}>Select Duration</option>
                      }
                      <option value={1}>1 month </option>
                      <option value={2}>2 Months </option>
                      <option value={3}>3 Months </option>
                      <option value={4}>4 Months </option>
                      <option value={5}>5 Months </option>
                      <option value={6}>6 Months </option>
                      <option value={7}>7 Months </option>
                      <option value={8}>8 Months </option>
                      <option value={9}>9 Months </option>
                      <option value={10}>10 Months </option>
                      <option value={11}>11 Months </option>
                      <option value={12}>12 Months </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Price:<span className="text-danger">*</span></label>
                    <input
                      step="0.01"
                      type="number"
                      className="form-control"
                      placeholder="price"
                      name="price"
                      id="price"
                      required
                      // defaultValue={props?.packages?.price}
                      // disabled={isEdit || props?.type === "add" ? false : true}
                      defaultValue={props?.type === "edit" ? props?.packages?.price : ''}
                      disabled={!isEdit || props?.type === "add" ? false : true}
                    />
                  </div>
                  <div className="form-group">
                    <label>Sale Price:<span className="text-danger">*</span></label>
                    <input
                      type="number"
                      step="0.01"
                      className="form-control"
                      placeholder="price"
                      name="sale_price"
                      id="sale_price"
                      required
                      defaultValue={props?.type === "edit" ? props?.packages?.sale_price : ''}
                      disabled={!isEdit || props?.type === "add" ? false : true}
                    // defaultValue={props?.packages?.sale_price}
                    // disabled={isEdit || props?.type === "add" ? false : true}
                    />
                  </div>
                  <div className="form-group mt-2 d-flex">
                    <label>Active:</label>
                    <Switch
                      onChange={handleChecked}
                      checked={isActive}
                      //change this when active is done in backend with "props.packages.active"
                      name="is_active"
                      onColor="#ff0000"
                      onHandleColor="#000000"
                      handleDiameter={30}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch ms-2"
                    />
                  </div>
                  {props?.type === "add" && (
                    <SaveButtonModal type="submit">Ruaj</SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal
                      type="submit"
                      disabled={!isEdit || props?.type === "add" ? false : true}
                    >
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>
              )}
              {isdelete && (
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>
                      Deshironi te fshini te dhenat?
                    </ModalTitle>
                    <DeleteButtonModal onClick={deletCity}>
                      Fshij
                    </DeleteButtonModal>
                  </DeleteMessage>
                </>
              )}
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default PackagesModal;
