import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import { 
  MenuButton, 
  PageStyle, 
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  BackButton,
  EyeIcon } from "../../styles/MainLayout";
  import './usersInfo.scss';

import Search from "../search/search";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../axios"
import { useParams } from "react-router-dom";

const UserInfo = (props) => {
  let param = useParams()
const [isOpen, setIsOpen] = useState(false);
const [modalType, setModalType] = useState(null);
const [activeTab, setActiveTab] = useState(0);
const [ecommercePurchases, setEcommercePurchases] = useState([]);
const [userSubscription, setUserSubscription] = useState([]);
const [dayPasses, setDayPasses] = useState([]);

console.log("propsat", param?.id)


const handleModalAdd = () => {
  setModalType('add')
  setIsOpen(true);
};

const handleModalEdit = () => {
  setModalType('edit')
  setIsOpen(true);
};

const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
};    
const navigate = useNavigate();

const getUserSubscription = () => {
  axios.get(`user_subscription/subscriptions/${param?.id}`).then(data => {
    setUserSubscription(data?.data);

  }).catch(err => {
    console.log(err)
  })
}
// const getEcommercePurchases = () => {
//   axios.get(`/orders/purchases/${param?.id}`).then(data => {
//     setEcommercePurchases(data?.data);

//   }).catch(err => {
//     console.log(err)
//   })
// }
const getDayPasses = () => {
  axios.get(`/day_passes/${param?.id}`).then(data => {
    setDayPasses(data?.data);

  }).catch(err => {
    console.log(err)
  })
}

  useEffect(()=>{
    // getEcommercePurchases();
    getUserSubscription();
    getDayPasses();
  },[])
  
return (
      <>

        
        <PageStyle isMenuOpen={props?.menu}>

          <PageTitleStyle>
            {activeTab === 0 
            ?
            <span>User Subscription</span>
            : 
            // activeTab === 1
            // ?
            // <span>E-Commerce Purchases</span>
            // :
            activeTab === 2
            ? 
            <span>#1 Day Free Passes</span>
            : 
            ""
            }
            
            </PageTitleStyle>
          <Search />
          <MenuButton onClick={handleMenuClick}>{props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>
            <PageContainer>
              <TableResponsive isMenuOpen={props?.menu}>
                <div className="row">

                <div className="col-md-6 ps-3 pb-2 d-flex gap-3">
      

            <button className={ activeTab === 0 ? "buttonSelected" : "buttonBorder"} onClick={()=>{setActiveTab(0)}}>User Subscription</button>
            {/* <button className={ activeTab === 1 ? "buttonSelected" : "buttonBorder"} onClick={()=>{setActiveTab(1)}}>E-Commerce Purchases</button> */}
            <button className={ activeTab === 2 ? "buttonSelected" : "buttonBorder"} onClick={()=>{setActiveTab(2)}}>#1 Day Free Passes</button>
                </div>
                <div className="col-md-6 ">
                <BackButton onClick={() => navigate(-1)}>
                <i className="fa fa-chevron-left backIcon "></i><h6>Back</h6>

                </BackButton>
                </div>
                </div>
                {activeTab === 0 ?
                <Table>
                  <TableHead>
                    <TableRowHead>
                      <TableHeadStyle tableFirstElement>ID</TableHeadStyle>
                      <TableHeadStyle >Subscription</TableHeadStyle>
                      <TableHeadStyle>Personal Trainer</TableHeadStyle>
                      <TableHeadStyle>Experation Date</TableHeadStyle>
                      <TableHeadStyle>Device ID</TableHeadStyle>
                      <TableHeadStyle>Status</TableHeadStyle>
                      <TableHeadStyle>Device</TableHeadStyle>
                    </TableRowHead>
                  </TableHead>
                  {userSubscription?.map((el)=>{
                    return(
                      <>
                  <TableBody>
                    <TableRowBody>
                      <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                      <TableBodyStyle>{el?.package?.name_al}</TableBodyStyle>
                      <TableBodyStyle>{el?.personalTrainerInfo?.first_name} {el?.personalTrainerInfo?.last_name}</TableBodyStyle>
                      <TableBodyStyle>{el?.experation_date?.slice(0,10)}</TableBodyStyle>
                      <TableBodyStyle></TableBodyStyle>
                      <TableBodyStyle>{el?.is_valid ? "Active" : "Expired"}</TableBodyStyle>
                      <TableBodyStyle>App</TableBodyStyle>
                    </TableRowBody>
                  </TableBody>
                      </>
                    )
                  })}
                </Table>
                : 
                activeTab === 1 ? 
                <Table>
                <TableHead>
                  <TableRowHead>
                    <TableHeadStyle tableFirstElement>ID</TableHeadStyle>
                    <TableHeadStyle >Order ID</TableHeadStyle>
                    <TableHeadStyle>Name</TableHeadStyle>
                    <TableHeadStyle>Date</TableHeadStyle>
                    <TableHeadStyle>Delivery Address</TableHeadStyle>
                    <TableHeadStyle>Status</TableHeadStyle>
                  </TableRowHead>
                </TableHead>
                {/* {ecommercePurchases?.map((el)=>{
                  return(
                    <>
                <TableBody>
                  <TableRowBody>
                    <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                    <TableBodyStyle>{el?.transaction_id}</TableBodyStyle>
                    <TableBodyStyle>{el?.name_surname}</TableBodyStyle>
                    <TableBodyStyle>{el?.date?.slice(0,10)}</TableBodyStyle>
                    <TableBodyStyle>{el?.address}</TableBodyStyle>
                    <TableBodyStyle>{el?.orders_status?.name_al}</TableBodyStyle>
                  </TableRowBody>
                </TableBody>
                    </>
                  )
                })} */}
              </Table>
              : 
              activeTab === 2 ? 
              <Table>
              <TableHead>
                <TableRowHead>
                  <TableHeadStyle tableFirstElement>ID</TableHeadStyle>
                  {/* <TableHeadStyle >PASS ID</TableHeadStyle> */}
                  <TableHeadStyle>Fitness Name</TableHeadStyle>
                  <TableHeadStyle>Date</TableHeadStyle>
                  <TableHeadStyle>Status</TableHeadStyle>
                </TableRowHead>
              </TableHead>
              {dayPasses?.map((el)=>{
                return (
                  <>
                <TableBody>
                <TableRowBody>
                  <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                  {/* <TableBodyStyle>SHF135115</TableBodyStyle> */}
                  <TableBodyStyle>{el?.fitness?.name}</TableBodyStyle>
                  <TableBodyStyle>{el?.created_at.slice(0,10)}</TableBodyStyle>
                  <TableBodyStyle>{el?.status ? "Used" : "Not Used"}</TableBodyStyle>
                </TableRowBody>
              </TableBody>
                  </>
                )
              })}
     
            </Table>
            :
            <h6>Please Select the right button</h6>    
            }

            </TableResponsive>
          </PageContainer>

        </PageStyle>

      </>
)
}

const mapStateToProps = (state) => {
    return {
      lang: state.data.lang,
      langData: state.data.langData,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu()),
      // loadingOff: () => dispatch(loadingOff()),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);