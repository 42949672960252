import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios";
import { toast } from "react-toastify";

const FitnessPlansModal = (props) => {
  
  const fitnessPlansData = props?.data;
  console.log("fitnes_data", fitnessPlansData)
  console.log("props", props)

  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [dataToSave, setDataToSave] = useState(fitnessPlansData)
  const [exerciseLevels, setExeciseLevels] = useState();
  const [personalTrainers, setPersonalTrainers] = useState();
  const [equipments, setEquipments] = useState();
  const [goals, setGoals] = useState();
  const [location, setLocation] = useState();

  const [isActive, setIsActive] = useState({
    Day_1: false,
    Day_2: false,
    Day_3: false,
    Day_4: false,
    Day_5: false,
    Day_6: false,
  });

  const handleCheckboxChange = (day) => {
    setIsActive((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };
  

  const getIsActiveArray = () => {
    return Object.keys(isActive).map((key, index) => ({
      [index]: isActive[key] ? 1 : 0,
    }));
  };

  
  const handleAddSubmmision = (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      if (key === 'is_active') {
        payload[key].forEach((item, index) => {
          const value = Object.values(item)[0];
          formData.append(`is_active[${index}]`, value);
        });
      } else {
        formData.append(key, payload[key]);
      }
    });
  
      axios.post('fitness-plans', formData)
        .then(data => {
          props?.handleModalClose();
          props?.getData();
          toast.success('Të dhënat janë ruajtur me sukses!');
        }
        ).catch(err => {
          toast.error('Të dhënat nuk janë ruajtur!');
          console.log(err);
        });
  }
  

  const handelEditSubmmision = (e) => {
    e.preventDefault();
    setEdit(false);


            const formData = new FormData();

        Object.keys(payload).forEach((key) => {
          if (key === 'is_active') {
            payload[key].forEach((item, index) => {
              const value = Object.values(item)[0];
              formData.append(`is_active[${index}]`, value);
            });
          } else {
            formData.append(key, payload[key]);
          }
        });
    
      axios.put(`fitness-plans/`, formData)
         .then(data => {
           toast.success('Të dhënat janë përditësuar me sukses!');
           props?.handleModalClose();
           props?.getData();
         })
         .catch(err => {
           console.log(err);
           toast.error('Të dhënat nuk janë përditësuar!');
         });
  }

  const getExerciseLevels = () => {
    axios.get('/exercise-levels/all').then(data => {
      setExeciseLevels(data.data);
      // setFiltered(data?.data);
    }).catch(err => {
      console.log(err);
    })

    axios.get('/personal-trainers/all').then(data => {
      setPersonalTrainers(data.data);
      // setFiltered(data?.data);
    }).catch(err => {
      console.log(err);
    })

    axios.get('/equipments/all').then(data => {
      setEquipments(data.data);
      // setFiltered(data?.data);
    }).catch(err => {
      console.log(err);
    })

    axios.get('/goals/all').then(data => {
      setGoals(data.data);
      // setFiltered(data?.data);
    }).catch(err => {
      console.log(err);
    })

    axios.get('/locations/all').then(data => {
      setLocation(data.data);
      // setFiltered(data?.data);
    }).catch(err => {
      console.log(err);
    })
  }



  const image = (e) => {
    const ud = Object.assign({}, dataToSave);
    ud.image_url = e.target.files[0];
    setDataToSave(ud);
  };


  const handleChange = (e) => {
    if (e.target.name === "coming_soon" || e.target.name === "is_activePlan") {
      setDataToSave({
        ...dataToSave,
        [e.target.name]: e.target.checked,
      })
    }
    else {
      setDataToSave({
        ...dataToSave,
        [e.target.name]: e.target.value,
      })
    }
  }
  const isActiveArray = getIsActiveArray();

  const payload = {
    id:dataToSave?.id,
    name_al: dataToSave?.name_al,
    name_en: dataToSave?.name_en,
    description_al: dataToSave?.description_al,
    description_en: dataToSave?.description_en,
    days_per_week: 6,
    level_id: dataToSave?.level_id,
    plan_weeks: Number(dataToSave?.plan_weeks),
    plan_category: dataToSave?.plan_category,
    plan_location: dataToSave?.plan_location,
    equipment: dataToSave?.equipment,
    trainer_id: Number(dataToSave?.trainer_id),
    is_active: isActiveArray,
    fitness_plans:dataToSave?.image_url,
    average_session_time:dataToSave?.average_session_time,
    coming_soon:dataToSave?.coming_soon,
    is_activePlan:dataToSave?.is_activePlan
  }

  const resetCheckboxes = () => {
    setIsActive({
      Day_1: false,
      Day_2: false,
      Day_3: false,
      Day_4: false,
      Day_5: false,
      Day_6: false,
    });
  };
  
  console.log("data to save", payload)

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })


  useEffect(() => {
    getExerciseLevels();

    if (props?.isOpen) {
      setEdit(false);
      setDelete(false);
      resetCheckboxes()
    }
  }, [props?.isOpen]);

  fitnessPlansData?.fitness_plans_weeks?.sort((a, b) => {
    return a.day_id - b.day_id;
  });

  useEffect(() => {
    if(props?.type == "edit"){

      const newIsActive = {};
      for (let i = 0; i < fitnessPlansData?.fitness_plans_weeks.length; i++) {
        const dayIndex = fitnessPlansData?.fitness_plans_weeks[i].day_id;
        newIsActive[`Day_${dayIndex}`] = fitnessPlansData?.fitness_plans_weeks[i].is_active;
      }
      setIsActive(newIsActive);
    }
  }, [fitnessPlansData?.fitness_plans_weeks]);
  

  const handleDeleteSubmmision = () => {

    axios.delete(`/fitness-plans/${fitnessPlansData?.id}`).then(data => {
      toast.success('Të dhënat  janë fshir me sukses!');
      props?.handleModalClose();
      props?.getData();
    }).catch(err => {
      console.log(err);
      toast.error('Të dhënat nuk jan fshir me sukses!');

    })
  }


  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper onClick={(e) => e.stopPropagation()}>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                      <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons> */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>


              {isdelete === false && (
                <Form onSubmit={props?.type === "add" ? handleAddSubmmision : props?.type === "edit" ? handelEditSubmmision : ""}>
                  <ModalTitle title>Fitness Plan Weeks</ModalTitle>

                  <div className="form-group mb-2">
                    <label>Plan Name Shqip:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Plan Name Shqip"
                      name="name_al"
                      required
                      onChange={handleChange}
                      defaultValue={props?.type=='edit' ? props?.data?.name_al : ""}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Plan Name Anglisht:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Plan Name Anglisht"
                      name="name_en"
                      required
                      onChange={handleChange}
                      defaultValue={props?.type=='edit' ? props?.data?.name_en : ""}
                    />
                  </div>
              
                  <div className="form-group mb-2">
                    <label>Plan Description Shqip:</label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      placeholder="Plan Description"
                      name="description_al"
                      onChange={handleChange}
                      defaultValue={props?.type=='edit' ? props?.data?.description_al : ""}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Plan Description Anglisht:</label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      placeholder="Plan Description Anglisht"
                      name="description_en"
                      onChange={handleChange}
                      defaultValue={props?.type=='edit' ? props?.data?.description_en : ""}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <h6 className="mt-3">Plan details</h6>
                    <hr />
                    <label for="exampleFormControlSelect1">Days per Week</label>
                    <br />

                    {Object.keys(isActive).map((day, index) => (
                        <div className="form-check form-check-inline" key={day}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id={day}
                            checked={isActive[day]}
                            onChange={() => handleCheckboxChange(day)}
                          />
                          <label className="form-check-label" htmlFor={day}>
                            {props?.type === "edit"
                              ? `Day ${parseInt(day.replace('Day_', '')) + 1}`
                              : `Day ${parseInt(day.replace('Day_', ''))}`}
                          </label>
                        </div>
                      ))
                    }


                    <div className="col-md-12">
                      <label for="exampleFormControlSelect1">Level</label>
                      <select className="form-control" 
                      id="exampleFormControlSelect1"
                      name="level_id"
                      required
                      onChange={handleChange}
                      // value={props?.type === "edit" ? selectedCountry : null}
                      defaultValue={props?.data?.level_id}
                        >
                      <option value="" selected > Select exercise level</option>
                       {exerciseLevels?.map((el) => (
                            <option key={el?.id} value={el?.id} selected={fitnessPlansData?.level_id === el.id ? true : null}>
                              {el?.name_al}
                            </option>
                          ))}
                      </select>
                    </div>

                  </div>
                  <div className="form-group mb-2">
                    <label for="exampleFormControlSelect1">Plan Weeks</label>
                    <div className="input-group">
                      <input 
                        type="number" 
                        required
                        className="form-control" 
                        placeholder="e.i 12" 
                        name="plan_weeks" 
                        // max={12}
                        min={1}
                        onChange={handleChange} 
                        defaultValue={props?.type=='edit' ? props?.data?.plan_weeks : ""}
                        />
                    </div>

                    <div className="form-group mb-2">
                      <label for="exampleFormControlSelect1">Plan Category</label>
                      <select 
                        className="form-control" 
                        required
                        id="exampleFormControlSelect1"
                        name="plan_category"
                        onChange={handleChange}
                        defaultValue={props?.data?.plan_category}
                        >
                        <option value="" selected > Select equipment</option>
                        {goals?.map((el) => (
                            <option key={el?.id} value={el?.id} selected={fitnessPlansData?.plan_category === el.id ? true : null}>
                              {el?.name_al}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group mb-2">
                      <label for="exampleFormControlSelect1">Plan Location</label>
                      <select 
                        className="form-control" 
                        required
                        id="exampleFormControlSelect1"
                        name="plan_location"
                        onChange={handleChange}
                        defaultValue={props?.data?.plan_location}
                        >
                       <option value="" selected>Select equipment </option>
                        {location?.map((el) => (
                            <option key={el?.id} value={el?.id} selected={fitnessPlansData?.plan_location === el.id ? true : null}>
                              {el?.name_al}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group mb-2">
                      <label for="exampleFormControlSelect1">Equipment</label>
                      <select 
                        className="form-control" 
                        required
                        id="exampleFormControlSelect1"
                        name="equipment"
                        onChange={handleChange}
                        defaultValue={props?.data?.equipment}
                        >
                        <option value="" selected > Select equipment</option>
                        {equipments?.map((el) => (
                            <option key={el?.id} value={el?.id} selected={fitnessPlansData?.equipment === el.id ? true : null}>
                              {el?.name_al}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="form-group mb-2">
                      <label for="exampleFormControlSelect1">Chose Trainer</label>
                      <select 
                        required
                        className="form-control" 
                        id="exampleFormControlSelect1"
                        name="trainer_id"
                        onChange={handleChange}
                        defaultValue={props?.data?.trainer_id}
                        >
                      <option value="" selected > Select trainer</option>
                        {personalTrainers?.map((el) => (
                          <>
                          {console.log("edasdl",el)}
                            <option key={el?.id} value={el?.id} selected={fitnessPlansData?.trainer_id === el.id ? true : null}>
                              {el?.first_name + " " + el?.last_name}
                            </option>
                            </>
                          ))}
                      </select>
                      <div className="form-group my-2">
                    <label>Average Session Time:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Average Session Time"
                      name="average_session_time"
                      required
                      onChange={handleChange}
                      defaultValue={props?.type=='edit' ? props?.data?.average_session_time : ""}
                    />
                  </div>

                      <div className="form-group my-2">
                    <input
                      // className="ms-2"
                      type="checkbox"
                      className="form-check-input me-2"
                      placeholder="Coming Soon"
                      name="coming_soon"
                      id="coming_soon"
                      onChange={handleChange}
                      defaultChecked={props?.type=='edit' ? props?.data?.coming_soon : ""}
                      />
                      <label for="coming_soon">Coming Soon</label>
                  </div>
                      <div className="form-group my-2">
                    <input
                      // className="ms-2"
                      type="checkbox"
                      className="form-check-input me-2"
                      placeholder="Coming Soon"
                      name="is_activePlan"
                      id="is_activePlan"
                      onChange={handleChange}
                      defaultChecked={props?.type=='edit' ? props?.data?.is_activePlan : ""}
                      />
                      <label for="is_activePlan">Active</label>
                  </div>

                      <div className="form-group mt-3 d-flex justify-content-between align-items-center">
                        <input
                            type="file"
                            name="fitness_plans"
                            onChange={image}
                            />
                            {props?.type === "edit" &&
                                <div>
                                  <img src={`${process.env.REACT_APP_UPLOADS + fitnessPlansData?.image_url}`} alt="photo Preview" 
                                  className="img-fluid" style={{ maxWidth: '100%', maxHeight: '100px' }} />
                                </div>
                              
                            }
                      </div>
                        {/* <option>Ibrahim</option>
                        <option>Dion</option>
                        <option>Urim</option> */}
                      {/* </select> */}
                    </div>
                  </div>

                  {props?.type === "add" && (
                    <SaveButtonModal type="submit">
                      Add Plan
                    </SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal type="submit" >
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>


              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={handleDeleteSubmmision}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default FitnessPlansModal;
