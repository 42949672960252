import axios from "axios";
import jwtDecode from "jwt-decode";
let auth = window.localStorage.getItem("data");
let decode = auth && jwtDecode(auth);
console.log('axiosDecode',decode);

const defaultInstance = axios.create({
    baseURL: process.env.REACT_APP_API,
});

defaultInstance.interceptors.request.use(
    (config) => {
        let token = window.localStorage.getItem("data");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            if (token) {
                config.baseURL += "admin/";
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default defaultInstance;
