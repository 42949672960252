import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,

  rmAuth,
  setActiveMenu,
  toggleMenu,
  setLang
} from "../../redux/Functions/actions";
import { Link, useNavigate } from "react-router-dom";
// import axios from "../../axios";
import { AdminMenuStyle, LogoImage, MenuList, LinkStyle, SubMenuItems, MenuButton } from "../../styles/MainLayout";
import Logo from "../../images/001-01.svg"
import AdminMenuData from "./adminMenuData"
import axios from "../../axios";
import EComMenuData from "./ecomUserMenu";



const AdminMenu = (props) => {

  console.log("propsaaat vjosa", props)
  const navigate = useNavigate()
  const [dropdown, setDropdown] = useState(false);
  const [dropdownStat, setDropdownStat] = useState(false);
  const [languages, setLanguages] = useState([]);


  const getLanguages = () => {
    axios.get('/languages/all').then(data => {
      setLanguages(data.data);
    }).catch(err => {
      console.log('err', err);
    });
  };
  useEffect(() => {
    getLanguages();
  }, []);

  let width = window.innerWidth;
  function handleLogut() {
    navigate("/")
    window.localStorage.clear()
    props?.loggMeIN()
    props?.rmAuth()
  }

  if (props.menu === true) {
    return (
      <>
        <AdminMenuStyle>
          <LogoImage src={Logo} />

        <div class="dropdown text-center mt-3">
             {/* <button class="btn btn-dark dropdown-toggle w-75" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
             {languages && props && 
             (languages.find(lang => lang?.id === props?.lang) || {}).name || "Select Language"}
           </button> */}
           <ul class="dropdown-menu w-75" aria-labelledby="dropdownMenuButton1">
            {languages?.map((el)=>{
              return(
                <>
                <li >
                  <div className="row align-items-center">
                 <div className="col-md-2">

                <img className="ms-2" src={`${process.env.REACT_APP_UPLOADS + el?.image_url}`}style={{ maxWidth: '25px', maxHeight: '25px' }} />
                 </div>
                 <div className="col-md-10">

                  <a class="dropdown-item" style={{cursor:"pointer"}} onClick={()=>props?.setLang(el?.id)}>{el?.name}</a>
                 </div>
                  </div>
                  </li>
                </>
              )
            })}
           </ul>
        </div>
          <MenuList>
          {props?.data?.role === 1 &&
            AdminMenuData?.map((el, index) => {
              if (el?.more?.length > 0) {
                return (
                  <>
                    <LinkStyle to={{}} key={index} onClick={() => { setDropdown(!dropdown); }}>
                      <li className={dropdown === true && 'active'} key={index}>
                        <i className={!dropdown ? "fa fa-chevron-right" : "fa fa-chevron-down"} key={index} />
                        {el.name_al}
                      </li>
                    </LinkStyle>

                    {dropdown === true &&
                      <SubMenuItems> {el.more.map((el, ii) => {
                        return <LinkStyle to={el?.url} key={ii} onClick={() => {
                          if (width < 750) {
                            props?.toggleMenu();
                          } props?.setActiveMenu(el?.idS)
                        }} >
                          <li className={props?.activemenu == el.idS && 'active '} >
                            <i className={el?.icon} />
                            {el.name_al}
                          </li>
                        </LinkStyle>
                      })}
                      </SubMenuItems>
                    }

                  </>
                )
              }
              else
                return <LinkStyle to={el?.url} id={index} onClick={() => {
                  {
                    if (width < 750) {
                      props?.toggleMenu();
                    } props?.setActiveMenu(el.id)
                  }
                }} key={index}>
                  <li className={props?.activemenu === el.id && 'active'}><i className={el?.icon}></i><span >{el.name_al}</span></li>  </LinkStyle>
            })}


          {(props?.data?.role === 5 ) &&
            <>
             {
              EComMenuData?.map((el, index) => {
     
              return <LinkStyle to={el?.url} id={index} onClick={() => {
                {
                  if (width < 750) {
                    props?.toggleMenu();
                  } props?.setActiveMenu(el.id)
                }
              }} key={index}>
                <li className={props?.activemenu === el.id && 'active'}><i className={el?.icon}></i><span >{el.name_al}</span></li>  </LinkStyle>
          })
            }
            </>
            }            
            
            <LinkStyle to={{}}  >
              <li onClick={() => handleLogut()} className="mt-2">
              <i className="fa-solid fa-arrow-right-to-bracket"></i>
                Log out</li>
            </LinkStyle>
          </MenuList>
        </AdminMenuStyle>
      </>
    )
  } else {
    return (
      null
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    activemenu: state.data.activemenu,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
    setActiveMenu: (id) => dispatch(setActiveMenu(id)),
    setLang: (id) => dispatch(setLang(id)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);

// export default Menu;