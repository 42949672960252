import styled, { css } from "styled-components";
// import Logo from "../images/logoMSHMS.jpg"
import { Link } from "react-router-dom";

export const AppWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

export const MenuStyle = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  filter: drop-shadow(0 0 0.75rem gray);
  z-index: 9;
  // transition: 3s ease-in-out;
  ${(props) =>
    props?.isMenuOpen
      ? css`
          width: 20%;
          display: inline-block;
        `
      : css`
          width: 0%;
          display: none;
        `}
  a {
    color: gray;
    font-weight: 600;

    &:hover {
      color: black;
    }
  }

  @media only screen and (max-width: 750px) {
    ${(props) =>
    props?.isMenuOpen
      ? css`
            display: inline-block;
          `
      : css`
            display: none;
          `}
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: white;

    a {
      width: 100%;
      float: left;
      padding: 10px 20px;
    }

    a:hover {
      background-color: black;
      color: white;
    }
  }
`;

export const AdminMenuStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20%;
  height: auto;
  overflow-y: auto;
  background-color: white;
  color: black;
  z-index: 9999;
  box-shadow: inset 0px -1px 11px gray;
  transition: 0.3s ease-in-out;

  @media (max-width: 750px) {
    width: 100%;
    right: 0;
    z-index: 99;
  }
`;

export const LogoImage = styled.img`
  display: block;
  width: 40%;
  margin: 0 auto;  
  margin-top: 20px;
`;

export const MenuList = styled.ul`
  margin-top: 10%;
  height: auto;
  overflow-y: auto;
  list-style-type: none;
  text-align: left;

  li {
    transition: 0.3s ease-in-out;
    padding: 11px 1px;
    color: black;
    i {
      padding-right: 1rem;
    }
    &:hover {
      background-color: black;
      color: white;
      padding-left: 30px;
      border-radius: 20px 0 0 20px;
    }
  }

  li.active {
    background-color: black;
    color: white;
    padding-left: 30px;
    border-radius: 20px 0 0 20px;
  }
`;

export const SubMenuItems = styled.div`
  padding-left: 20px;
  a {
    li {
      i {
        padding-right: 10px;
      }

      transition: 0.3s ease-in-out;
      padding: 10px 25px;
      color: black;

      &:hover {
        background-color: black;
        color: white;
        padding-left: 30px;
        margin-top: 1px;
      }
    }
  }
`;

export const LinkStyle = styled(Link)`
  color: white;
  text-decoration: none;

  &:hover {
    background-color: black;
    color: white;
    text-decoration: none;
  }
`;

export const MenuButton = styled.button`
  position: fixed;
  top: 25px;
  z-index: 99999 !important;
  border-radius: 50%;
  background-color: #fff;
  color: black;
  border: unset;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  border: 2px red solid;
  // transition: .3s ease-in-out;
  font-size: 22px;
  margin-left: 5px;

  ${(props) =>
    props.menu &&
    css`
      left: 20%;
    `}
  ${(props) =>
    props?.add &&
    css`
      top: unset;
      bottom: 20px;
      right: 20px;
    `}

  &:hover {
    background-color: red;
    border: 2px #fff solid;
    color: #fff;
    filter: unset;
  }
  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 750px) {
    z-index: 99999 !important;
    /* top: 22px !important; */
    left: unset !important;
    right: 10px !important;
    // right: 20px !important;

    ${(props) =>
    props.add &&
    css`
        top: unset;
        bottom: 20px;
        right: 20px;
      `}
  }
`;

export const PageStyle = styled.div`
  width: 100;
  position: fixed;
  // height:250px;
  right: 0;
  bottom: 0;
  // top: 0;
  // background: white;
  ${(props) =>
    props?.isMenuOpen
      ? css`
          width: 80%;
        `
      : css`
          width: 100%;
          left: 0;
        `}
`;

export const PageTitleStyle = styled.div`
    transition: 0.3s ease-in-out;
    position: fixed;
    text-align: left;
    border-bottom: 2px solid;
    border-top: 2px solid;
    padding: 20px 80px;
    border-radius: 0 50px 50px 0;
    color: white;
    background-color: black;
    z-index: 10;
    top: 15px;
    font-size: 24px;
    font-weight: 500;
    line-height: 17px;
    border-right: 2px solid;

  @media only screen and (max-width: 750px) {
    padding: 20px 20px !important;
    top: 10px !important;
    left: 0% !important;
    width: 80%;
    ${(props) =>
    props?.workoutCategorisationTitle &&
    css`
     font-size: 24px;
    `}
  }
`;

//PAGE CONTAINTER
export const PageContainer = styled.div`
  position: fixed;
  right: 0%;
  left: 0%;
  height: auto;
  overflow: auto;
  bottom: 0px;
  top: 21%;
`;

/*  MODAL */
export const ModalStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .28);
    z-index: 99999;
    width: 100%;
    height: 100%;

    form {
      margin: 0 auto;
      width: 80%;
    }
 
        a {
          height: 50px;
          width: 50px;
          border: unset;
          color: green;
        }
`;

export const Form = styled.form``;

export const ModalTitle = styled.h3`
  position: sticky;
  // top: -10px;
  width: 80%;
  z-index: 99999;

  ${(props) =>
    props?.title &&
    css`
      margin-bottom: 40px;
    `}
  ${(props) =>
    props?.deleteMessage &&
    css`
      width: 100%;
      margin-top: 10%;
    `}
`;

export const Mymodal_wrapper = styled.div`
  position: absolute;
  right: 0;
  width: 50%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  filter: drop-shadow(0 0 0.75rem gray);
  background-color: white;
  z-index: 999999;
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;

export const ModalActionButtonWrapper = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  text-align: right;
  background-color: #fff;
  z-index: 99999;
`;

export const ModalButtons = styled.button`
  height: 50px;
  width: 50px;
  border: unset;
  color: white;
  ${(props) =>
    props?.edit &&
    css`
      background-color: #ffc107;
    `}

  ${(props) =>
    props?.delete &&
    css`
      background-color: #dc3545;
    `}
  ${(props) =>
    props?.close &&
    css`
      background-color: #444444;
    `}
`;

export const SaveButtonModal = styled.button`
    background-color: black;
    margin-top:10px;
    margin-bottom: 10px;
    margin-left: 1px;
    color: #fff;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    width:100px;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;  
`;


export const ReplicateButtonModal = styled.button`
    background-color: black;
    text-align: center;
    color:white;
    border: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.5;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:hover {
    color: white;
    transition: 0.3s;
    transform: scale(0.9);
  }
`

export const DeleteButtonModal = styled.button`
  width: 100px;
  background-color: #dc3545;
  color: #fff;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const DeleteMessage = styled.div`
  text-align: center;
  padding: 20px;
`;

//TABLE RESPONSIVE
export const TableResponsive = styled.div`
  // position: fixed;
  // right: 0%;
  // height: auto;
  // overflow: auto;

  position: absolute;
  right: 0%;
  height: auto;
  overflow: auto;

  ${(props) =>
    props?.isMenuOpen
      ? css`
          left: 20%;
          width: 80%;
        `
      : css`
          left: 0%;
          width: 100%;
        `}
  &::-webkit-scrollbar {
    width: 0;
  }
`;
export const FitnesPlanWeeksTable = styled.div`
    position: absolute;
    right: 0%;
    height: auto;
    overflow: auto;

  
  ${(props) =>
    props?.isMenuOpen
      ? css`
      left:20%;
      width:80%;
      `
      : css`
      left:0%;
      width:100%
        `}
  &::-webkit-scrollbar {
      width: 0;
  }
`;

export const TableRowHead = styled.tr`
  background-color: black;
  padding: 0.75rem;
  color: black;
  font-weight: 700;

  &:hover {
    filter: drop-shadow(0 0 0.45rem gray);
    margin-bottom: 2px;
  }
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableRowBody = styled.tr`
  padding: 0.75rem;
  margin-left: 10px;
  background-color: white;

  &:hover {
    filter: drop-shadow(0 0 0.45rem gray);
    margin-bottom: 2px;
  }
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

export const TableHeadStyle = styled.th`
  border-top: none;
  padding: 0.75rem;
  border-top: none;
  color: white;
  white-space: nowrap;
  text-align: left;
  ${(props) =>
    props?.tableFirstElement &&
    css`
      padding-left: 47px;
    `}
`;

export const TableBodyStyle = styled.td`
  padding: 0.75rem;
  ${(props) =>
    props?.tableFirstElement &&
    css`
      padding-left: 47px;
    `}
`;

//SEARCH CSS
export const SearchBar = styled.div`
  position: fixed;
  right: 0px;
  top: 3px;
  width: 350px;
  height: 40px;
  border-radius: 50px 0 0 50px;
  background-color: black;
  color: white;
  z-index: 9;

  @media only screen and (max-width: 855px) {
    width: 300px;
    top: 14%;
  }
  `;

export const Refresh = styled.div`
  position: absolute;
  left: 0;
  border-radius: 50%;
  background-color: black;
  height: 40px;
  width: 40px;
  color: white;

  &:hover {
    height: 45px;
    width: 45px;
    left: -2px;
    top: -2px;
  }

  @media only screen and (max-width: 750px) {
    &:hover {
      display: none;
    }
  }
`;

export const ButtonRefresh = styled.div`
  color: white;
  border: unset;
  background: unset;
  line-height: unset;
`;

export const IconRefresh = styled.i.attrs(() => ({
  className: "fa fa-refresh",
}))`
  font-size: 25px;
  line-height: unset;
  padding: 2px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 4px;

  &:hover {
    rotate: 360deg;
    color: white;
  }
`;

export const Input = styled.input`
    width: 80%;
    height: 95%;
    /* padding: 4px; */
    padding-left: 15px;
    margin-left: 17%;
    margin-top: 0.4%;
    border: unset;
    border-radius: 15px;
`;

export const EyeIcon = styled.i.attrs(() => ({ className: "far fa-edit" }))`
  background: unset;
  color: black;
  border: unset;
  cursor: pointer;
  transition: 0.3s ease-in-out;
`;


export const CostumTableHeadStyle = styled.th`
  border-top: none;
  padding: 0.75rem;
  border-top: none;
  color: white;
  white-space: nowrap;
  text-align:center;
  ${(props) =>
    props?.tableFirstElement
    && css`
       padding-left: 47px;
     `}
`;


export const BackButton = styled.div`
display: flex;
flex-direction: row;
gap: 10px;
  margin-right: 25px;
  margin-bottom: 25px;
  text-align:center;
  float: right;
  cursor: pointer;
  border: 2px solid #ed282b;
  /* width: 8%; */
  padding: 8px 16px 2px 10px;
  border-radius: 25px;
  .backIcon{
    margin-top: 3px;
  }
  &:hover {
    background-color: #ed282b;
      color: white;
      border: 2px solid gray;
    transition: 0.5s;

    }
`;

export const Pagination = styled.div`
.paginationBttns {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 60px;

    @media only screen and (max-width: 750px) {
      justify-content: start;
    }
    a{
      padding: 10px;
      margin: 8px;
      border-radius: 5px;
      border: 1px solid black;
      color: black;
      cursor: pointer;

      @media only screen and (max-width: 750px) {
        margin: 5px;
      }
        &:hover{
          color: white !important;
        background-color: black;
        }
      }

  .paginationActive a {
    color: white !important;
    background-color: black;
  }
  .paginationDisabled a {
    display: none;}}
  `;