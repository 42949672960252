import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import {
  MenuButton,
  PageStyle,
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  EyeIcon,
  Pagination
} from "../../styles/MainLayout";
import TrainingLessonsCategoriesModal from "./trainingLessonsCategoriesModal";
import Search from "../search/search";
import axios from "../../axios"
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";


const TrainingLessonsCategories = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [dataToModal , setDataToModal] = useState()
  const [trainingData, setTrainingData] = useState([])
  const [filtered, setFiltered] = useState([]);


  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 9;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
      setPageNumber(selected);
  }
  const getTrainingLessonsCat = () => {
    axios.get('/training-lessons-cat/all').then(
      response => {
        setTrainingData(response.data)
        setFiltered(response.data)
      }
    ).catch(err => {
      console.log(err)
      toast.error("Something went wrong")

    })
  }

  const handleModalAdd = () => {
    setModalType('add')
    setIsOpen(true);
  };

  const handleModalEdit = (data) => {
    setModalType('edit')
    setIsOpen(true);
    setDataToModal(data)
  };

  const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
  };
  
  useEffect(() => {
    getTrainingLessonsCat()
  }, [])
  return (
    <>
      <TrainingLessonsCategoriesModal getTrainingLessonsCat={getTrainingLessonsCat} data={dataToModal} isOpen={isOpen} type={modalType} handleModalClose={() => setIsOpen()} />

      <PageStyle isMenuOpen={props?.menu}>

        <PageTitleStyle>Training Lessons Categories</PageTitleStyle>
        <Search data={trainingData} filtered={setFiltered} setPageNumber={setPageNumber}/>
        <MenuButton onClick={handleMenuClick}>{props?.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>

        <PageContainer>
          <TableResponsive isMenuOpen={props?.menu}>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableHeadStyle tableFirstElement>Emri i kategorise se trajnimit</TableHeadStyle>
                  <TableHeadStyle tableFirstElement>Training category name</TableHeadStyle>
                  <TableHeadStyle>Actions</TableHeadStyle>
                </TableRowHead>
              </TableHead>
              <TableBody>
                {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map(el => {
                  return (
                    <>
                      <TableRowBody>
                        <TableBodyStyle tableFirstElement>{el?.name_al}</TableBodyStyle>
                        <TableBodyStyle tableFirstElement>{el?.name_en}</TableBodyStyle>
                        <TableBodyStyle><EyeIcon onClick={() => handleModalEdit(el)} /> </TableBodyStyle>
                      </TableRowBody>
                    </>
                  )
                })}
              </TableBody>
            </Table>
            <Pagination>
              <div className="text-right">
                {((trainingData?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
          </TableResponsive>
        </PageContainer>

      </PageStyle>

      <MenuButton add onClick={() => { handleModalAdd(); }}>
        <i className="fa fa-plus" />
      </MenuButton>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TrainingLessonsCategories);