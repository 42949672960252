import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import sign from "jwt-encode";

const NewsModal = (props) => {
  const [isdelete, setDelete] = useState(false);
  const [newsCategory, setNewsCategory] = useState([]);  
  const [addNews, setAddNews] = useState({
    id: props?.dataToModal?.id,
    title_al: props?.dataToModal?.title_al
  });
  
  const getCategoryData = () => {
    axios.get('news-category/all').then(data => {
      setNewsCategory(data.data);
    }).catch(err => {
      console.log('err', err)
    });
  };

  const handleAddSubmmision = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(addNews).forEach((key) => {
      formData.append(key, addNews[key]);
    });
      e.preventDefault();
        axios.post('/news', formData)
          .then(data => {
            props?.handleModalClose();
            props?.getData();
            toast.success('Të dhënat janë ruajtur me sukses!');
          }
          ).catch(err => {
            toast.error('Të dhënat nuk janë ruajtur!');
            console.log(err);
          });
  };

  const handleEditSubmmision = (e) => {
    e.preventDefault()
    const formData = new FormData();
    Object.keys(addNews).forEach((key) => {
      formData.append(key, addNews[key])
    })
    if (addNews.newVideo) {
      formData.append('video', addNews.newVideo)
    }
    axios.put(`/news`, formData)
      .then(data => {
        toast.success('Të dhënat  janë përditësuar me sukses!');
        props?.getData();
        props?.handleModalClose();
      }).catch(err => {
        console.log(err);
        toast.error('Të dhënat nuk janë përditësuar!');
      })
  }

  const handleDelete = () => {
    axios.delete(`/news/${props?.dataToModal?.id}`).then(data => {
      toast.success('Të dhënat janë fshirë me sukses!');
      props?.handleModalClose();
      props?.getData();
    }).catch(err => {
      console.log(err);
      toast.success('Të dhënat  nuk janë fshirë me sukses!');
    })
  };

  const handleChange = (e) => {
    let secretKey = process.env.REACT_APP_SECRET;
    if (e.target.name == "password") {
      let encriptedPassword = sign(e.target.value, secretKey)
      setAddNews({
        ...addNews,
        [e.target.name]: encriptedPassword,
      });
    }
      else{
        setAddNews({
          ...addNews,
          [e.target.name]: e.target.value,
        })
      }
  }

  const video = (e) => {
    const ud = Object.assign({}, addNews);
    ud.news = e.target.files[0];
    setAddNews(ud);
  };
  
  useEffect(() => {
    getCategoryData();
  }, []);

  useEffect(() => {
    if (props?.isOpen) {
      setDelete(false);
    }
  }, [props?.isOpen]);
  
  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()}/>
            <Mymodal_wrapper>
              <ModalActionButtonWrapper>
                { props?.type !== "add" ?
                  <>
                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>
                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :   
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>
              {isdelete === false && (
                <Form>
                  <ModalTitle title>Add News </ModalTitle>
                  <div className="form-group">
                    <label>Shto titullin:</label>
                    <input
                      required
                      id="name"
                      name="title_al"
                      type="text"
                      className="form-control"
                      placeholder="Loose weight"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Add Title:</label>
                    <input
                      id="name"
                      name="title_en"
                      type="text"
                      placeholder="ex. Fitness Gloves"
                      className="form-control"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.title_en : ""}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Shto pershkrimin:</label>
                    <textarea
                      id="name"
                      name="description_al"
                      type="text"
                      className="form-control"
                      placeholder="Pershkrimi juaj"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.description_al : ""}
                      rows="4"
                      cols="11"
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Add Description:</label>
                    <textarea
                      id="name"
                      name="description_en"
                      type="text"
                      className="form-control"
                      placeholder="ex. Lorem Ipsum"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.description_en : ""}
                      rows="4"
                      cols="11"
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Choose Category:</label>
                    <select
                      required
                      id="name"
                      name="news_category_id"
                      className="form-control"
                      onChange={handleChange}
                      >
                      <option hidden selected value="">Select Category</option>
                      {newsCategory?.map((el) => (
                        <option  key={el?.id} value={el?.id} selected={props?.dataToModal?.news_category_id === el.id ? true : false}>
                          {el?.name_al}
                        </option>
                      ))}
                    </select>
                  </div>
                  {props?.type !== 'edit' || props?.dataToModal?.video == null ? " " :  
                    <div className="form-group my-2">
                      <video  controls
                        className="w-100"
                        src={`${process.env.REACT_APP_UPLOADS + props?.dataToModal?.video}`}
                      >                   
                      </video>
                    </div> 
                   }
                    <div className="form-group my-2">
                      <label>Add video:</label><br/>
                      <input
                          type="file"
                          name="news_video"
                          onChange={video}
                      />
                    </div>
                  {props?.type === "add" && (
                    <SaveButtonModal type="submit" onClick={handleAddSubmmision}>
                      Ruaj
                    </SaveButtonModal>
                  )}
                  {props?.type === "edit" && (
                    <SaveButtonModal onClick={handleEditSubmmision} type="submit">
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>
              )}
              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={handleDelete}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};
export default NewsModal;
