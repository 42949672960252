import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const WorkoutsPlanModal = (props) => {
console.log("propss",props)
  let params = useParams()
  console.log('params', props)

  const [isdelete, setDelete] = useState(false);
  const [categories, setCategories] = useState([]);
  const [dataToSave, setDataToSave] = useState({
    id: props?.dataToModal?.id,
    fitness_plan_id: params?.id,
    trainerId: props?.trainerId
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [videos, setVideos] = useState(null);
  const [images, setImages] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectedImage, setSelectedImage] = useState();


  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      props?.handleModalClose();
    }
  });

  // const getVideoImage = () => {
  //   axios.get(`/plan_workouts/allVideos/${props?.trainerId}`)
  //     .then((res) => {
  //       setVideos(res?.data?.videos)
  //       setImages(res?.data?.images)

  //     })
  //     .catch((err) => { console.log('plan_workouts', err) })
  // };

  const getVideoImage = () => {
    axios.get(`/remove-files/allVideos/${props?.trainerId}`)
      .then((res) => {
        console.log("res",res.data)
        setVideos(res?.data?.videos)
      })
      .catch((err) => { console.log('plan_workouts', err) })
  };
  const getImages = () => {
    axios.get(`/remove-files/allPhotos/${props?.trainerId}`)
      .then((res) => {
        console.log("res",res.data)
        setImages(res?.data?.photos)
      })
      .catch((err) => { console.log('plan_workouts', err) })
  };

  const handleChange = (e) => {
    setDataToSave({
      ...dataToSave,
      [e?.target?.name]: e?.target?.value,
    })
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  const handleGetsetCategories = () => {
    axios.get(`/workout-categorisation/all`)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log('categorisation', err)
      })
  };


  const handleSubmitFitnesPlan = (e) => {
    e.preventDefault()

    // if (!selectedFile) {
    //   alert('Please select a file');
    //   return;
    // }

    const formData = new FormData();
    Object.keys(dataToSave).forEach((key) => {
      formData.append(key, dataToSave[key]);
    });

    formData.append('workout_plan_tutorial', selectedFile);
    formData.append('selectedVideo', selectedVideo);
    formData.append('selectedImage', selectedImage);


    axios.post('/plan_workouts', formData)
      .then((res) => {
        console.log('formData', formData)
        toast.success('Workout Plan added succesfully!')
        props?.handleModalClose();
        props?.handleGetFitnesPlans();
      })
      .catch((err) => {
        console.log('categorisation', err)
      })
  };


  const handleEditFitnesPlan = (e) => {
    e.preventDefault();

    const formData = new FormData();
    Object.keys(dataToSave).forEach((key) => {
      formData.append(key, dataToSave[key]);
    });

    formData.append('workout_plan_tutorial', selectedFile);
    formData.append('selectedVideo', selectedVideo);
    formData.append('selectedImage', selectedImage);
    // formData.append('workout_plan_tutorial', selectedFile);

    // formData.append('old_video', props?.dataToModal?.video);

    // if (selectedFile) {
    //   formData.append('new_workout_plan_tutorial', selectedFile);
    // }


    axios.put(`/plan_workouts`, formData, {
      method: 'PUT', // Specify the method as PUT
    })
      .then((res) => {
        console.log('formData', formData);
        toast.success('Workout Plan updated successfully!');
        props?.handleModalClose();
        props?.handleGetFitnesPlans();
      })
      .catch((err) => {
        console.log('categorization', err);
      });
  };


  const deleteECommerceItem = () => {
    axios.delete(`/plan_workouts/${props?.dataToModal?.id}`).then(data => {
      toast.success('Të dhënat e produktit janë fshirë me sukses!');
      props?.handleModalClose();
      props?.handleGetFitnesPlans();
    }).catch(err => {
      console.log(err);
      toast.success('Të dhënat e produktit nuk janë fshirë me sukses!');
    })
  }
  // const handleFormSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!selectedFile) {
  //     alert('Please select a file');
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('videoFile', selectedFile);
  //   console.log('formData', formData)
  //   try {
  //     const response = await axios.post('/upload-video', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     console.log('File uploaded successfully', response.data);
  //   } catch (error) {
  //     console.error('Error uploading file', error);
  //   }
  // };

  const image = (e) => {
    const ud = Object.assign({}, dataToSave);
    ud.plan_workouts = e.target.files[0];
    setDataToSave(ud);

    if (ud.plan_workouts) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(ud.plan_workouts);
    } else {
      setImagePreview(null);
    }

    console.log("image data", ud);
  };

          const handleDeleteVideo = (data ,e) => {
            e.preventDefault();
          let dataToSend = {
              fileName: data
          }
          axios.post(`/remove-files`, dataToSend)
            .then(data => {
              toast.success('Të dhënat  janë përditësuar me sukses!');
              // props?.handleGetFitnesPlans();
              getVideoImage();
              getImages();
              // props?.handleModalClose();
            }).catch(err => {
              console.log(err);
              toast.error('Të dhënat nuk janë përditësuar! / Video apo Foto është fshir apo e ke fshir');
            })
          
        };  

  useEffect(() => {
    if (props?.isOpen) {
      setDelete(false);
    }
  }, [props?.isOpen]);

  useEffect(() => {
    handleGetsetCategories();
    getVideoImage();
    getImages();
  }, []);

  const videoExists = videos?.some(videoObj => videoObj.video === props?.dataToModal?.video);
  const photoExists = images?.some(photoObj => photoObj.photo === props?.dataToModal?.image_url);

  console.log("selectedFile", videos, images)
  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>
              <ModalActionButtonWrapper>
                {props?.type !== "add" ? (
                  <>
                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons
                      close
                      onClick={() => props?.handleModalClose()}
                    >
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                ) : (
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                )}
              </ModalActionButtonWrapper>
              {isdelete === false && (
                <Form onSubmit={(e) => {
                  if (props?.type === 'add') {
                    e.preventDefault();
                    handleSubmitFitnesPlan(e);
                  } else {

                    handleEditFitnesPlan(e);
                  }
                }}>
                  <ModalTitle title>{props?.type == "add" ? "Add Workout Plan" : "Edit Workout Plan"}</ModalTitle >

                  <div className="form-group">
                    <label for="exerciseName">Exercise Name Al:</label>
                    <input
                      type="text"
                      id="exerciseName"
                      name="name_al"
                      required
                      className="form-control"
                      placeholder="Exercise Name"
                      defaultValue={props?.dataToModal?.name_al}
                      onChange={handleChange}

                    />
                  </div>
                  <div className="form-group">
                    <label for="exerciseName">Exercise Name EN:</label>
                    <input
                      type="text"
                      id="exerciseName"
                      name="name_en"
                      required
                      className="form-control"
                      placeholder="Exercise Name"
                      defaultValue={props?.dataToModal?.name_en}
                      onChange={handleChange}

                    />
                  </div>

                  <div className="form-group">
                    <label for="exerciseCategory">Exercise Category:</label>
                    <select
                      required
                      id="exerciseCategory"
                      name="workout_category"
                      className="form-control"
                      onChange={handleChange}
                    >



                      <option value="" selected disabled>Select a category </option>
                      {categories?.map(el =>
                        <option selected={props?.dataToModal?.category?.id === el.id ? true : null}
                          value={el?.id}>{el?.name_al}/ {el?.name_en}</option>
                      )}
                      {/* <option value="2">Category 222222</option> */}
                      {/* <option value="1">Category 3</option> */}
                    </select>
                  </div>
                  {/* {console.log("exterciseLevels", exterciseLevels)}
                  <div className="form-group">
                    <label>Exercise Level:</label>
                    <div className="d-flex  align-items-center">
                      {console.log('data to modal', props?.dataToModal)}
                      {exterciseLevels?.map(el =>
                        <div className="form-check me-4">
                          <input
                            type="checkbox"
                            id={el?.id}
                            name="level"
                            value={el?.id}
                            className=" form-check-input"
                            // defaultValue={props?.dataToModal}
                        
                          />
                          <label className="form-check-label" for={el?.id}>
                            {el?.name_al}
                          </label>
                        </div>
                      )} 
                       <div className="form-check me-4">
                        <input
                          type="checkbox"
                          id="intermediate"
                          name="level"
                          value="intermediate"
                          className="form-check-input"
                         
                        />
                        <label className="form-check-label" for="intermediate">
                          Intermediate
                        </label>
                      </div>
                      <div className="form-check me-4">
                        <input
                          type="checkbox"
                          id="advanced"
                          name="level"
                          value="advanced"
                          className="form-check-input"
                         
                        />
                        <label className="form-check-label" for="advanced">
                          Advanced
                        </label>
                      </div> 
                    </div>
                  </div> */}
                  {!selectedFile
                    ?

                    <div class="dropdown text-center mt-3">
                      {videos ?
                        <button class="btn btn-dark dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          Select Existing Video

                        </button>
                        : ""
                      }
                      <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1" style={{ maxHeight: '250px', overflowY: 'auto', overflowX: 'hidden' }}>
                        {videos?.map((el) => {
                          return (
                            <>
                              <li >
                                <div className="row align-items-center">
                                  {/* <div className="col-md-6 ms-2">

                                    <video controls
                                      style={{ width: '50%' }}
                                      src={`${process.env.REACT_APP_UPLOADS + el.video}`}
                                    >
                                    </video>
                                  </div> */}
                                  <div className="col-md-6 ms-4 text-start" style={{ cursor: "pointer" }} onClick={() => setSelectedVideo(el.video)}>
                                    {/* {console.log("emri videos", el?.video?.split('/')[3]?.split('___')[0])} */}
                                     <h4 className="">Select Video</h4>
                                    <h4 className="text-start btn btn-outline-primary"><i className="fa fa-arrow-right pe-2"></i>{el?.video?.split('/')[3]?.split('___')[0]}</h4>
                                  </div>
                                </div>
                              </li>
                            </>
                          )
                        })}
                      </ul>
                    </div>
                    : ""
                  }
                  {/* {videos?.map((el)=>{
                          return(
                            <>
                               <div className="form-group my-2">
                        <video controls
                          style={{ width: '25px' }}
                          src={`${process.env.REACT_APP_UPLOADS + el.video}`}
                        >
                        </video>
                      </div>
                            </>
                          )
                        })} */}
                  <div className="form-group mt-3">
                    {props?.type !== 'edit' ? " "
                      :
                      !selectedVideo ?
                        <div className="form-group my-2">
                           {videoExists && (
                            <video controls style={{ width: '300px' }} src={`${process.env.REACT_APP_UPLOADS + props?.dataToModal?.video}`}>
                            </video>
                          )}
                        </div> : ""
                    }
                    {!selectedVideo
                      ?
                      <>
                        <p>Upload Video:</p>
                        <input
                          type="file"
                          id="videoFile"
                          name="workout_plan_tutorial"
                          accept=".mp4, .avi, .mkv, .mov, .wmv"
                          onChange={handleFileChange}
                        />
                     
                      </>
                      :
                      <>
                        <div className="form-group my-2">
                          <video controls
                            style={{ width: '300px' }}
                            src={`${process.env.REACT_APP_UPLOADS + selectedVideo}`}
                          >
                          </video>
                        </div>
                      </>
                    }
                  </div>
         

                  {!dataToSave.plan_workouts
                    ?
                    <div class="dropdown text-center mt-3">
                      {images
                        ?
                        <button class="btn btn-dark dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          Select Existing Photo
                        </button>
                        : ""}
                      <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1" style={{ maxHeight: '250px', overflowY: 'auto', overflowX: 'hidden' }}>
                        {images?.map((el) => {
                          return (
                            <>
                              <li >
                                <div className="row align-items-center">
                                  {/* <div className="col-md-3 ms-3">
                                    <img className="my-1" src={`${process.env.REACT_APP_UPLOADS + el?.image}`} style={{ width: '50px' }} />
                                  </div> */}
                                  <div className="col-md-8 ms-3" style={{ cursor: "pointer" }} onClick={() => setSelectedImage(el?.photo)}>
                                    <h4 className="text-start">Select Photo</h4>
                                    <h4 className="text-start btn btn-outline-primary"><i className="fa fa-arrow-right pe-2"></i>{el?.photo?.split('/')[3]?.split('___')[0]}</h4>
                                  </div>
                                </div>
                              </li>
                            </>
                          )
                        })}
                      </ul>
                    </div>
                    : ""
                  }

                  {selectedImage && (
                    <div>
                      <img src={`${process.env.REACT_APP_UPLOADS + selectedImage}`} className="mt-2" alt="Preview" style={{ maxWidth: '100px', maxHeight: '200px' }} />
                    </div>
                  )}
                  <div className="form-group mb-3">
                    {imagePreview && (
                      <div>
                        <img className="mt-2" src={imagePreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                      </div>
                    )}

                    {!imagePreview && props?.type === "edit" && !selectedImage &&
                      <div>
                         {photoExists && (
                          <img className="mt-2" src={`${process.env.REACT_APP_UPLOADS + props?.dataToModal?.image_url}`} style={{ maxWidth: '100%', maxHeight: '200px' }} />
                          )}
                      </div>

                    }

                  </div>
                  {!selectedImage
                    ?
                    <div className="form-group mb-3">
                      <p>Add Image:</p>

                      <input
                        type="file"
                        name="image_url"
                        onChange={image}
                      />
                    </div>
                    : ""}
                    {props?.type === "edit"
                  ?
                  <>
                  <hr />
                  <h5 className='text-warning text-center'>Some files are being used by the system. Please be cautious!</h5>
                  <div className="row">

                  <div className="col-md-6">
                          <button className='btn btn-outline-danger w-100' onClick={(e) => handleDeleteVideo(props?.dataToModal?.video,e)}>Delete Video From System</button>
                        </div>
                  <div className="col-md-6">
                          <button className='btn btn-outline-danger w-100' onClick={(e) => handleDeleteVideo(props?.dataToModal?.image_url,e)}>Delete Photo From System</button>
                   </div>
                  </div>
                        <hr />
                  </>
                : ""  
                }
                  {/* <button onClick={(e) => handleFormSubmit(e)} type="button">  test</button> */}
                  {props?.type === "add" && (
                    <SaveButtonModal type="submit">Ruaj</SaveButtonModal>
                  )}

                  {props?.type === "edit" && (
                    <SaveButtonModal
                      type="submit"
                    >
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>
              )}
              {isdelete && (
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>
                      Deshironi te fshini te dhenat?
                    </ModalTitle>
                    <DeleteButtonModal onClick={deleteECommerceItem}>
                      Fshij
                    </DeleteButtonModal>
                  </DeleteMessage>
                </>
              )}
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default WorkoutsPlanModal;
