import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import { toast } from "react-toastify";
import axios from "../../axios"
import sign from "jwt-encode";
import ECommerceVariations from "./eCommerceVariations";
import AddNewTermVariation from "./addNewTermVariation";
import EditNewVariation from "./editNewVariation";
import DeleteItemsModal from "./deleteItemsModal";

const ECommerceEditVariations = (props) => {
  console.log("pointer-icon", props)
  const [isdelete, setDelete] = useState(false);
  const [productVariationData, setProductVariationData] = useState([]);
  const [productVariationDataNewGenerated, setProductVariationDataNewGenerated] = useState([]);
  const [dataToEdit, setDataToEdit] = useState();
  const [editButton, setEditButton] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAttribute, setIsOpenAttribute] = useState(false);
  const [numberIndex, setNumberIndex] = useState();
  const [parentItems, setParentItems] = useState([]);
  const [parentItemsattribute, setParentItemsAttribute] = useState([]);
  const [itemGroupData, setItemGroupData] = useState([]);
  const [variationData, setVariationData] = useState([]);
  const [deleteVariationData, setDeleteVariationData] = useState([]);
  const [attributeParent, setAtrributeParent] = useState();
  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [deleteDataToModal, setDeleteDataToModal] = useState();
  const [modalType, setModalType] = useState();


  const getVariationData = () => {
    axios.get(`/ecommerce/productVariationData/${props?.dataToModal?.id}`).then(data => {
      setProductVariationData(data.data);

    }).catch(err => {
      console.log(err)
    })
  }
  const getAttributesItems = () => {
    axios.get(`/ecommerce/single/${props?.dataToModal?.id}`).then(data => {
      setAtrributeParent(data?.data?.productParentVariation)
    }).catch(err => {
      console.log(err)
    })
  }


  const handleModalDelete = (data, type) => {
    setDeleteDataToModal(data)
    setModalType(type)
    setIsDeleteItem(true);
  };





  useEffect(() => {
    getVariationData();
    getAttributesItems();
  }, [])




  const handleChangeGeneratedVariations = (e, index) => {

    setDataToEdit({
      ...dataToEdit,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeGeneratedVariationsNew = (e, index) => {
    setVariationData((prevData) => {
      const updatedVariations = [...prevData];
      updatedVariations[index] = {
        ...updatedVariations[index],
        [e.target.name]: e.target.value
      };
      return updatedVariations;
    });
  }

  const handleAllEditData = (e) => {
    e.preventDefault();
    let dataToSend = {
      product_id: props?.dataToModal?.id,
      newVariationData: variationData,
      itemGroupData: itemGroupData,
      deleteVariationData: deleteVariationData
    }

    axios.put(`/ecommerce-product-variations/update-variations`, dataToSend)
      .then(data => {
        getVariationData();
        setProductVariationDataNewGenerated([]);
        setVariationData([])
        setDeleteVariationData([])
        toast.success('Të dhënat e produktit janë shtuar me sukses!');
      })
      .catch(err => {
        toast.error('Të dhënat e produktit nuk janë shtuar!');
        console.log(err);
      });
    // console.log("dataToSend",dataToSend)

  }

  const handleEditSingleVariation = (e, data) => {
    e.preventDefault();
    console.log("dataToEdit", dataToEdit)
    if (!dataToEdit?.sku_variation) {
      toast.warning("Fusha Sku e variacionit është e pa përcaktuar!");
    } else if (!dataToEdit?.price_variation) {
      toast.warning("Fusha Cmimit te variacionit është e pa përcaktur!")
    } else if (!dataToEdit?.stock_variation) {
      toast.warning("Fusha e stokit te variacionit është e pa përcaktur!")
    }
    else {
      let dataSingleEdit = {
        id: data?.id,
        sku_variation: dataToEdit?.sku_variation,
        price_variation: dataToEdit?.price_variation,
        sale_price_variation: dataToEdit?.sale_price_variation,
        stock_variation: dataToEdit?.stock_variation,
        vat_variation: dataToEdit?.vat_variation
      }

      // console.log("dataSingleEdit",dataSingleEdit)
      axios.put('/ecommerce-product-variations', dataSingleEdit)
        .then(data => {
          setEditButton(null);
          // props?.handleModalClose();
          // props?.getData();
          toast.success('Të dhënat janë edituar me sukses!');
        }
        ).catch(err => {
          toast.error('Te dhënat nuk jane edituar!');
          console.log(err);
        });
    }
  }
  const receiveItemsFromChild = (childItems) => {
    // console.log("childItems", childItems);
    if (attributeParent) {
      attributeParent.forEach((variation) => {
        // console.log("variation", variation);
        const { e_commerce_variations_children } = variation;

        // Check if variation.id matches childItems.group_id
        if (variation.id === childItems.group_id) {
          e_commerce_variations_children.push({
            id: childItems.id,
            e_com_variation_id: childItems.group_id,
            name_al: childItems.name,
          });
          itemGroupData.push({
            name: childItems.name,
            group_id: childItems.group_id
          })
        }
      });
    }
  };



  //   console.log("itemGroupData",itemGroupData)

  const isCombinationInExisting = (combination, existingCombinations) => {
    return existingCombinations.some(existingCombo => {
      return (
        existingCombo?.firstVariationName?.id === combination?.firstVariationName?.id &&
        existingCombo?.secondVariationName?.id === combination?.secondVariationName?.id
      );
    });
  };
  const isCombinationInExistingSingle = (combination, existingCombinations) => {
    return existingCombinations.some(existingCombo => {
      return (
        existingCombo?.firstVariationName?.id === combination?.firstVariationName?.id
      );
    });
  };

  const handleGenerate = () => {
    const combinations = [];
    const groupedItems = {};
    const e_commerce_variations_children = attributeParent?.map(variation => variation.e_commerce_variations_children);
    if (Array.isArray(e_commerce_variations_children)) {
      e_commerce_variations_children.forEach(e_commerce_variations_child => {
        if (Array.isArray(e_commerce_variations_child)) {
          e_commerce_variations_child.forEach(item => {
            // console.log("HEj",item)
            if (!groupedItems[item.e_com_variation_id]) {
              groupedItems[item.e_com_variation_id] = [];
            }
            groupedItems[item.e_com_variation_id].push(item);
          });
        }
      });
    }
    //   console.log("groupedItems",groupedItems)
    parentItems.forEach(item => {
      if (!groupedItems[item.group_id]) {
        groupedItems[item.group_id] = [];
      }
      groupedItems[item.group_id].push(item);
    });


    const groupIds = Object.keys(groupedItems);
    const allHaveNullSecondVariationId = productVariationData.every(item => item.second_variation_id === null);
    if (allHaveNullSecondVariationId) {
      setDeleteVariationData(productVariationData);
    } else {

    }

    if (groupIds?.length < 2) {
      setVariationData([]);
      setDeleteVariationData([]);
      const newPostVariationDataSingle = [];
      const group1 = groupedItems[groupIds[0]];
      for (let k = 0; k < group1?.length; k++) {
        const group1 = groupedItems[groupIds[0]];
        const groupCombinations = [];
        for (let k = 0; k < group1?.length; k++) {
          const combination = {
            firstVariationName: group1[k]
          };
          if (!isCombinationInExistingSingle(combination, productVariationData)) {
            newPostVariationDataSingle.push({
              first_variation_id: group1[k]?.name_al
            });
            groupCombinations.push(combination);
            setVariationData(prevVariationData => [
              ...prevVariationData,
              {
                first_variation_id: group1[k]?.id
              }
            ]);
          }


          //   setVariationData([{
          //       first_variation_id:group1[k]?.name_al
          //   }]);
        }



        setProductVariationDataNewGenerated(groupCombinations);
        // console.log("combo",groupCombinations)
        // console.log("variationData", variationData);
        return;
      }
    }
    const existingCombinations = productVariationData.map(data => ({
      firstVariationName: data.firstVariationName,
      secondVariationName: data.secondVariationName,
    }));

    //   console.log("existingCombinations",existingCombinations)
    const newPostVariationData = [];
    setVariationData([]);
    for (let i = 0; i < groupIds?.length; i++) {
      for (let j = i + 1; j < groupIds?.length; j++) {
        const group1 = groupedItems[groupIds[i]];
        const group2 = groupedItems[groupIds[j]];

        for (let k = 0; k < group1?.length; k++) {
          for (let l = 0; l < group2?.length; l++) {

            const combination = {
              firstVariationName: group1[k],
              secondVariationName: group2[l],
            };


            if (!isCombinationInExisting(combination, existingCombinations)) {
              newPostVariationData.push({
                first_variation_id: group1[k].name_al,
                second_variation_id: group2[l].name_al,
              });
              // console.log("HEJJJJCORE",group1[k])
              combinations.push(combination);
              setVariationData(prevVariationData => [
                ...prevVariationData,
                {
                  first_variation_id: group1[k].id,
                  second_variation_id: group2[l].id
                }
              ]);
            }
          }
        }
      }
      // console.log("combinations", combinations)
    }
    // setPostVariationData(newPostVariationData);
    setProductVariationDataNewGenerated(combinations);
  };

  // console.log("productVariationData",productVariationData)
  // console.log("productVariationDataNewGenerated",productVariationDataNewGenerated)
  // console.log("variationData",variationData)

  // console.log("attributeParent",attributeParent?.length)

  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => {
              props?.handleModalClose();
              setProductVariationDataNewGenerated(null);
              setVariationData(null)
              setDeleteVariationData(null)
            }} />
            <Mymodal_wrapper>

              <ModalActionButtonWrapper>
                <>
                  <ModalButtons close onClick={() => {
                    props?.handleModalClose()
                    setProductVariationDataNewGenerated(null);
                    setVariationData(null)
                    setDeleteVariationData(null)
                  }}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                </>

              </ModalActionButtonWrapper>



              <Form onSubmit={handleAllEditData}>
                <ModalTitle title>Variation Data ( {props?.dataToModal?.title_al} )</ModalTitle>


                <div className="nextStep2">


                  <div className="mb-5">
                    {attributeParent?.length < 2 || attributeParent === undefined ?
                      <button type="button" className="btn btn-success"
                        onClick={() => setIsOpenAttribute(true)}
                      >
                        Add new Attribute Group
                      </button>
                      :
                      <div className="bg-warning p-3">
                        <h4>2 Attribute janë krijuar.</h4>
                      </div>
                    }
                    {isOpenAttribute ?
                      <>
                        <EditNewVariation
                          isOpen={isOpenAttribute}
                          // sendItemsToParent={receiveItemsFromChildAttribute}
                          getData={() => getAttributesItems()}
                          type="attributeGroup"
                          name="Attribute"
                          product_id={props?.dataToModal?.id}
                          // initialItems={attributeParent}
                          handleModalClose={() => { setIsOpenAttribute(false); }
                          }
                        />
                      </>
                      :
                      ""
                    }
                  </div>


                  <>
                    <div className="row">
                      {attributeParent?.map((el, index) => (
                        <>

                          <div className="col-md-6">


                            <div className="card p-1 text-center">
                              <h6>Attribute Group name: {el?.name_al}</h6>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <button type="button" className="btn btn-secondary w-50"
                              onClick={() => { setIsOpen(true); setNumberIndex(el?.id) }}
                            >Add new term</button>
                            <button type="button" className="btn btn-danger w-50"
                              onClick={(e) => handleModalDelete(el, "parent")}
                            >Delete Group</button>

                            <div className="text-end mt-3">


                              {el?.e_commerce_variations_children?.map((innerEl, innerIndex) => (
                                <>
                                  {innerEl?.group_id === el?.index
                                    ?
                                    <div className="d-flex justify-content-between my-1">
                                      <h5 key={index}>{innerEl?.name_al}</h5>
                                      <div className="buttons">
                                        {/* <button className="btn btn-warning"><i className="fa fa-edit"></i></button> */}
                                        <button type="button" className="btn btn-danger"
                                          onClick={(e) => handleModalDelete(innerEl, "child")}
                                        ><i className="fa fa-trash"></i></button>
                                      </div>
                                    </div>
                                    : ""}
                                </>
                              ))
                              }
                            </div>
                          </div>

                        </>
                      ))
                      }
                      {isOpen ?
                        <>
                          <AddNewTermVariation
                            isOpen={isOpen}
                            number={numberIndex}
                            // sendItemsToParent={receiveItemsFromChild} 
                            initialItems={parentItems}
                            getData={() => getAttributesItems()}
                            name="Term"
                            handleModalClose={() => { setIsOpen(); }
                            }
                          />
                        </>
                        :
                        ""
                      }

                    </div>

                  </>

                </div>

                <div className="mt-4">
                  <button type="button" className="btn btn-secondary w-100"
                    onClick={() => handleGenerate()}
                  >Generate Variations</button>
                </div>
                <hr />
                <div className="  p-1 mt-2 ">
                  <div className="row align-items-center ">
                    <h6 className="col ">Var.1 </h6>
                    <h6 className="col "> Var.2</h6>
                    <h6 className="col-md-2">SKU</h6>
                    <h6 className="col">Price</h6>
                    <h6 className="col">Sales Price</h6>
                    <h6 className="col">Vat</h6>
                    <h6 className="col">Stock</h6>
                    <h6 className="col">Action</h6>

                  </div>
                </div>
                {/* <hr /> */}

                {productVariationData?.length !== 0
                  ?
                  productVariationData?.map((el, index) => {
                    return (
                      <div className="card p-1 my-3">
                        <div className="row  align-items-center">
                          <div className="col">
                            <h6>{el?.firstVariationName?.name_al}</h6>
                          </div>
                          <div className="col">

                            <h6>{el?.secondVariationName?.name_al}</h6>
                          </div>
                          <div className="col-md-2">
                            <input
                              required
                              id="name"
                              name="sku_variation"
                              type="text"
                              placeholder="SKU"
                              className="form-control"
                              disabled={editButton !== el?.id ? true : false}
                              onChange={(e) => handleChangeGeneratedVariations(e, index)}
                              defaultValue={el?.sku_variation}
                            />
                          </div>
                          <div className="col">
                            <input
                              required
                              id="name"
                              name="price_variation"
                              type="number"
                              placeholder="Price"
                              className="form-control"
                              disabled={editButton !== el?.id ? true : false}
                              defaultValue={el?.price_variation}
                              onChange={(e) => handleChangeGeneratedVariations(e, index)}
                            // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                            />
                          </div>
                          <div className="col">
                            <input
                              required
                              id="name"
                              name="sale_price_variation"
                              type="number"
                              placeholder="Sales Price"
                              className="form-control"
                              defaultValue={el?.sale_price_variation}
                              disabled={editButton !== el?.id ? true : false}
                              onChange={(e) => handleChangeGeneratedVariations(e, index)}
                            // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                            />
                          </div>
                          <div className="col">
                            <input
                              required
                              id="name"
                              name="vat_variation"
                              type="number"
                              placeholder="Vat"
                              className="form-control"
                              defaultValue={el?.vat_variation}
                              disabled={editButton !== el?.id ? true : false}
                              onChange={(e) => handleChangeGeneratedVariations(e, index)}
                            // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                            />
                          </div>
                          <div className="col">
                            <input
                              required
                              id="name"
                              name="stock_variation"
                              type="number"
                              placeholder="Stock"
                              className="form-control"
                              defaultValue={el?.stock_variation}
                              disabled={editButton !== el?.id ? true : false}
                              onChange={(e) => handleChangeGeneratedVariations(e, index)}
                            // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                            />
                          </div>
                          <div className="col">
                            {editButton === el.id ?
                              <button type="button" className="btn btn-success" onClick={(e) => handleEditSingleVariation(e, el)}><i className="fa fa-save" /></button>
                              :
                              <button type="button" className="btn btn-warning" onClick={(e) => { setEditButton(el.id); setDataToEdit(el) }}><i className="fa fa-edit" /></button>
                            }
                          </div>
                        </div>
                      </div>
                    )

                  })
                  : <>
                    <p>No Variation Data. Please Generate Variations</p>
                  </>
                }
                <hr />
                <div className="mt-5">
                  {productVariationDataNewGenerated?.length !== 0 ?
                    <p>New Data</p>
                    : ""}
                  {
                    productVariationDataNewGenerated?.map((el, index) => {
                      return (
                        <div className="card p-1 my-3">
                          <div className="row  align-items-center">
                            <div className="col">

                              <h6>{el?.firstVariationName?.name_al}</h6>
                            </div>
                            <div className="col">

                              <h6>{el?.secondVariationName?.name_al}</h6>
                            </div>
                            <div className="col-md-2">
                              <input
                                required
                                id="name"
                                name="sku_variation"
                                type="text"
                                placeholder="SKU"
                                className="form-control"
                                // disabled={editButton !== el?.id ? true : false}
                                onChange={(e) => handleChangeGeneratedVariationsNew(e, index)}
                                defaultValue={el?.sku_variation}
                              />
                            </div>
                            <div className="col">
                              <input
                                required
                                id="name"
                                name="price_variation"
                                type="number"
                                placeholder="Price"
                                className="form-control"
                                // disabled={editButton !== el?.id ? true : false}
                                defaultValue={el?.price_variation}
                                onChange={(e) => handleChangeGeneratedVariationsNew(e, index)}
                              // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                              />
                            </div>
                            <div className="col">
                              <input
                                required
                                id="name"
                                name="sale_price_variation"
                                type="number"
                                placeholder="Sales Price"
                                className="form-control"
                                defaultValue={el?.sale_price_variation}
                                // disabled={editButton !== el?.id ? true : false}
                                onChange={(e) => handleChangeGeneratedVariationsNew(e, index)}
                              // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                              />
                            </div>
                            <div className="col">
                              <input
                                required
                                id="name"
                                name="vat_variation"
                                type="number"
                                placeholder="Vat"
                                className="form-control"
                                defaultValue={el?.vat_variation}
                                // disabled={editButton !== el?.id ? true : false}
                                onChange={(e) => handleChangeGeneratedVariationsNew(e, index)}
                              // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                              />
                            </div>
                            <div className="col">
                              <input
                                required
                                id="name"
                                name="stock_variation"
                                type="number"
                                placeholder="Stock"
                                className="form-control"
                                defaultValue={el?.stock_variation}
                                // disabled={editButton !== el?.id ? true : false}
                                onChange={(e) => handleChangeGeneratedVariationsNew(e, index)}
                              // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                              />
                            </div>

                          </div>
                        </div>
                      )

                    })}
                </div>
                {productVariationDataNewGenerated?.length !== 0 ?
                  <button className="btn btn-success w-100" type="submit">
                    Save
                  </button>
                  : ""
                }
              </Form>



            </Mymodal_wrapper>
          </ModalStyle>
          {isDeleteItem === true
            ?
            <DeleteItemsModal
              data={deleteDataToModal}
              type={modalType}
              isOpen={isDeleteItem}
              getVariationData={() => { getVariationData(); getAttributesItems(); setProductVariationDataNewGenerated([]); setVariationData([]); setDeleteVariationData([]) }}
              handleModalClose={() => { setIsDeleteItem(false) }} />
            : ""
          }
        </ModalPortal>

      </>
    );
  } else {
    return null;
  }
};

export default ECommerceEditVariations;
