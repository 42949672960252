import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
    ModalActionButtonWrapper,
    ModalStyle,
    Mymodal_wrapper,
    ModalButtons,
    Form,
    ModalTitle,
    SaveButtonModal,
    DeleteButtonModal,
    DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';


const NewSetModal = (props) => {
    let params = useParams()
    const totalWeeks = props?.weekTotal || 12;
    const initialWeekArray = Array?.from({ length: totalWeeks }, (_, index) => index + 1);
    const [isdelete, setDelete] = useState(false);
    const [workoutSetData, setWorkoutSetData] = useState({
        name: props?.dataToModal?.name || null,
        plan_workout_id: params?.id,
        uom: props?.dataToModal?.unit?.id || null,
        value: props?.dataToModal?.value || null
    });
    const [UoM, setUoM] = useState([]);
    const [week_id, setWeekId] = useState(initialWeekArray);
    const [isSubmitting, setIsSubmitting] = useState(false);

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            props?.handleModalClose()
        }
    })
    function getUoM() {
        axios.get("/set-settings/all")
            .then(res => setUoM(res.data))
            .catch(err => {
                toast.error(err);
                console.log("Error fetching UoM : ", err)
            })
    }

    const handleDataInput = (e) => {
        setWorkoutSetData({
            ...workoutSetData,
            [e.target.name]: e.target.value,
        })
    }

    const handleWeekInput = (event) => {
        setWorkoutSetData({
            ...workoutSetData,
            "week_id": event,
        })
    }

    function handleWorkoutSetSubmit(e) {
        e.preventDefault();

         // Check if already submitting
         if (isSubmitting) {
            return; // If already submitting, do nothing
        }
    
        // Check if week_id is selected
        if (!workoutSetData.week_id) {
            toast.error('Please select a week.');
            return;
        } else {
            setIsSubmitting(true);
            // Proceed with the API request if week_id is selected
            axios.post("/workouts-sets", workoutSetData)
                .then(res => {
                    toast.success('Successfully created workout set!');
                    props?.handleModalClose();
                    props?.handleGetWorkoutSets();
                    console.log('Success post ', res);
                })
                .catch(err => {
                    toast.error('Error creating workout set.');
                    console.log("Error post ", err);
                })
                .finally(() => {
                    // Reset submitting flag after request is complete
                    setIsSubmitting(false);
                });
        }
    }

    function handleWorkoutSetEdit(e) {
        e.preventDefault()
        axios.put("/workouts-sets", {
            ...workoutSetData,
            id: props?.dataToModal?.id,
        })
            .then(res => {
                toast.success('Sucessfully edited workout set!');
                props?.handleModalClose();
                props?.handleGetWorkoutSets();
                console.log('sukses post ', res)
            })
            .catch(err => { toast.error('error'); console.log("error post ", err) })
    }
    function handleDelete(id) {

        axios.delete(`/workouts-sets/${id}`)
            .then(res => {
                toast.success('Sucessfully deleted workout set!');
                props?.handleModalClose();
                props?.handleGetWorkoutSets();
                console.log('sukses post ', res)
            })
            .catch(err => { toast.error('error'); console.log("error post ", err) })
    }
    useEffect(() => {
        if (props?.isOpen) {
            getUoM();
            setDelete(false);
        }
    }, [props?.isOpen]);
    console.log("modal edit workout set props", props)

    console.log("workoutSetData",workoutSetData)

    if (props?.isOpen) {
        return (
            <>
                <ModalPortal>
                    <ModalStyle>
                        <div className="wholePage" onClick={() => props?.handleModalClose()} />
                        <Mymodal_wrapper>

                            <ModalActionButtonWrapper>
                                {props?.type !== "add" ?
                                    <>

                                        <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                                            <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                                        </ModalButtons>

                                        <ModalButtons close onClick={() => props?.handleModalClose()}>
                                            <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                                        </ModalButtons>
                                    </>
                                    :
                                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                                        <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                                    </ModalButtons>
                                }
                            </ModalActionButtonWrapper>


                            {isdelete === false && (
                                <Form onSubmit={(e) => handleWorkoutSetSubmit(e)}>
                                    <ModalTitle title>Add new Workout Set</ModalTitle>
                                    <div className="form-group">
                                        <label for="exerciseName">Reps:</label>
                                        <input
                                            type="number"
                                            id="exerciseName"
                                            className="form-control"
                                            placeholder="Reps"
                                            required
                                            name="name"
                                            onChange={handleDataInput}
                                            defaultValue={props?.dataToModal?.name}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label for="exerciseCategory">Set Unit of Measurement:</label>
                                        <select
                                            id="exerciseCategory"
                                            className="form-control"
                                            required
                                            name="uom"
                                            onChange={handleDataInput}
                                            defaultValue={props?.type == 'add' ? null : props?.dataToModal?.unit?.id}
                                        >
                                            <option value="" disabled selected={!props?.dataToModal?.unit?.id} hidden>Please choose a unit</option>
                                            {/* <option selected={props?.dataToModal?.value?.unit?.id ? false : true} hidden value={null}>Please choose a unit</option> */}
                                            {/* {console.log("props?.dataToModal?.value?.unit?.id", props?.type)}
                                            {UoM?.map((el) => {
                                                return (
                                                    <option key={el?.id} value={el?.id}>{el?.name_al}</option>
                                                )
                                            })} */}

                                            <option value={1}>Pesha </option>
                                            <option value={8}>Kohezgjatja </option>
                                            <option value={21}>Dicka </option>

                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label for="valueOfUoM">Set value:</label>
                                        <input
                                            type="text"
                                            id="valueOfUoM"
                                            onChange={handleDataInput}
                                            name="value"
                                            required
                                            className="form-control"
                                            defaultValue={props?.dataToModal?.value}
                                            placeholder="Value of Unit of Measurement"
                                        />
                                    </div>

                                
                                    {props?.type !== "edit" 
                                    ?
                                    <div className="form-group">
                                        <label for="valueOfUoM">Set Weeks:</label>
                                        <Multiselect
                                          isObject={false}
                                        //   required
                                          onRemove={(event)=>handleWeekInput(event)}
                                          onSelect={(event)=>handleWeekInput(event)}
                                          options={week_id}
                                            />
                                            
                                    </div>
                                    : 
                                            <div className="form-group">
                                            <label for="valueOfUoM">Set Week:</label>
                                            <select className="form-select"
                                            onChange={handleDataInput}
                                            required
                                            name="week_id"
                                            id="exampleFormControlSelectWeek"
                                            defaultValue={props?.type === "edit" ? props?.dataToModal?.week_id : null}
                                            
                                            >
                                            <option value=""  selected disabled>Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>

                                            </select>
                                        </div>
                                    }

                                    <div className="form-group">
                                        <label for="valueOfUoM">Set Day:</label>
                                        <select className="form-select"
                                        onChange={handleDataInput}
                                        required
                                        name="day_id"
                                        id="exampleFormControlSelectDay"
                                        defaultValue={props?.type === "edit" ? props?.dataToModal?.day_id : null}
                                        >
                                        <option value=""  selected disabled>Select</option>
                                        <option value="0">1</option>
                                        <option value="1">2</option>
                                        <option value="2">3</option>
                                        <option value="3">4</option>
                                        <option value="4">5</option>
                                        <option value="5">6</option>
                                        </select>
                                    </div>
                                    {props?.type !== "edit"
                                    ?
                                    <>
                                    <hr />
                                    <p className="text-danger">If u want this record to repeat please type the number u want</p>
                                    <div className="form-group">
                                        <label for="multiplyName">Multiply:</label>
                                        <input
                                            type="number"
                                            id="multiplyName"
                                            className="form-control"
                                            placeholder="Number"
                                            max={10}
                                            // required
                                            name="multiply"
                                            onChange={handleDataInput}
                                            // defaultValue={props?.dataToModal?.name}
                                            />
                                    </div>
                                            </>
                                    : ""
                                    }





                                    {props?.type === "add" && (
                                        <SaveButtonModal type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? 'Load...' : 'Ruaj'}
                                        </SaveButtonModal>
                                    )}

                                    {props?.type === "edit" && (
                                        <SaveButtonModal onClick={(e) => { handleWorkoutSetEdit(e) }}>
                                            Ndrysho
                                        </SaveButtonModal>
                                    )}
                                </Form>


                            )}
                            {isdelete &&
                                <>
                                    <DeleteMessage>
                                        <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                                        <DeleteButtonModal onClick={(e) => { e.preventDefault(); handleDelete(props?.dataToModal?.id) }}>Fshij</DeleteButtonModal>
                                    </DeleteMessage>
                                </>
                            }
                        </Mymodal_wrapper>
                    </ModalStyle>
                </ModalPortal>
            </>
        );
    } else {
        return null;
    }
};

export default NewSetModal;
