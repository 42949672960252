import React, { useEffect, useState } from "react";

const ECommerceVariations = (props) => {
 console.log('shaban', props)
    const [items, setItems] = useState(props?.initialItems);
    const [text, setText] = useState('');
  

    useEffect(() => {
        setItems(props?.initialItems); 
      }, [props?.initialItems]);

    const addItem = () => {
        if (text) {
            const lastIndex = props?.initialItems.length > 0 ? props?.initialItems[props?.initialItems.length - 1].index : -1;
            const newIndex = lastIndex + 1;
            const newItem = { name: text, index: newIndex};
          setItems([...items, newItem]);
          setText('');
          props.sendItemsToParent([...items, newItem]);
        };
        // Rest of your component
        props?.handleModalClose()
      }
    
    // const deleteItem = (index) => {
    //   const updatedItems = items.filter((_, i) => i !== index);
    //   setItems(updatedItems);
    // };

    console.log("items",items)
  return (
    <div>



                <div className="card p-4 my-3">
                      <div className="d-flex align-items-center justify-content-between">

                      <h4>Add {props?.name}</h4>
                      <button type="button" class="btn-close" aria-label="Close" onClick={()=>props?.handleModalClose()}></button>
                      </div>
                      <label htmlFor="">Name:</label>
                      <input
                              required
                              id="name"
                              type="text"
                              placeholder="Name"
                              className="form-control"
                              value={text}
                              onChange={(e) => setText(e.target.value)}
                            />
                            <button className="btn btn-success mt-3" onClick={addItem}>Add</button>
                      </div> 
    </div>
  )
}

export default ECommerceVariations