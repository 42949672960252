import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";
import axios from "../../axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import sign from "jwt-encode";

const LanguagesModal = (props) => {
console.log("props", props)

  const [addLanguages, setAddLanguage] = useState({
    id: props?.dataToModal?.id,
  });



  const handleAddSubmmision = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(addLanguages).forEach((key) => {
      formData.append(key, addLanguages[key]);
    });
    e.preventDefault();
    axios.post('/languages', formData)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat janë ruajtur me sukses!');
      }
      ).catch(err => {
        toast.error('Të dhënat nuk janë ruajtur!');
        console.log(err);
      });
  };

  const handleEditSubmmision = (e) => {
    e.preventDefault()
    const formData = new FormData();
    Object.keys(addLanguages).forEach((key) => {
      formData.append(key, addLanguages[key])
    })
    if (addLanguages.newVideo) {
      formData.append('video', addLanguages.newVideo)
    }
    axios.put(`/languages`, formData)
      .then(data => {
        toast.success('Të dhënat  janë përditësuar me sukses!');
        props?.getData();
        props?.handleModalClose();
      }).catch(err => {
        console.log(err);
        toast.error('Të dhënat nuk janë përditësuar!');
      })
  }

  const handleChange = (e) => {
    setAddLanguage({
      ...addLanguages,
      [e.target.name]: e.target.value,
    })
  }
  const video = (e) => {
    const ud = Object.assign({}, addLanguages);
    ud.language_image = e.target.files[0];
    setAddLanguage(ud);
  };


  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>
              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className="fa fa-times" />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className="fa fa-times" />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>
                <Form>
                  <ModalTitle title>Add New Language </ModalTitle>
                  <div className="form-group">
                    <label>Emri:</label>
                    <input
                      required
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Loose weight"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.name : ""}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label>Add Prefix:</label>
                    <input
                      id="code"
                      name="code"
                      type="text"
                      placeholder="ex. AL "
                      className="form-control"
                      onChange={handleChange}
                      defaultValue={props?.type === "edit" ? props?.dataToModal?.code : ""}
                    />
                  </div>
                  {props?.type !== 'edit' || props?.dataToModal?.image_url == null ? " " :
                    <div className="form-group my-2">
                      <img 
                        className="w-25"
                        src={`${process.env.REACT_APP_UPLOADS + props?.dataToModal?.image_url}`}
                      >
                      </img>
                    </div>
                  }
                  <div className="form-group my-2">
                    <label>Edit Image:</label><br />
                    <input
                      type="file"
                      name="language_image"
                      onChange={video}
                    />
                  </div>
                  {props?.type === "add" && (
                    <SaveButtonModal type="submit" onClick={handleAddSubmmision}>
                      Ruaj
                    </SaveButtonModal>
                  )}
                  {props?.type === "edit" && (
                    <SaveButtonModal onClick={handleEditSubmmision} type="submit">
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};
export default LanguagesModal;
