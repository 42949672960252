import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";

import axios from "../../axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ECommerceVariations from "./eCommerceVariations";
import './eCommerceVariants.scss'
import sign from "jwt-encode";
import ECommerceTermVariations from "./eCommerceTermVariations";


const EcommerceModal = (props) => {
  // const [isEdit, setEdit] = useState(false);

  const [isdelete, setDelete] = useState(false);
  const [ecomItemCategories, setEComItemCategories] = useState();
  const [selectedCategory, setSelectedCategory] = useState(props?.item?.category || null);
  const [initialImage, setInitialImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [nextStep, setNextStep] = useState(1);
  const [attributeGroup, setAttributeGroup] = useState(false);
  const [colors, setColors] = useState(false);
  const [colorArray, setColorArray] = useState();
  const [colorName, setColorName] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [parentItems, setParentItems] = useState([]);
  const [parentItemsattribute, setParentItemsAttribute] = useState([]);
  const [numberIndex, setNumberIndex] = useState();
  const [generatedVariations, setGeneratedVariations] = useState();
  const [postVariationData, setPostVariationData] = useState([]);


  const [addEComItem, setAddEComItem] = useState({
    id: props?.dataToModal?.id,
  })

  console.log("parentItems", parentItems)

  const getCategoryData = () => {
    axios.get('eCom-product-cat/all').then(data => {
      setEComItemCategories(data.data);
    }).catch(err => {
      console.log('ecomItemCateErr', err)
    })
  }


  const [galleryData, setGalleryData] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);





  const handleGalleryImageUpload = (e) => {
    const files = e.target.files;
    const updatedGallery = [...galleryData];

    for (let i = 0; i < files.length; i++) {
      updatedGallery.push(files[i]);
    }

    setGalleryData(updatedGallery);
  }


  useEffect(() => {
    if (props.type === 'edit' && props.dataToModal?.e_commerce_images) {
      setExistingImages(props.dataToModal?.e_commerce_images);
    } else {
      setExistingImages([]);
    }
  }, [props.type, props.dataToModal?.e_commerce_images]);


  const addECommerceItem = (e) => {
    e.preventDefault();


    const formData = new FormData();

    Object.keys(addEComItem).forEach((key) => {
      if (key === 'has_variants' && addEComItem[key] === true) {
        formData.append("variationData", JSON.stringify(postVariationData))
        formData.append("has_variants", addEComItem[key])
      } else {
        formData.append(key, addEComItem[key])
      }
    })

    formData.append("groupData", JSON.stringify(parentItemsattribute))
    formData.append("itemGroupData", JSON.stringify(parentItems))
    for (const existingImage of existingImages) {
      formData.append('e_commerce_images', existingImage.image);
    }

    for (const galleryImg of galleryData) {
      formData.append('e_commerce_gallery', galleryImg);
    }

    e.preventDefault();

    axios.post(`/ecommerce`, formData)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e produktit janë shtuar me sukses!');

      })
      .catch(err => {
        toast.error('Të dhënat e produktit nuk janë shtuar!');
        console.log(err);
      });
  }



  const editECommerceItem = (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.keys(addEComItem).forEach((key) => {
      formData.append(key, addEComItem[key]);
    });

    if (existingImages.length > 0) {
      existingImages.forEach((existingImage) => {
        formData.append('deletedImages', existingImage.id);
      });
    }

    for (const galleryImg of galleryData) {
      formData.append('e_commerce_gallery', galleryImg);
    }


    axios.put(`/ecommerce`, formData)
      .then(data => {
        toast.success('Të dhënat e produktit janë përditësuar me sukses!');
        props?.getData();
        props?.handleModalClose();


      })
      .catch(err => {
        console.log(err);
        toast.error('Të dhënat e produktit nuk janë përditësuar!');
      });
  }


  const handleRemoveExistingImage = async (imageId) => {
    try {
      await axios.delete(`/ecommerce/images/${imageId}`);

      const updatedExistingImages = existingImages.filter(image => image.id !== imageId);
      setExistingImages(updatedExistingImages);

      // toast.success('Image removed successfully!');
    } catch (error) {
      console.error('Error removing image:', error);
      toast.error('Failed to remove image.');
    }
  };



  const deleteECommerceItem = () => {
    axios.delete(`/ecommerce/${props?.dataToModal?.id}`).then(data => {
      toast.success('Të dhënat e produktit janë fshirë me sukses!');
      props?.handleModalClose();
      props?.getData();
    }).catch(err => {
      console.log(err);
      toast.success('Të dhënat e produktit nuk janë fshirë me sukses!');
    })
  }

  const handleButtonClick = () => {
    if (
      !addEComItem.title_al ||
      !addEComItem.category ||
      !addEComItem.regular_price ||
      !addEComItem.stock
    ) {
      setButtonDisabled(false);

      let emptyField = '';
      if (!addEComItem.title_al) emptyField = 'Title';
      else if (!addEComItem.category) emptyField = 'Category';
      else if (!addEComItem.regular_price) emptyField = 'Regular Price';
      else if (!addEComItem.stock) emptyField = 'Stock';

      // Show a toast notification
      // toast.error(`Please fill in the ${emptyField} field.`);

    } else {
      setButtonDisabled(true);
    }
  };


  useEffect(() => {
    handleButtonClick()
  }, [addEComItem])



  const handleChange = (e) => {
    // if (addEComItem.title_al   && addEComItem.category   && addEComItem.regular_price   && addEComItem.stock   ) {
    //   setButtonDisabled(true)
    // }

    let secretKey = process.env.REACT_APP_SECRET;
    if (e.target.name == "password") {
      let encriptedPassword = sign(e.target.value, secretKey)
      setAddEComItem({
        ...addEComItem,
        [e.target.name]: encriptedPassword
      })
    }
    else if (e.target.name === "has_variants") {
      setAddEComItem({
        ...addEComItem,
        [e.target.name]: e.target.checked
      })
    }
    else {
      setAddEComItem({
        ...addEComItem,
        [e.target.name]: e.target.value
      })
    }
  }
  console.log("addItem", addEComItem)

  const handleChangeGeneratedVariations = (e, index) => {

    setPostVariationData((prevData) => {
      const updatedVariations = [...prevData];
      updatedVariations[index] = {
        ...updatedVariations[index],
        [e.target.name]: e.target.value
      };
      return updatedVariations;
    });
  }
  console.log("postVariationDatapostVariationData", postVariationData)




  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })



  useEffect(() => {
    getCategoryData();
  }, [])



  useEffect(() => {
    if (props?.isOpen) {
      setDelete(false);
    }
  }, [props?.isOpen]);


  const receiveItemsFromChild = (childItems) => {
    setParentItems(childItems);
  };
  const receiveItemsFromChildAttribute = (childItems) => {
    setParentItemsAttribute(childItems);
  };

  const deleteItem = (index, e) => {
    e.preventDefault();
    const updatedItems = parentItems.filter((_, i) => i !== index);
    setParentItems(updatedItems);
  };
  const deleteGroupItem = (index, e) => {
    e.preventDefault();
    console.log("index", index);


    const itemToDelete = parentItemsattribute.find((item) => item.index === index);

    if (itemToDelete) {
      const group_idToDelete = itemToDelete.index;

      console.log("group_idToDelete", itemToDelete)


      const updatedAttributes = parentItemsattribute.filter((attr) => attr.index !== index);


      const updatedItems = parentItems.filter((item) => !(item.group_id === group_idToDelete && item.group_id === index));


      const reindexedItems = updatedItems.map((item, i) => ({ ...item, index: i }));

      setParentItems(reindexedItems);
      setParentItemsAttribute(updatedAttributes);
      setGeneratedVariations([]);
      setPostVariationData([]);
    }
  };




  const handleGenerate = () => {
    const combinations = [];
    const groupedItems = {};

    // Group the items by their group_id
    parentItems.forEach(item => {
      if (!groupedItems[item.group_id]) {
        groupedItems[item.group_id] = [];
      }
      groupedItems[item.group_id].push(item);
    });

    const groupIds = Object.keys(groupedItems);

    if (groupIds?.length < 2) {
      const newPostVariationDataSingle = [];
      const group1 = groupedItems[groupIds[0]];
      for (let k = 0; k < group1?.length; k++) {
        const group1 = groupedItems[groupIds[0]];
        const groupCombinations = [];

        for (let k = 0; k < group1?.length; k++) {
          const combination = {
            parentItem1: group1[k]
          };
          newPostVariationDataSingle.push({
            first_variation_id: group1[k].name,
          });
          groupCombinations.push(combination);
        }
        setPostVariationData(newPostVariationDataSingle);
        setGeneratedVariations(groupCombinations);
        return;
      }
    }
    const newPostVariationData = [];
    for (let i = 0; i < groupIds?.length; i++) {
      for (let j = i + 1; j < groupIds?.length; j++) {
        const group1 = groupedItems[groupIds[i]];
        const group2 = groupedItems[groupIds[j]];

        for (let k = 0; k < group1?.length; k++) {
          for (let l = 0; l < group2?.length; l++) {
            combinations.push({
              parentItem1: group1[k],
              parentItem2: group2[l]
            });

            newPostVariationData.push({
              first_variation_id: group1[k].name,
              second_variation_id: group2[l].name,
            });
          }
        }
      }
    }
    setPostVariationData(newPostVariationData);
    setGeneratedVariations(combinations);
    console.log("combinations", combinations)
  };


  console.log("parentItems", parentItems)
  console.log("parentItemsattribute", parentItemsattribute)

  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>

              <ModalActionButtonWrapper>
                {props?.type !== "add" ?
                  <>
                    {/* <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                        <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons>  */}

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                      <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                  </>
                  :
                  <ModalButtons close onClick={() => props?.handleModalClose()}>
                    <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                  </ModalButtons>
                }
              </ModalActionButtonWrapper>

              {/* Add ecommercee */}
              {isdelete === false && (
                <Form onSubmit={props?.type === "add" ? addECommerceItem : props?.type === "edit" ? editECommerceItem : ""}>

                  <ModalTitle title> {props?.type === "add" ? "Add ECommerce Item" : "Edit ECommerce Item"}</ModalTitle>
                  {nextStep === 1
                    ?
                    <div className="first_step">
                      <div className="form-group mb-2">
                        <label>Shto titullin: <div style={{ color: "red", fontWeight: "bold" }} className="tooltip-container">*
                          <span class="tooltip-text">This field is required</span>
                        </div>
                        </label>
                        <input
                          required
                          id="name"
                          name="title_al"
                          type="text"
                          placeholder="p.sh Doreza Fitnesi"
                          className="form-control"
                          onChange={handleChange}
                          defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                        />
                      </div>

                      <div className="form-group mb-2">
                        <label>Add Title:</label>
                        <input
                          id="name"
                          name="title_en"
                          type="text"
                          placeholder="ex. Fitness Gloves"
                          className="form-control"
                          onChange={handleChange}
                          defaultValue={props?.type === "edit" ? props?.dataToModal?.title_en : ""}
                        />
                      </div>

                      <div className="form-group mb-2">
                        <label>Shto pershkrimin:</label>
                        <textarea
                          id="name"
                          name="description_al"
                          type="text"
                          className="form-control"
                          placeholder="p.sh Lorem Ipsum"
                          onChange={handleChange}
                          defaultValue={props?.type === "edit" ? props?.dataToModal?.description_al : ""}
                          rows="4"
                          cols="11"
                        />
                      </div>

                      <div className="form-group mb-2">
                        <label>Add Description:</label>
                        <textarea
                          id="name"
                          name="description_en"
                          type="text"
                          className="form-control"
                          placeholder="ex. Lorem Ipsum"
                          onChange={handleChange}
                          defaultValue={props?.type === "edit" ? props?.dataToModal?.description_en : ""}
                          rows="4"
                          cols="11"
                        />
                      </div>

                      <div className="form-group mb-2">
                        <label>Choose Category: <div style={{ color: "red", fontWeight: "bold" }} className="tooltip-container">*
                          <span class="tooltip-text">This field is required</span>
                        </div></label>
                        <select
                          required
                          id="name"
                          name="category"
                          className="form-control"
                          onChange={handleChange}
                          value={props?.type === "edit" ? selectedCategory : null}
                        >
                          <option selected value="">Select Category</option>
                          {ecomItemCategories?.map((el) => (
                            <option key={el?.id} value={el?.id}>
                              {el?.name_al}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-6">
                          <label>Regular Price:  <div style={{ color: "red", fontWeight: "bold" }} className="tooltip-container">*
                            <span class="tooltip-text">This field is required</span>
                          </div></label>
                          <input
                            required
                            type="number"
                            className="form-control"
                            onChange={handleChange}
                            name="regular_price"
                            defaultValue={props?.type === "edit" ? props?.dataToModal?.regular_price : ''}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Sales Price: </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={handleChange}
                            name="sales_price"
                            defaultValue={props?.type === "edit" ? props?.dataToModal?.sales_price : ''}
                          />
                        </div>
                      </div>


                      <div className="form-group mb-2">
                        <label>Stock: <div style={{ color: "red", fontWeight: "bold" }} className="tooltip-container">*
                          <span class="tooltip-text">This field is required</span>
                        </div></label>
                        <input
                          required
                          id="name"
                          name="stock"
                          type="number"
                          className="form-control"
                          onChange={handleChange}
                          defaultValue={props?.type === "edit" ? props?.dataToModal?.stock : ''}
                        />
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6">

                          <label>SKU: </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="sku"
                            defaultValue={props?.type === "edit" ? props?.dataToModal?.sku : ''}
                            placeholder="SKU"
                          />
                        </div>
                        <div className="col-md-6">

                          <label>VAT: </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="18%"
                            onChange={handleChange}
                            name="vat"
                            defaultValue={props?.type === "edit" ? props?.dataToModal?.vat : ''}
                          />
                        </div>
                      </div>


                      <div className="form-group mb-2">
                        <label className="me-2">Upload Images:</label>
                        <input
                          onChange={handleGalleryImageUpload}
                          type="file"
                          multiple
                          name="e_commerce_gallery"
                          accept="image/png, image/jpeg"
                        />


                        {existingImages.length > 0 && (
                          <div className="existing-images ">
                            <h4>Uploaded Images:</h4>
                            <div className="image-preview row">
                              {existingImages.map((existingImage, index) => (
                                <div key={index} className="image-preview-item col-3">
                                  <img
                                    src={`${process.env.REACT_APP_UPLOADS + existingImage.image}`}
                                    alt={`Existing Image ${index}`}
                                    style={{ width: '100px', height: '100px', objectFit: "cover", margin: '5px' }}
                                  />
                                  <button type="button" className="btn btn-dark mx-2 my-2" onClick={() => handleRemoveExistingImage(existingImage.id)}>
                                    Remove
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {galleryData.length > 0 && (
                          <div className="image-preview">
                            {galleryData.map((image, index) => (
                              <img
                                key={index}
                                src={URL.createObjectURL(image)}
                                alt={`Newly Added Image ${index}`}
                                style={{ maxWidth: '100px', maxHeight: '100px', margin: '5px' }}
                              />
                            ))}
                          </div>
                        )}

                      </div>

                      <div className="form-check">
                        <input
                          defaultChecked={props?.type === "edit" ? props?.dataToModal?.has_variants : ''}
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          name="has_variants"
                          onClick={handleChange}
                          id="hasVariations" />
                        <label className="form-check-label" for="hasVariations">
                          Has Variations
                        </label>
                      </div>
                    </div>
                    : nextStep === 2 ?
                      <div className="nextStep2" >
                        {/* next step */}

                        {/* pjes e dyte */}
                        {addEComItem?.has_variants === true ?
                          <div className="mb-5">
                            {parentItemsattribute?.length !== 2 ?
                              <button type="button" className="btn btn-success" onClick={() => setIsOpen1(true)}>
                                Add new Attribute Group
                              </button>
                              :
                              <div className=" p-3 addAttribute">
                                <i class="fa-solid fa-list-ol"></i> Keni shtuar 2 Attribute.
                              </div>
                            }
                            {isOpen1 ?
                              <>
                                <ECommerceVariations
                                  isOpen={isOpen}
                                  sendItemsToParent={receiveItemsFromChildAttribute}
                                  type="attributeGroup"
                                  name="Attribute"
                                  initialItems={parentItemsattribute}
                                  handleModalClose={() => { setIsOpen1(false); }
                                  }
                                />
                              </>
                              :
                              ""
                            }
                          </div>
                          :
                          ""
                        }
                        {addEComItem?.has_variants === true
                          ?
                          <>
                            <div className="">
                              {parentItemsattribute?.map((el, index) => (
                                <>
                                  <div className="col-12 ">
                                    <div className="card attribute-card p-1 ">
                                      <div className="row">

                                        <div className="col-md-6">
                                          <h6 className="mt-2">Attribute Group name: {el?.name}</h6>
                                        </div>
                                        <div className="d-flex gap-1 flex-row-reverse col-md-6">
                                          <button type="button" className="btn " onClick={(e) => deleteGroupItem(el?.index, e)}><i class="fa-solid fa-trash"></i> Attribute</button>
                                          <button type="button" className="btn  " onClick={() => { setIsOpen(true); setNumberIndex(el?.index) }}><i class="fa-solid fa-circle-plus"></i> New item</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="text-end mt-1">
                                      {parentItems?.map((innerEl, innerIndex) => (
                                        <>
                                          {innerEl?.group_id === el?.index
                                            ?
                                            <div class="card ps-2 mr-2 pt-2 mt-1">
                                              <div className="d-flex justify-content-between">
                                                <p key={index}> {innerEl?.name}</p>
                                                <div className="buttons">
                                                  {/* <button className="btn btn-warning"><i className="fa fa-edit"></i></button> */}
                                                  <button type="button" className="btn btn-sm btn-danger" onClick={(e) => deleteItem(innerIndex, e)}><i className="fa fa-trash"></i></button>
                                                </div>
                                              </div>
                                            </div>
                                            : ""}
                                        </>
                                      ))
                                      }
                                      <hr />
                                    </div>
                                  </div>

                                </>
                              ))
                              }
                              {isOpen ?
                                <>
                                  <ECommerceTermVariations
                                    isOpen={isOpen}
                                    number={numberIndex}
                                    sendItemsToParent={receiveItemsFromChild}
                                    initialItems={parentItems}
                                    name="Term"
                                    handleModalClose={() => { setIsOpen(); }
                                    }
                                  />
                                </>
                                :
                                ""
                              }

                            </div>

                          </>
                          : ""
                        }
                      </div>
                      :

                      <div className="nextStep3">

                        <div className="mt-4">
                          <button type="button" className="btn btn-secondary w-100" onClick={() => handleGenerate()}>Generate Variations</button>
                        </div>
                                <div className="card attribute-card p-1 mt-2 ">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="d-flex flex-direction-row gap-4">
                                      <h6 className="mt-2">Item Name</h6>
                                      <h6 className="mt-2"> Item Name 2</h6>
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="row mt-1">
                                        <div className="col">SKU</div>
                                        <div className="col">Price</div>
                                        <div className="col">Sales Price</div>
                                        <div className="col">Vat</div>
                                        <div className="col">Stock</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                        {generatedVariations?.length !== 0
                          ?
                          generatedVariations?.map((el, index) => {
                            return (

                              <div className="card p-1 ">
                                <div className="row  align-items-center">
                                  <div className="col">
                                    <h6>{el?.parentItem1?.name}</h6>
                                  </div>
                                  <div className="col">
                                    <h6>{el?.parentItem2?.name}</h6>
                                  </div>
                                  <div className="col-md-2">
                                    <div className=" d-flex">
                                      <input
                                        required
                                        id="name"
                                        name="sku_variation"
                                        type="text"
                                        placeholder="SKU"
                                        className="form-control"
                                        onChange={(e) => handleChangeGeneratedVariations(e, index)}
                                      // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                                      />
                                    </div>

                                  </div>
                                  <div className="col">
                                    <div className=" d-flex">
                                      <input
                                        required
                                        id="name"
                                        name="price_variation"
                                        type="number"
                                        placeholder="Price"
                                        className="form-control"
                                        onChange={(e) => handleChangeGeneratedVariations(e, index)}
                                      // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className=" d-flex">

                                      <input
                                        required
                                        id="name"
                                        name="sale_price_variation"
                                        type="number"
                                        placeholder="Sales Price"
                                        className="form-control"
                                        onChange={(e) => handleChangeGeneratedVariations(e, index)}
                                      // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                                      />
                                    </div>
                                  </div>

                                  <div className="col">
                            <input
                                required
                                id="name"
                                name="vat_variation"
                                type="number"
                                placeholder="Vat"
                                className="form-control"
                                defaultValue={el?.vat_variation}
                                // disabled={editButton !== el?.id ? true : false}
                                  onChange={(e)=>handleChangeGeneratedVariations(e,index)}
                                // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                                />
                            </div>

                                  <div className="col">
                                    <div className=" d-flex">

                                      <input
                                        required
                                        id="name"
                                        name="stock_variation"
                                        type="number"
                                        placeholder="Stock"
                                        className="form-control"
                                        onChange={(e) => handleChangeGeneratedVariations(e, index)}
                                      // defaultValue={props?.type === "edit" ? props?.dataToModal?.title_al : ""}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )

                          }) : "No Avaliable Generations"}
                      </div>
                  }


                  {addEComItem?.has_variants !== true || nextStep === 3 ?
                    props?.type === "add" && (
                      nextStep === 1 ? (
                        <div className="text-end">
                          <SaveButtonModal type="submit">
                            Ruaj
                          </SaveButtonModal>
                        </div>
                      ) : nextStep === 3 ? (
                        <>
                          <hr className="mt-5" />
                          <div className="d-flex justify-content-between">
                            <button type="button" className="btn btn-danger" onClick={(e) => { e.preventDefault(); setNextStep(2) }}>
                              Back Step
                            </button>
                            {generatedVariations?.length >= 1 ?
                              <button className="btn btn-dark" type="submit">
                                Ruaj
                              </button>
                              :
                              <button className="btn btn-dark" type="submit" disabled>
                                Ruaj
                              </button>
                            }
                          </div>
                        </>
                      ) : null
                    )
                    : nextStep === 1 ?
                      <>
                        <hr className="mt-5" />
                        <div className="text-end my-2">
                          {props?.type === "edit" ? ""
                            :
                            <button type="button" disabled={buttonDisabled == false} className="btn btn-success" onClick={(e) => { e.preventDefault(); setNextStep(2); handleButtonClick() }}>
                              Next Step
                            </button>
                          }
                        </div>
                      </>
                      :
                      nextStep === 2 ?
                        <>
                          <hr className="mt-5" />
                          <div className="d-flex justify-content-between my-2">
                            <button type="button" className="btn btn-danger" onClick={(e) => { e.preventDefault(); setNextStep(1) }}>
                              Back Step
                            </button>
                            {parentItems?.length >= 1 ?
                              <button type="button" className="btn btn-success" onClick={(e) => { e.preventDefault(); setNextStep(3) }}>
                                Next Step
                              </button>
                              :
                              <button type="button" className="btn btn-success" disabled onClick={(e) => { e.preventDefault(); setNextStep(3) }}>
                                Next Step
                              </button>

                            }


                          </div>
                        </>
                        : ""
                  }
                  {/* {nextStep === 3 ?
                 <button className="btn btn-danger" onClick={(e)=>{e.preventDefault();setNextStep(2)}}>
                 Back Step
                </button>   
                : ""
                       } */}

                  {props?.type === "edit" && (
                    <SaveButtonModal onClick={editECommerceItem} type="submit">
                      Ndrysho
                    </SaveButtonModal>
                  )}
                </Form>




              )}







              {isdelete &&
                <>
                  <DeleteMessage>
                    <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                    <DeleteButtonModal onClick={deleteECommerceItem}>Fshij</DeleteButtonModal>
                  </DeleteMessage>
                </>
              }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default EcommerceModal;