import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loggMeIN, rmAuth, toggleMenu } from "../../redux/Functions/actions";
import {
  MenuButton,
  PageStyle,
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  EyeIcon,
  Pagination,
  BackButton,
} from "../../styles/MainLayout";
import Search from "../search/search";
import ECommerceOrdersModal from "./eCommerceOrdersModal";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import ReactPaginate from "react-paginate";
import Loader from "../loader/loader";

const ECommerceOrders = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [dataToModal, setDataToModal] = useState();
  const [ecomOrders, setEComOrders] = useState();
  const [ecomOrderStatus, setEComOrderStatus] = useState([]);
  const [ecomOrderID, setEComOrderID] = useState();

  const [filtered, setFiltered] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 8;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  console.log("propsat", props);

  const getECommerceOrders = () => {
    axios
      .get("/orders/all")
      .then((data) => {
        setEComOrders(data.data);
        setFiltered(data?.data);
      })
      .catch((err) => {
        console.log("ecommerceOrdersErr", err);
      });
  };
  //   console.log(dataToModal);
  // const getECommerceOrderStatus = () => {
  //   axios
  //     .get("orders-status/all")
  //     .then((data) => {
  //       console.log("catdatadata diqka", data);
  //       setEComOrderStatus(data.data);
  //     })
  //     .catch((err) => {
  //       console.log("ecommerceOrderStatusErr", err);
  //     });
  // };

  console.log("ecommerceOrderStatus", ecomOrderStatus);

  useEffect(() => {
    console.log("ecomOrders:", ecomOrders);
    // console.log("filtered:", filtered);
    getECommerceOrders();
    // getECommerceOrderStatus();
  }, []);

  const handleModalAdd = () => {
    setModalType("add");
    setIsOpen(true);
  };

  const handleModalEdit = (data) => {
    setDataToModal(data);
    setEComOrderID(data);
    setModalType("edit");
    setIsOpen(true);
  };

  const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
  };
  console.log("ecomOrders", ecomOrders)
  const navigate = useNavigate();


  const orders = filtered || []

  const totalsArray = [];

  for (const order of orders) {
    const orderProducts = order?.order_products;



    let total = 0;

    for (const product of orderProducts) {

      if (product?.is_variation === true) {
        total += (product?.ordered_variation?.sale_price_variation ? product?.ordered_variation?.sale_price_variation : product?.ordered_variation?.price_variation) * product?.qty_ordered;
      } else {
        total += (product?.e_commerce?.sales_price ? product?.e_commerce?.sales_price : product?.e_commerce?.regular_price) * product?.qty_ordered;
      }
    }
    totalsArray.push({ order_id: order.id, total });
  }



  return (
    <>
      {isOpen &&
        <ECommerceOrdersModal
          dataToModal={dataToModal}
          isOpen={isOpen}
          type={modalType}
          getData={() => getECommerceOrders()}
          handleModalClose={() => setIsOpen()}
        />
      }

      <PageStyle isMenuOpen={props?.menu}>
        <PageTitleStyle>List of e-Commerce Orders</PageTitleStyle>

        <Search
          data={ecomOrders}
          filtered={setFiltered}
          setPageNumber={setPageNumber}
        />
        <MenuButton onClick={handleMenuClick}>
          {props.menu ? (
            <i className="fa fa-chevron-left"></i>
          ) : (
            <i className="fa fa-bars"></i>
          )}
        </MenuButton>

        <PageContainer>
          <TableResponsive isMenuOpen={props?.menu}>
            <BackButton onClick={() => navigate(-1)}>
              <i className="fa fa-chevron-left backIcon "></i><h6>Back</h6>
            </BackButton>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableHeadStyle tableFirstElement>ID</TableHeadStyle>
                  <TableHeadStyle>Order #ID</TableHeadStyle>
                  <TableHeadStyle>User</TableHeadStyle>
                  <TableHeadStyle>Amount</TableHeadStyle>
                  <TableHeadStyle>Status</TableHeadStyle>
                  <TableHeadStyle>Actions</TableHeadStyle>
                </TableRowHead>
              </TableHead>
              <TableBody>
                {filtered && filtered?.length === 0 && (
                  <div className="text-secondary text  m-3">
                    <h6>Nuk ka të dhëna</h6>
                  </div>
                )}
                {filtered && filtered?.length > 0 && (
                  <>
                    {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                      return (
                        <>
                          <TableRowBody key={el?.id}>
                            <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                            <TableBodyStyle>SHF-{el?.id}</TableBodyStyle>
                            <TableBodyStyle>{el?.name_surname}</TableBodyStyle>
                            <TableBodyStyle>
                              {totalsArray?.find(elm => elm?.order_id == el?.id)?.total ?
                                totalsArray?.find(elm => elm?.order_id == el?.id)?.total + ' €'
                                :
                                totalsArray?.find(elm => elm?.order_id == el?.id)?.total === 0 ?
                                  0 + ' €' :
                                  <div className="loaderBody">
                                    <div class="frame">
                                      <div id="loader"></div>
                                    </div>
                                  </div>
                              }
                            </TableBodyStyle>
                            <TableBodyStyle>{el?.orders_status?.name_en} </TableBodyStyle>
                            <TableBodyStyle>
                              <EyeIcon onClick={() => handleModalEdit(el)} />
                            </TableBodyStyle>
                          </TableRowBody>
                        </>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
            <Pagination>
              <div className="text-right">
                {ecomOrders?.length >= productsPerPage ? (
                  <ReactPaginate
                    previousLabel={<i className="fa fa-angle-double-left" />}
                    nextLabel={<i className="fa fa-angle-double-right" />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                ) : (
                  ""
                )}
              </div>
            </Pagination>
          </TableResponsive>
        </PageContainer>
      </PageStyle>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ECommerceOrders);
