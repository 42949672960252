import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import ReactPaginate from "react-paginate";
import {
  MenuButton,
  PageStyle,
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  Pagination,
  EyeIcon
} from "../../styles/MainLayout";
import CaloriesRangeModal from "./caloriesRangeModal";
import Search from "../search/search";
import axios from "../../axios";
const CaloriesRange = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [caloriesRange, setCaloriesRange] = useState();
  const [dataToModal, setDataToModal] = useState(null);
  const [filtered, setFiltered] = useState([]);

  //Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 10;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }

  const getData = () => {
    axios.get('/calories-range/all').then(data => {
      setCaloriesRange(data.data);
      setFiltered(data?.data);
    }).catch(err => {
      console.log(err);
    })
  }
  const handleModalAdd = () => {
    setModalType('add');
    setIsOpen(true);
  };

  const handleModalEdit = (data) => {
    setModalType('edit');
    setIsOpen(true);
    setDataToModal(data);
  };

  const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {isOpen === true && <CaloriesRangeModal isOpen={isOpen} type={modalType} getData={() => getData()} handleModalClose={() => {setIsOpen();setDataToModal(null)}} data={dataToModal} />}
      <PageStyle isMenuOpen={props?.menu}>
        <PageTitleStyle>CaloriesRange</PageTitleStyle>
        <Search data={caloriesRange} filtered={setFiltered} setPageNumber={setPageNumber} />
        <MenuButton onClick={handleMenuClick}>{props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>
        <PageContainer>
          <TableResponsive isMenuOpen={props?.menu}>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableHeadStyle tableFirstElement>Id</TableHeadStyle>
                  <TableHeadStyle>Range</TableHeadStyle>
                  <TableHeadStyle>Actions</TableHeadStyle>
                </TableRowHead>
              </TableHead>
              <TableBody>
                {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>}
                {filtered && filtered?.length > 0 && <>
                  {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                    return (
                      <>
                        <TableRowBody key={index}>
                          <TableBodyStyle tableFirstElement>{el?.id}</TableBodyStyle>
                          <TableBodyStyle >{el?.range}</TableBodyStyle>
                          <TableBodyStyle><EyeIcon onClick={() => handleModalEdit(el)} /> </TableBodyStyle>
                        </TableRowBody>
                      </>
                    )
                  })}</>
                }
              </TableBody>
            </Table>
            <Pagination>
              <div className="text-right">
                {((caloriesRange?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
          </TableResponsive>
        </PageContainer>
      </PageStyle>
      <MenuButton add onClick={() => { handleModalAdd(); }}>
        <i className="fa fa-plus" />
      </MenuButton>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CaloriesRange);