import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import { 
  MenuButton, 
  PageStyle, 
  PageTitleStyle,
  TableResponsive,
  TableBody,
  TableHead,
  TableRowHead,
  TableRowBody,
  PageContainer,
  Table,
  TableHeadStyle,
  TableBodyStyle,
  Pagination,
  EyeIcon } from "../../styles/MainLayout";
import Search from "../search/search";
import axios from "../../axios";
import ReactPaginate from "react-paginate";
import LabelsModal from "./labelsModal";

const Labels = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [dataToModal, setDataToModal] = useState();
  const [labels, setLabels] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [languageID, setLanguageID] = useState(null);

  const [filtered, setFiltered] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 8;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }

  const getLabels = () => {
    axios.get('/label-translations/all').then(data => {
      setLabels(data.data);
      setFiltered(data?.data)
    }).catch(err => {
      console.log('err', err);
    });
  };

  const getLanguages = () => {
    axios.get('/languages/all').then(data => {
      setLanguages(data.data);
    }).catch(err => {
      console.log('err', err);
    });
  };
  useEffect(() => {
    getLabels();
    getLanguages();
  }, []);

  const handleModalAdd = () => {
    setModalType('add');
    setIsOpen(true);
  };

  const handleModalEdit = (data) => {
    setDataToModal(data);
    setLanguageID(data?.data);
    setModalType('edit');
    setIsOpen(true);
  };

  const handleMenuClick = (e) => {
    e.preventDefault();
    props.toggleMenu();
  };    

  return (
    <>
      {isOpen === true ?
        <LabelsModal dataToModal={dataToModal} languageID={languageID} isOpen={isOpen} type={modalType} getData={() => getLabels()} handleModalClose={() => setIsOpen(false)} />
        : ""
      }
      <PageStyle isMenuOpen={props?.menu}>
        <PageTitleStyle>Labels</PageTitleStyle>
        <Search data={labels} filtered={setFiltered} setPageNumber={setPageNumber}/>
        <MenuButton onClick={handleMenuClick}>
          {props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}
        </MenuButton>
        <PageContainer>
          <TableResponsive isMenuOpen={props?.menu}>
           <Table>
              <TableHead>
                <TableRowHead>
                  <TableHeadStyle tableFirstElement>Count</TableHeadStyle>
                  <TableHeadStyle>Key</TableHeadStyle>
                   {languages?.map((el)=>{
                    return(
                        <>
                        
                        <TableHeadStyle>Values {el?.code}</TableHeadStyle>
                        </>
                    )
                   })}
                  <TableHeadStyle>Edit</TableHeadStyle>
                </TableRowHead>
              </TableHead>
            <TableBody>
              {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>
              }
                {filtered && filtered?.length > 0 && <>
                  {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                    
                    return (
                      <>
                      <TableRowBody key={el?.id}>
                      <TableBodyStyle tableFirstElement>{index+1}</TableBodyStyle>
                      <TableBodyStyle >{el?.keyname}</TableBodyStyle>
                      {el?.allValues?.map((elem)=>{
                        return(
                            <>
                            <TableBodyStyle>{elem?.value}</TableBodyStyle>
                            </>
                        )
                      })}
                       <TableBodyStyle>
                        <EyeIcon onClick={() => handleModalEdit(el)} />
                      </TableBodyStyle> 
                      </TableRowBody>
                      </> )})}</>}
              </TableBody>
            </Table>
            <Pagination>
              <div className="text-right">
                {((labels?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
          </TableResponsive>
        </PageContainer>
      </PageStyle>
      <MenuButton add onClick={handleModalAdd}> <i className="fa fa-plus" /> </MenuButton>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Labels);