import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
  SaveButtonModal,
  DeleteButtonModal,
  DeleteMessage,
} from "../../styles/MainLayout";


const GlobalModal = (props) => {
  const [isEdit, setEdit] = useState(false);
  const [isdelete, setDelete] = useState(false);

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      props?.handleModalClose()
    }
  })

  useEffect(() => {
    if (props?.isOpen) {
      setEdit(false);
      setDelete(false);
    }
  }, [props?.isOpen]);


  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
          <div className="wholePage" onClick={() => props?.handleModalClose()}/>
             <Mymodal_wrapper>
              <ModalActionButtonWrapper>
                { props?.type !== "add" ?
                    <>
                    <ModalButtons edit onClick={() => setEdit(!isEdit)}>
                        <i className={isEdit ? "fa fa-times" : "fa fa-edit"} />
                    </ModalButtons> 

                    <ModalButtons delete onClick={() => setDelete(!isdelete)}>
                     <i className={isdelete ? "fa fa-times" : "fa fa-trash"} />
                    </ModalButtons>

                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                        <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                    </>
                :   
                    <ModalButtons close onClick={() => props?.handleModalClose()}>
                        <i className={isdelete ? "fa fa-times" : "fa fa-times"} />
                    </ModalButtons>
                }
              </ModalActionButtonWrapper>

          
              {isdelete === false && (
                <Form >
                    <ModalTitle title>Modal Title</ModalTitle>
                    <div className="form-group">
                        <label>Emri:</label>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="emri"
                        disabled={isEdit || props?.type === "add" ? false : true}
                        />
                    </div>
                    <div className="form-group">
                        <label>Mbiemer:</label>
                        <select
                        disabled={isEdit || props?.type === "add" ? false : true}
                        className="form-control text-capitalize"
                        >
                        <option>popo </option>
                        <option>joopo </option>
                        <option>popaa </option>
                        <option>poopo </option>
                        </select>
                    </div>


                    {props?.type === "add" && (
                        <SaveButtonModal type="submit">
                            Ruaj
                        </SaveButtonModal>
                    )}
                    
                    {props?.type === "edit" && (
                        <SaveButtonModal type="submit" disabled={isEdit || props?.type === "add" ? false : true}>
                            Ndrysho
                        </SaveButtonModal>
                    )}
                </Form>

                    
              )}
                {isdelete &&
                  <>
                      <DeleteMessage>
                          <ModalTitle deleteMessage>Deshironi te fshini te dhenat?</ModalTitle>
                          <DeleteButtonModal onClick={props?.handleModalClose}>Fshij</DeleteButtonModal>
                      </DeleteMessage>
                  </>
                }
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else {
    return null;
  }
};

export default GlobalModal;
