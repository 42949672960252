import React, { useEffect } from "react";
import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import TestComponent from './components/test';
import AdminMenu from './components/menu/menu';
import { MenuButton, PageStyle, PageTitleStyle } from "./styles/MainLayout";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
  setLangData
} from "./redux/Functions/actions";
import Statistics from "./components/statistics/statistics";
import WorkoutCategorisation from "./components/workoutCategorisation/workoutCategorisation";
import SetsSettings from "./components/setsSettings/setsSettings";
import ExcerciseLevels from "./components/excerciseLevels/excerciseLevels";
import Locations from "./components/locations/locations";
import Users from "./components/users/users";
import Fitnesses from "./components/fitness/Fitnesses"
import ActivityLevels from "./components/activityLevels/activityLevels";
import Goals from "./components/goals/goals";
import DietTypes from "./components/dietTypes/dietTypes";
import CaloriesRange from "./components/caloriesRange/caloriesRange";
import Equipments from "./components/equipments/equipments";
import TrainingLessonsCategories from "./components/trainingLessonsCategories/trainingLessonsCategories";
import EComProductCategories from "./components/e-ComProductCategories/eComProductCategories";
import EComPaymentTypes from "./components/e-ComPaymentTypes/eComPaymentTypes";
import Countries from "./components/countries/countries";
import Cities from "./components/cities/cities";
import Packages from "./components/packages/packages";
import PersonalTrainers from "./components/personalTrainers/personalTrainers";
import UserInfo from "./components/users/userInfo";
import TrainingLessons from "./components/trainingLessons/trainingLessons";
import ECommerce from "./components/eCommerce/eCommerce";
import ECommerceOrders from "./components/eCommerce/eCommerceOrders";
import FitnessPlans from "./components/fitnessPlans/fitnessPlans";
import ListOfPlanQWorkouts from "./components/listOfPlanWorkouts/listOfPlanQWorkouts";
import ListOfWorkoutSets from "./components/listOfPlanWorkouts/listOfWorkoutSets";
import FitnessPlansWeeks from "./components/fitnessPlans/fitnessPlansWeeks";
import { ToastContainer } from "react-toastify";
import Login from "./components/login/login";
import NewsCategories from "./components/newsCategories/newsCategories";
import News from "./components/news/news";
import OrderStatus from "./components/eComOrderStatus/orderStatus"
import Languages from "./components/languages/languages";
import Labels from "./components/labels/labels";
import axios from "./axios";
import FileManager from "./components/fileManager/fileManager";
import FilesTrainerManage from "./components/fileManager/filesTrainerManage";

const App = (props) => {

  const getData = () => {
    axios.get("/label-translations/all").then(data => {
      console.log('allLabels', data.data);
      props.setLangData(data.data);
    }).catch(err => console.log(err));
  }

  useEffect(()=>{
    getData();
  },[])
  

  const handleMenuClick = (e) => {
    e.preventDefault();
    props.toggleMenu();
  }

  return (
    <>
      <ToastContainer position="top-left" theme="colored" style={{ zIndex: '99999999999999999999999999' }} />
      <BrowserRouter>

        {props?.loggedIn === false && props?.auth == "" ?
          <Routes>
            <Route path="/*" element={<Login />} />
          </Routes>

          :
          <>
            <MenuButton onClick={handleMenuClick} menu={props.menu}>{props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>
            <AdminMenu  data={props}/>
            <PageStyle isMenuOpen={props.menu}>
              <Routes>
                {/* <Route path="/" element={<TestComponent />} /> */}
                <Route path="/" element={props.role !=5 ? <Statistics /> : <ECommerce/>} />
                <Route path="/settings/workout-categorisation" element={<WorkoutCategorisation />} />
                <Route path="/settings/sets-settings" element={<SetsSettings />} />
                <Route path="/settings/excercise-levels" element={<ExcerciseLevels />} />
                <Route path="/settings/locations" element={<Locations />} />
                <Route path="/settings/users" element={<Users />} />
                <Route path="/settings/user-info/:id" element={<UserInfo />} />
                <Route path="settings/fitness" element={<Fitnesses />} />
                <Route path="/settings/activity-levels" element={<ActivityLevels />} />
                <Route path="/settings/goals" element={<Goals />} />
                <Route path="/settings/diet-types" element={<DietTypes />} />
                <Route path="/settings/calories-range" element={<CaloriesRange />} />
                <Route path="/settings/equipments" element={<Equipments />} />
                <Route path="/settings/training-lessons-categories" element={<TrainingLessonsCategories />} />
                <Route path="/settings/news-categories" element={<NewsCategories />} />
                <Route path="/settings/e-com-product-categories" element={<EComProductCategories />} />
                <Route path="/settings/e-com-payment-types" element={<EComPaymentTypes />} />
                <Route path="/settings/countries" element={<Countries />} />
                <Route path="/settings/cities" element={<Cities />} />
                <Route path="/settings/packages" element={<Packages />} />
                <Route path="/news" element={<News />} />
                <Route path="/trainingLessons" element={<TrainingLessons />} />
                <Route path="/eCommerce" element={<ECommerce />} />
                <Route path="/eCommerce-orders" element={<ECommerceOrders />} />
                <Route path="/personal-trainers" element={<PersonalTrainers />} />
                <Route path="/fitness-plans" element={<FitnessPlans />} />
                <Route path="/fitness-plans-weeks/:id" element={<FitnessPlansWeeks />} />
                <Route path="/plan-workouts/:id/:trainerId" element={<ListOfPlanQWorkouts />} />
                <Route path="/workout-sets/:id" element={<ListOfWorkoutSets />} />
                <Route path="/trainingLessons" element={<TrainingLessons />} />
                <Route path="/eCommerce" element={<ECommerce />} />
                <Route path="/login" element={<Login />} />
                <Route path="/languages" element={<Languages />} />
                <Route path="/labels" element={<Labels />} />
                <Route path="/settings/order-status" element={<OrderStatus />} />
                <Route path="/file-manager" element={<FileManager />} />
                <Route path="/files/:id" element={<FilesTrainerManage />} />
                <Route path="/*" element={<h2>Page Not Found</h2>} />
              </Routes>
            </PageStyle>
          </>
        }
      </BrowserRouter>
    </>

  );
};

const mapStateToProps = (state) => {
  console.log("tes ]t state", state)
  return {
    auth: state.data.auth,
    role:state.data.role, 
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    setLangData: (data) => dispatch(setLangData(data)),
    // loadingOff: () => dispatch(loadingOff()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);


const About = () => {
  return <PageTitleStyle>About</PageTitleStyle>;
};

const Contact = () => {
  return <PageTitleStyle>Contact</PageTitleStyle>;
};