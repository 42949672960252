const EComMenuData = [
    {
        name_al: "E-Commerce",
        url: "/eCommerce",
        icon: "fa-solid fa-shop",
        id: 1
    }
                
]

export default EComMenuData;










