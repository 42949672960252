import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
} from "../../redux/Functions/actions";
import { 
  MenuButton, 
  PageStyle, 
  PageTitleStyle,
  TableResponsive,
  PageContainer,
 } from "../../styles/MainLayout";
import GlobalModal from "./globalModal";
import Search from "../search/search";

import "./statistics.scss"
import axios from "../../axios";


const GlobalComponent = (props) => {

const [isOpen, setIsOpen] = useState(false);
const [modalType, setModalType] = useState(null);

const today = new Date();
const todaySliced = today?.toISOString()?.slice(0, 10);
const threeMonthAgo = today.setMonth(today.getMonth() - 7);

const threeMonthAgoSliced = new Date(threeMonthAgo)?.toISOString()?.slice(0, 10);

const [fromDate, setFromDate] = useState(threeMonthAgoSliced);
const [toDate, setToDate] = useState(todaySliced);

const [totalUsers, setTotalUsers] = useState();
const [totalUsersSubscription, setTotalUsersSubscription] = useState();
const [totalUsersFreeTrial, setTotalUsersFreeTrial] = useState();
const [totalSalesSubscription, setTotalSalesSubscription] = useState();
const [totalSalesPackage1, setTotalSalesPackage1] = useState();
const [totalFitnessSubscription, setTotalFitnessSubscription] = useState([]);
const [isLoading, setIsLoading] = useState(true);


const handleMenuClick = (e) => {
    e.prevenTableHeadStyleefault();
    props.toggleMenu();
};    

const getTotalUsers = () => {
  // props?.loadingOn();
  axios.get('statistics/total/users/'+ fromDate +'/'+ toDate ).then(data => {
    setTotalUsers(data.data);
    props?.loadingOff();
  }).catch(err => console.log(err));

  axios.get('statistics/total/users-subscriptions/'+ fromDate +'/'+ toDate ).then(data => {
    setTotalUsersSubscription(data.data);
    props?.loadingOff();
  }).catch(err => console.log(err));

  axios.get('statistics/total/users/free-trial/'+ fromDate +'/'+ toDate ).then(data => {
    setTotalUsersFreeTrial(data.data);
    props?.loadingOff();
  }).catch(err => console.log(err));

  axios.get('statistics/total/salesSubscriptions/'+ fromDate +'/'+ toDate ).then(data => {
    setTotalSalesSubscription(data.data);
    props?.loadingOff();
  }).catch(err => console.log(err));

  axios.get('statistics/total/salesSubscriptionsPackages/'+ fromDate +'/'+ toDate ).then(data => {
    setTotalSalesPackage1(data.data?.totalSalesSubsciption);
    props?.loadingOff();
  }).catch(err => console.log(err));

  axios.get('statistics/total/fitnessSubscriptions/'+ fromDate +'/'+ toDate ).then(data => {
    setTotalFitnessSubscription(data.data.fitnessSubscriptions);
    setIsLoading(false)
    props?.loadingOff();
  }).catch(err => console.log(err));
}


useEffect(() => {
  getTotalUsers();
}, [fromDate, toDate]);

// console.log("statsdata",fromDate, toDate, totalFitnessSubscription)

return (
      <>
   
        <GlobalModal isOpen={isOpen} type={modalType} handleModalClose={()=>setIsOpen()} />
        
        <PageStyle isMenuOpen={props?.menu}>

          <PageTitleStyle>Dashboard</PageTitleStyle>
          <Search />
          <MenuButton onClick={handleMenuClick}>{props.menu ? <i className="fa fa-chevron-left"></i> : <i className="fa fa-bars"></i>}</MenuButton>

          <PageContainer>
        
          <TableResponsive isMenuOpen={props?.menu}>

          <div className="row ">
            <div className="col-12 mb-4">
              <div className="button-group">
                <label>
                  <span>To Date</span>
                </label>
                <input type="date" defaultValue={toDate} onChange={(e) => { e.preventDefault(); setToDate(e.target.value) }}></input>
              </div>
              <div className="button-group">
                <label>
                  <span>From Date</span>
                </label>
                <input type="date" defaultValue={fromDate} onChange={(e) => { e.preventDefault(); setFromDate(e.target.value) }}></input>
              </div>
            </div>
          </div>

          <div class="header-body">
            <div class="row m-4">
              <div class="col-xl-4 col-lg-6">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">TOTAL USERS REGISTER</h5>
                        <span class="h2 font-weight-bold mb-0">{totalUsers?.totalUsers}</span>
                      </div>
                      <div class="col-auto m-auto">
                        <div class="icon icon-shape bg-dark text-white rounded-circle shadow">
                          <i class="fas fa-users"></i>
                        </div>
                      </div>
                    </div>
                    {/* <p class="mt-3 mb-0 text-muted text-sm">
                      <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                      <span class="card-title text-uppercase text-muted mb-0"><b>Total User Register</b></span>
                    </p> */}
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-6">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">TOTAL USERS SUBSCRIPTION</h5>
                        <span class="h2 font-weight-bold mb-0">{totalUsersSubscription?.totalUsersSubscription}</span>
                      </div>
                      <div class="col-auto m-auto">
                        <div class="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i class="fas fa-chart-bar"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-6">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">TOTAL USERS FREE-TRIAL</h5>
                        <span class="h2 font-weight-bold mb-0">{totalUsersFreeTrial?.totalUsersFreeTrial}</span>
                      </div>
                      <div class="col-auto m-auto">
                        <div class="icon icon-shape bg-dark text-white rounded-circle shadow">
                          <i class="fas fa-chart-pie"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-12 col-lg-12 mt-4">
                <div class="card overflow-hidden">
                  <div class="card-content">
                    <div class="card-body cleartfix row">
                      <div class="media align-items-stretch col-9  m-auto">
                        <div class="align-self-center">
                          <i class="icon-pencil primary font-large-2 mr-2"></i>
                        </div>
                        <div class="media-body">
                          <h6 className="text-muted ">TOTAL SALES FROM SUBSCRIPTION</h6>
                          {/* <span>gggggggggggggg</span> */}
                        </div>
                        
                      </div>
                      <div class="align-self-end col-3 text-center">
                        <h2> {totalSalesSubscription?.totalSalesSubsciption} €  
                          {/* <div class="icon icon-shape bg-dark text-white rounded-circle shadow">
                            <i class="fas fa-euro"></i>
                          </div> */}
                        </h2>
                      </div>

                      <hr></hr>

                      {totalSalesPackage1 && totalSalesPackage1.length > 0 ? (
                        totalSalesPackage1?.map((el) =>{
                          return(
                            <>
                            <div class="media align-items-stretch col-9  m-auto">
                              <div class="align-self-center">
                                <i class="icon-pencil primary font-large-2 mr-2"></i>
                              </div>
                              <div class="media-body">
                                <h6 className="text-muted">PACKAGE</h6>
                              </div>
                              <p class="mt-3 mb-2 text-sm">
                                {/* <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span> */}
                                <span class="card-title text-uppercase mb-0"><b>{el?.package_name}</b></span>
                              </p>  
                            </div>
                            <div class="align-self-end col-3 text-center">
                                <h2 > {el?.total_price !== null ?  el?.total_price + "€ ": "-" } </h2>
                            </div>
                            <hr></hr>
                            </>
                          )
                        }))
                        :(
                          <p className="text-muted">No sales packages available.</p>
                        )
                      }       
                      </div> 
                  </div> 
                </div>
              </div>


          {/* TOTAL SALES SUBSCRIPTION BASED IN FITNESS */}
          <div className="accordion accordion-flush mt-4 statistikat" id="accordionFlushExample">
            <div className="accordion-item ">
                <button className=" h6 accordion-button collapsed text-start text-muted" type="button" 
                style={{width:" 100%", backgroundColor:" #fff", backgroundClip: "border-box", border: "1px solid rgba(0,0,0,.125)", borderRadius: "0.25rem"}}
                data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  <h6 className="mt-0 mb-0 text-start">TOTAL FITNESS SUBSCRIPTION</h6>
                </button>
              <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  {isLoading ? (
                    <div className="spinner-border text-dark m-4" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <div className="row p-4 mt-2">
                      { totalFitnessSubscription && totalFitnessSubscription.length > 0 ? (
                          totalFitnessSubscription?.map((el) => {
                            return (
                              <div className="col-xl-4 col-lg-12 mb-2" key={el?.fitness_name}>
                                <div className="card card-stats mb-4 mb-xl-0">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">
                                          {el?.fitness_name}
                                        </h5>
                                        <span className="h2 font-weight-bold mb-0">
                                          {el?.totalfitnessSubscriptions}
                                        </span>
                                      </div>
                                      <div className="col-auto m-auto">
                                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                          <i className="fa-solid fa-ranking-star"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                            );
                          })
                        ): (
                          <p className="text-muted">No fitness subsctiptions available.</p>
                        )
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          </div>
          </TableResponsive>
          </PageContainer>

        </PageStyle>

        {/* <MenuButton add onClick={() => {handleModalAdd();}}>
          <i className="fa fa-plus" />
        </MenuButton> */}
      </>
)
}

const mapStateToProps = (state) => {
    return {
      lang: state.data.lang,
      langData: state.data.langData,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu()),
      // loadingOff: () => dispatch(loadingOff()),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(GlobalComponent);